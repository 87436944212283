<template>
  <div class="container-fluid row" style="width: 90%; margin: auto">
    <div class="card mt-5 col-md-12" style="margin: 10px">
      <div class="mb-3 mt-3">
        <div class="row">
          <div class="col-md-12 mb-3">
            <label for="exampleInputEmail1" class="form-label">ชุดทดสอบ {{ name }} ( ข้อสอบปรนัย แบบที่ 2 )</label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label for="exampleInputEmail1" class="form-label">คำถาม</label>
            &nbsp;<button
              v-if="showquestion"
              type="button"
              class="btn btn-warning btn-sm"
              @click="gettextform(question.question, 'question')"
            >
              <i class="fa fa-edit"></i>
            </button>
          </div>
          <div class="col-md-12 mb-3">
            <div v-if="showquestion">
              <label v-html="question.question"></label><br />
            </div>
            <div v-else>
              <vue-editor
                use-custom-image-handler
                @image-added="handleImageAdded"
                v-model="question.question"
              /><br /><button
                type="button"
                class="btn btn-success btn-sm"
                @click="saveform(question.question, 'question')"
              >
                <i class="fa fa-save"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label for="exampleInputEmail1" class="form-label"
              >คำตอบที่ถูกต้อง</label
            >
          </div>
          <div class="col-md-5 mb-3">
            <div v-if="showCorrectAns">
              <label v-html="question.CorrectAns"></label><br />
              <button
                type="button"
                class="btn btn-warning btn-sm"
                @click="gettextform(question.CorrectAns, 'CorrectAns')"
              >
                <i class="fa fa-edit"></i>
              </button>
            </div>
            <div v-else>
              <vue-editor
                use-custom-image-handler
                @image-added="handleImageAdded"
                v-model="question.CorrectAns"
              /><br /><button
                type="button"
                class="btn btn-success btn-sm"
                @click="saveform(question.CorrectAns, 'CorrectAns')"
              >
                <i class="fa fa-save"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-3">
            <label for="exampleInputEmail1" class="form-label"
              >คำตอบลวง</label
            >
          </div>
          <div class="col-md-3 mb-3">
            <div v-if="showIncorrectAns1">
              <label v-html="question.IncorrectAns1"></label><br />
              <button
                type="button"
                class="btn btn-warning btn-sm"
                @click="gettextform(question.IncorrectAns1, 'IncorrectAns1')"
              >
                <i class="fa fa-edit"></i>
              </button>
            </div>
            <div v-else>
              <vue-editor
                use-custom-image-handler
                @image-added="handleImageAdded"
                v-model="question.IncorrectAns1"
              /><br /><button
                type="button"
                class="btn btn-success btn-sm"
                @click="saveform(question.IncorrectAns1, 'IncorrectAns1')"
              >
                <i class="fa fa-save"></i>
              </button>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div v-if="showIncorrectAns2">
              <label v-html="question.IncorrectAns2"></label><br />
              <button
                type="button"
                class="btn btn-warning btn-sm"
                @click="gettextform(question.IncorrectAns2, 'IncorrectAns2')"
              >
                <i class="fa fa-edit"></i>
              </button>
            </div>
            <div v-else>
              <vue-editor
                use-custom-image-handler
                @image-added="handleImageAdded"
                v-model="question.IncorrectAns2"
              /><br /><button
                type="button"
                class="btn btn-success btn-sm"
                @click="saveform(question.IncorrectAns2, 'IncorrectAns2')"
              >
                <i class="fa fa-save"></i>
              </button>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div v-if="showIncorrectAns3">
              <label v-html="question.IncorrectAns3"></label><br />
              <button
                type="button"
                class="btn btn-warning btn-sm"
                @click="gettextform(question.IncorrectAns3, 'IncorrectAns3')"
              >
                <i class="fa fa-edit"></i>
              </button>
            </div>
            <div v-else>
              <vue-editor
                use-custom-image-handler
                @image-added="handleImageAdded"
                v-model="question.IncorrectAns3"
              /><br /><button
                type="button"
                class="btn btn-success btn-sm"
                @click="saveform(question.IncorrectAns3, 'IncorrectAns3')"
              >
                <i class="fa fa-save"></i>
              </button>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div v-if="showIncorrectAns4">
              <label v-html="question.IncorrectAns4"></label><br />
              <button
                type="button"
                class="btn btn-warning btn-sm"
                @click="gettextform(question.IncorrectAns4, 'IncorrectAns4')"
              >
                <i class="fa fa-edit"></i>
              </button>
            </div>
            <div v-else>
              <vue-editor
                use-custom-image-handler
                @image-added="handleImageAdded"
                v-model="question.IncorrectAns4"
              /><br /><button
                type="button"
                class="btn btn-success btn-sm"
                @click="saveform(question.IncorrectAns4, 'IncorrectAns4')"
              >
                <i class="fa fa-save"></i>
              </button>
            </div>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-md-12 mt-3">
            <label for="exampleInputEmail1" class="form-label">สถานะ</label>
          </div>
          <div class="col-md-3 mb-3">
            <select
              v-model="question.status"
              class="form-select form-select-sm"
              aria-label=".form-select-sm example"
            >
              <option value="1">ใช้งานอยู่</option>
              <option value="0">ไม่ใช้งาน</option>
            </select>
          </div>
        </div> -->
        <div class="row">
          <div class="col-md-12 mt-3">
            <label for="exampleInputEmail1" class="form-label"
              >ระดับความยาก</label
            >
          </div>
          <div class="col-md-3 mb-3">
            <select
              v-model="question.level"
              class="form-select form-select-sm"
              aria-label=".form-select-sm example"
            >
              <option :value="l.id" v-for="(l, i) in levels" :key="'L1' + i">
                {{ l.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="social-auth-links text-center mb-3 mt-3">
        <button @click="save()" type="button" class="btn btn-success btn-sm">
          บันทึก
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SubjectTestService from "../services/SubjectTestService";
import LevelService from "../services/LevelService";
import RandomService from "../services/RandomService";
import QuestionObject from "../services/QuestionObject";
import axios from "axios";
import LinkService from "../services/LinkService";
import QuestionSetService from "../services/QuestionSetService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  component: {},
  data() {
    return {
      question_id: 0,
      question: {},
      answer: [],
      levels: [],
      format: "",
      showquestion: true,
      showCorrectAns: true,
      showIncorrectAns1: true,
      showIncorrectAns2: true,
      showIncorrectAns3: true,
      showIncorrectAns4: true,
      showIncorrectAns5: true,
      type:'',
      name:'',
      page:''
    };
  },
  mounted() {
    this.question_id = this.$route.params.id;
    this.format = this.$route.params.setid;
    this.type = this.$route.params.type
    this.page = this.$route.params.page
    QuestionSetService.getQuestionSet(this.format).then(
        (res) => {
          // console.log(res.data);
          this.name = res.data.question_name
        }
      );
    // console.log(this.question_id);
    if (this.question_id == 0) {
      this.getquestions();
      // this.answer.push({
      //   answer: "",
      //   id: 0,
      // });
      this.question.level = 1;
      this.question.status = 1;
    } else {
      this.getquestion();
    }
    this.getlevel();
  },
  methods: {
    saveform(data, type) {
      if (type == "question") {
        this.showquestion = true;
      } else if (type == "CorrectAns") {
        this.showCorrectAns = true;
      } else if (type == "IncorrectAns1") {
        this.showIncorrectAns1 = true;
      } else if (type == "IncorrectAns2") {
        this.showIncorrectAns2 = true;
      } else if (type == "IncorrectAns3") {
        this.showIncorrectAns3 = true;
      } else if (type == "IncorrectAns4") {
        this.showIncorrectAns4 = true;
      } else if (type == "IncorrectAns5") {
        this.showIncorrectAns5 = true;
      }
    },
    gettextform(data, type) {
      if (type == "question") {
        this.showquestion = false;
      } else if (type == "CorrectAns") {
        this.showCorrectAns = false;
      } else if (type == "IncorrectAns1") {
        this.showIncorrectAns1 = false;
      } else if (type == "IncorrectAns2") {
        this.showIncorrectAns2 = false;
      } else if (type == "IncorrectAns3") {
        this.showIncorrectAns3 = false;
      } else if (type == "IncorrectAns4") {
        this.showIncorrectAns4 = false;
      } else if (type == "IncorrectAns5") {
        this.showIncorrectAns5 = false;
      }
    },
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData();
      formData.append("file", file);
      var http = LinkService.getLink()+"/uploadimage?name=" + file.name;
      axios
        .post(http, formData)
        .then(() => {
      Editor.insertEmbed(
        cursorLocation,
        "image",
        LinkService.getLink()+"/uploads/" + file.name
      );
      resetUploader();
      var im = this.question.question.split('img')
      this.question.question = im[0]+'img style="width:30%;"'+im[1]
          // console.log(res.data);
          // this.question.image = file.name;
          // var q = this.question.question;
          // this.question.question =
          //   q +
          //   '<img style="width:30%" src="' +
          //   "https://api-test.ponnipa.in.th/uploads/" +
          //   file.name +
          //   '"/>';
          console.log(this.question);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteanswer(index) {
      for (let l = 0; l < this.answer.length; l++) {
        if (l == index) {
          this.answer.splice(index, 1);
        }
      }
    },
    addinputanswer() {
      this.answer.push({
        answer: "",
        id: 0,
      });
    },
    getlevel() {
      LevelService.getlevel(this.question_id).then((res) => {
        this.levels = res.data;
        // console.log(this.levels);
      });
    },
    getquestion() {
      QuestionObject.getQuestionObject(this.question_id, this.format).then(
        (res) => {
          this.question = res.data;
          console.log(this.question);
        }
      );
    },
    getquestions() {
      QuestionObject.getQuestionObjects(this.format).then((res) => {
        this.question.no = res.data.length + 1;
        console.log(this.question);
      });
    },
    getanswers() {
      SubjectTestService.getanswerobjectivetest(
        this.question_id,
        this.question.answer_id
      ).then((res) => {
        this.answer = res.data;
        // var ans = 15 - this.answer.length;
        // for (let a = 0; a < ans; a++) {
        //   this.answer.push({
        //     answer: "",
        //     id: 0,
        //     subjecttest_id: this.question_id,
        //   });
        // }
        console.log(this.answer);
      });
    },
    save() {
      if (this.question.question == null) {
        alert("กรุณากรอกคำถาม");
      } else if (this.question.CorrectAns == null) {
        alert("กรุณากรอกคำตอบที่ถูกต้อง");
      } else if (this.question.IncorrectAns1 == null || this.question.IncorrectAns2 == null || this.question.IncorrectAns3 == null || this.question.IncorrectAns4 == null) {
        alert("กรุณากรอกคำตอบลวง");
      } else {
        // console.log(this.question);
        var question = {
          question_set_id: this.format,
          no: this.question.no,
          question: this.question.question,
          CorrectAns: this.question.CorrectAns,
          IncorrectAns1: this.question.IncorrectAns1,
          IncorrectAns2: this.question.IncorrectAns2,
          IncorrectAns3: this.question.IncorrectAns3,
          IncorrectAns4: this.question.IncorrectAns4,
          index_id:this.question.no,
          type:this.type
        };
        // console.log(question);
        if (this.question_id == 0) {
          // console.log(test);
          QuestionObject.createQuestionObject(question).then((res) => {
            // console.log(res.data.id);
            this.question_id = res.data.id;
            var random = {
              question_set_id: this.format,
              question_id: this.question_id,
              level: this.question.level,
              status: this.question.status,
              weight: 0,
              type:this.type
            };
            RandomService.createrandom(random).then(() => {});
            alert("บันทึกสำเร็จ");
            // this.$router.go(-1)
            // location.reload();
            // this.$router.push("/import?id=" + this.format);
            if (this.page == 'import') {
                    this.$router.push("/import?id=" + this.format);
                  }else{
                    this.$router.push("/edittest?id=" + this.format);
                  }
          });
        } else {
          QuestionObject.updateQuestionObject(this.question_id, question).then(
            () => {
              var random = {
                level: this.question.level,
                status: this.question.status,
              };
              RandomService.updateRandom(this.question.random_id, random).then(
                () => {}
              );
              alert("บันทึกสำเร็จ");
              // this.$router.go(-1)
              // location.reload();
              // this.$router.push("/import?id=" + this.format);
              if (this.page == 'import') {
                    this.$router.push("/import?id=" + this.format);
                  }else{
                    this.$router.push("/edittest?id=" + this.format);
                  }
            }
          );
        }
      }
    },
  },

  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.vertical-left {
  margin-left: 20px;
}
.title {
  color: blue;
}
</style>
