<template>
  <div id="app" style="margin: 0px; height: 100%">
    <div  v-if="status">
<section><router-view /></section>
    </div>
    <div class="row" style="margin-right: 0px" v-else>
      <input type="checkbox" id="check" />
      <label for="check">
        <i class="fa fa-bars" id="btn"></i>
        <i style="color: white" class="fa fa-times" id="cancel"></i>
      </label>
      <div class="sidebar">
        <header>Test</header>
        <ul>
          <li v-if="!currentUser" style="color: #7e51ab">
            <a href="/login">เข้าสู่ระบบ</a>
          </li>
          <li v-if="!currentUser" style="color: #7e51ab">
            <a href="/register">สมัครสมาชิก</a>
          </li>
          <li style="color: #7e51ab" v-for="m in menu" :key="m.id">
            <a :href="m.url">{{ m.name }}</a>
          </li>

          <li style="color: #7e51ab">
            <a href="#" @click.prevent="logOut" v-if="currentUser"
              >ออกจากระบบ</a
            >
          </li>
        </ul>
      </div>
      <section><router-view /></section>
      <!-- <Nav msg="Welcome to Your Vue.js App" /> -->
    </div>
  </div>
</template>

<script>
// import Nav from "./components/Nav.vue";
import UserService from "./services/UserService";
import AnswerTempTrainingService from "./services/AnswerTempTrainingService";

export default {
  name: "App",
  data() {
    return {
      status: false,
      menu: [],
    };
  },
  components: {
    // Nav,
  },
  mounted() {
    // console.log(this.$route);
    if (this.currentUser) {
      UserService.getMenuByRole(this.currentUser.role_id).then((res) => {
        // console.log(res.data);
        this.menu = res.data;
        this.status = false
        // for (let m = 0; m < res.data.length; m++) {
        //   var cla = "";
        //   if (res.data[m].url == this.$route.fullPath) {
        //     cla = "active";
        //   } else if (
        //     this.$route.name == "Q1" ||
        //     this.$route.name == "Q2" ||
        //     this.$route.name == "Q3" ||
        //     this.$route.name == "import"
        //   ) {
        //     if (res.data[m].id == 1) {
        //       cla = "active";
        //     }
        //   }
        //   this.menu.push({
        //     id: res.data[m].id,
        //     url: res.data[m].url,
        //     name: res.data[m].name,
        //     class: cla,
        //   });
        // }
      });
    }else{
      this.status = true
    }
  },
  methods: {
    logOut() {
      if (this.currentUser.role_id) {
              AnswerTempTrainingService.DeleteAnswerTemps(
                      this.currentUser.user_id
                    ).then(() => {
                    });
            }
      this.$store.dispatch("auth/logout");
      setTimeout(function () {
        location.reload();
      }, 500);

      this.$router.push("/");
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<style>
ol, ul {
    padding-left: 0.5rem;
}
.sidebar {
  position: fixed;
  left: -200px;
  width: 200px!important;;
  height: 100%;
  background: #7e51ab;
  transition: all 0.5s ease;
}
.sidebar header {
  font-size: 20px;
  color: #7e51ab;
  line-height: 40px;
  text-align: center;
  background: #7e51ab;
  user-select: none;
}
.sidebar ul a {
  display: block;
  height: 100%;
  width: 100%;
  line-height: 27px;
  font-size: 12px;
  color: white;
  padding-left: 0px;
  box-sizing: border-box;
  border-bottom: 1px solid black;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  transition: 0.4s;
  text-decoration: none;
}
ul li:hover a {
  padding-left: 15px;
}
.sidebar ul a i {
  margin-right: 10px;
}
#check {
  display: none;
}
label #btn,
label #cancel {
  position: absolute;
  background: #042331;
  border-radius: 3px;
  cursor: pointer;
}
label #btn {
  left: 20px;
  top: 25px;
  font-size: 35px;
  color: white;
  padding: 6px 12px;
  transition: all 0.5s;
}
label #cancel {
  z-index: 1111;
  left: -195px;
  top: 17px;
  font-size: 30px;
  color: #0a5275;
  padding: 4px 9px;
  transition: all 0.5s ease;
}
#check:checked ~ .sidebar {
  left: 0;
}
#check:checked ~ label #btn {
  left: 250px;
  opacity: 0;
  pointer-events: none;
}
#check:checked ~ label #cancel {
  left: 150px;
}
#check:checked ~ section {
  margin-left: 200px;
}
section {
  background-position: center;
  background-size: cover;
  height: 100vh;
  transition: all 0.5s;
  width: 100%;
}
</style>