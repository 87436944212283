<template>
  <div>
    <clip-loader :loading="loading" :color="color" :size="size"></clip-loader>
  <div class="row mt-5" v-if="!loading">
    <div class="col-md-6">
      <apexchart
        width="500"
        type="bar"
        :options="options"
        :series="series"
      ></apexchart>
    </div>
    <div class="col-md-12">
      <apexchart
        width="1200"
        type="bar"
        :options="optionsone"
        :series="seriesone"
      ></apexchart>
    </div>
    <div class="col-md-12">
      <apexchart
        height="1400"
        type="bar"
        :options="optionstwo"
        :series="seriestwo"
      ></apexchart>
    </div>
    <div class="col-md-12">
       <apexchart
        height="1200"
        type="bar"
        :options="optionsthree"
        :series="seriesthree"
      ></apexchart>
    </div>
   
  </div>
</div>
</template>

<script>
import DashboardService from "../services/DashboardService";
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default {
  name: "Nav",
  components: {
    ClipLoader
  },
  props: {
    msg: String,
  },
  data() {
    return {
      color: '#5bc0de',
      size: '50px',
      margin: '2px',
      radius: '2px',
      loading:true,
      options: {},
      series: [],
      category: [],
      data: [],
      list: [],
      optionsone: {},
      seriesone: [],
      categoryone: [],
      dataone: [],
      listone: [],
      optionstwo: {},
      seriestwo: [],
      categorytwo: [],
      datatwo: [],
      listtwo: [],
      optionsthree: {},
      seriesthree: [],
      categorythree: [],
      datathree: [],
      listthree: [],
    };
  },
  mounted() {
    DashboardService.getAllLevelDashboards().then(async (res) => {
      console.log("level", res.data);
      this.list = res.data[0].id;
      this.category = res.data[0].categories;
      this.data = res.data[0].data;
      this.getData();
      await DashboardService.getTypeOneDashboards().then(async (resone) => {
        console.log("one", resone.data);
        this.listone = resone.data[0].id;
        this.categoryone = resone.data[0].categories;
        this.dataone = resone.data[0].data;
        this.getDataOne();
        await DashboardService.getTypeTwoDashboards().then(async (restwo) => {
          console.log("two", restwo.data);
          this.listtwo = restwo.data[0].id;
          this.categorytwo = restwo.data[0].categories;
          this.datatwo = restwo.data[0].data;
          this.getDataTwo();
          await DashboardService.getTypeThreeDashboards().then((resthree) => {
            console.log("three", resthree.data);
            this.listthree = resthree.data[0].id;
          this.categorythree = resthree.data[0].categories;
          this.datathree = resthree.data[0].data;
          this.getDataThree();
          this.loading = false
          });
        });
      });
    });
    // DashboardService.getTypeTwoDashboards().then((restwo) => {
    //     console.log(restwo.data);
    //   });
    //   DashboardService.getTypeOneDashboards().then((resone) => {
    //     console.log(resone.data);
    //   });
    // this.getData();
  },
  methods: {
    getData() {
      this.loadOption();
      this.loadData();
    },
    getDataOne() {
      this.loadOptionOne();
      this.loadDataOne();
    },
    getDataTwo() {
      this.loadOptionTwo();
      this.loadDataTwo();
    },
    getDataThree() {
      this.loadOptionThree();
      this.loadDataThree();
    },
    loadOptionThree() {
      this.optionsthree = {
        chart: {
          type: "bar",
          events: {
            dataPointSelection: (event, chartContext, config) => {
              // console.log(config);
              console.log(config.dataPointIndex);
              var id = ''
              // console.log(this.list);
              for (let r = 0; r < this.listthree.length; r++) {
                // console.log(r);
                if (config.dataPointIndex == r) {
                  id = this.listthree[r]
                }
              }
              console.log(id);
              window.open("detailtype/" + id, "_blank");
            },
            // click: function (event, chartContext, config) {
            //     window.open("detaillevel/" + config.xaxis.categories[config.dataPointIndex], "_blank");
            // },
          },
          toolbar: {
      show: false
    }
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: this.categorythree,
          labels: {
              formatter: function (val) {
                return val.toFixed(0);
              }
            },
        },
        yaxis: {
          title: {
            text: "",
          },
        },
        colors: [
          "#ae548e",
        ],
        tooltip: {
          y: {
            formatter: function (val) {
              return  + val.toFixed(0) + " คน";
            },
          },
        },
        title: {
          text: "กราฟแสดงจำนวนผู้ทำข้อสอบอัตนัย",
        },
        style: {
          fontSize: "9px",
          fontWeight: "bold",
          fontFamily: "Niramit",
          color: "#263238",
        },
      };
    },
    loadDataThree() {
      this.seriesthree = [
        {
          name: "จำนวน",
          data: this.datathree,
        },
      ];
    },
    loadOptionTwo() {
      this.optionstwo = {
        chart: {
          type: "bar",
          // height: this.list.length * 50,
          events: {
            dataPointSelection: (event, chartContext, config) => {
              // console.log(config);
              console.log(config.dataPointIndex);
              var id = "";
              // console.log(this.list);
              for (let r = 0; r < this.listtwo.length; r++) {
                // console.log(r);
                if (config.dataPointIndex == r) {
                  id = this.listtwo[r];
                }
              }
              console.log(id);
              window.open("detailtype/" + id, "_blank");
            },
            // click: function (event, chartContext, config) {
            //     window.open("detaillevel/" + config.xaxis.categories[config.dataPointIndex], "_blank");
            // },
          },
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: this.categorytwo,
          labels: {
            formatter: function (val) {
              return val.toFixed(0);
            },
          },
        },
        colors: ["#597194"],
        tooltip: {
          y: {
            formatter: function (val) {
              return +val.toFixed(0) + " คน";
            },
          },
        },
        title: {
          text: "กราฟแสดงจำนวนผู้ทำข้อสอบปรนัย แบบที่ 2",
        },
        style: {
          fontSize: "9px",
          fontWeight: "bold",
          fontFamily: "Niramit",
          color: "#263238",
        },
      };
    },
    loadDataTwo() {
      this.seriestwo = [
        {
          name: "จำนวน",
          data: this.datatwo,
        },
      ];
    },
    loadOptionOne() {
      this.optionsone = {
        chart: {
          type: "bar",
          events: {
            dataPointSelection: (event, chartContext, config) => {
              // console.log(config);
              console.log(config.dataPointIndex);
              var id = "";
              // console.log(this.list);
              for (let r = 0; r < this.listone.length; r++) {
                // console.log(r);
                if (config.dataPointIndex == r) {
                  id = this.listone[r];
                }
              }
              console.log(id);
              window.open("detailtype/" + id, "_blank");
            },
            // click: function (event, chartContext, config) {
            //     window.open("detaillevel/" + config.xaxis.categories[config.dataPointIndex], "_blank");
            // },
          },
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: this.categoryone,
          labels: {
            formatter: function (val) {
              return val.toFixed(0);
            },
          },
          decimalsInFloat: 0,
        },
        colors: ["#f3a1b7"],
        tooltip: {
          y: {
            formatter: function (val) {
              return +val.toFixed(0) + " คน";
            },
          },
        },
        title: {
          text: "กราฟแสดงจำนวนผู้ทำข้อสอบปรนัย แบบที่ 1",
        },
        style: {
          fontSize: "9px",
          fontWeight: "bold",
          fontFamily: "Niramit",
          color: "#263238",
        },
      };
    },
    loadDataOne() {
      this.seriesone = [
        {
          name: "จำนวน",
          data: this.dataone,
        },
      ];
    },
    loadOption() {
      this.options = {
        chart: {
          type: "bar",
          events: {
            dataPointSelection: (event, chartContext, config) => {
              // console.log(config);
              console.log(config.dataPointIndex);
              var id = "";
              // console.log(this.list);
              for (let r = 0; r < this.list.length; r++) {
                // console.log(r);
                if (config.dataPointIndex == r) {
                  id = this.list[r];
                }
              }
              console.log(id);
              // window.open("detaillevel/" + id, "_blank");
              window.open("settingweight", "_blank");
            },
            // click: function (event, chartContext, config) {
            //     window.open("detaillevel/" + config.xaxis.categories[config.dataPointIndex], "_blank");
            // },
          },
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: this.category,
          labels: {
            formatter: function (val) {
              return val.toFixed(0);
            },
          },
          decimalsInFloat: 0,
        },
        colors: ["#42ea6a"],
        tooltip: {
          y: {
            formatter: function (val) {
              return +val.toFixed(0) + " คน";
            },
          },
        },
        title: {
          text: "กราฟแสดงข้อสอบที่เข้าเกณฑ์การปรับระดับความยากง่ายของข้อสอบ",
          // text: "กราฟแสดงข้อสอบที่เข้าเกณฑ์การปรับระดับความง่าย ปานกลาง ยาก",
        },
        style: {
          fontSize: "8px",
          fontWeight: "bold",
          fontFamily: "Niramit",
          color: "#263238",
        },
      };
    },
    loadData() {
      this.series = [
        {
          name: "จำนวน",
          data: this.data,
        },
      ];
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
