<template>
  <div class="container-fluid row mt-5" style="padding: 30px">
    <apexchart
      width="500"
      type="bar"
      :options="options"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import DashboardService from "../services/DashboardService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      options: {},
      series: [],
      category: [],
      data: [],
      list:[]
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      DashboardService.getTypeThreeDashboards().then((res) => {
        console.log(res.data);
        this.list = res.data
        for (let r = 0; r < res.data.length; r++) {
          this.category.push(res.data[r].categories)
        this.data.push(res.data[r].data)
        }
        
        // console.log(this.category );
        //  console.log(this.data );
        this.loadOption();
        this.loadData();
      });
    },

    loadOption() {
      this.options = {
        chart: {
          type: "bar",
          events: {
            dataPointSelection: (event, chartContext, config) => {
              // console.log(config);
              console.log(config.dataPointIndex);
              var id = ''
              console.log(this.list);
              for (let r = 0; r < this.list.length; r++) {
                console.log(r);
                if (config.dataPointIndex == r) {
                  id = this.list[r].id
                }
              }
              console.log(id);
              window.open("detailtype/" + id, "_blank");
            },
            // click: function (event, chartContext, config) {
            //     window.open("detaillevel/" + config.xaxis.categories[config.dataPointIndex], "_blank");
            // },
          },
          toolbar: {
      show: false
    }
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: this.category,
          labels: {
              formatter: function (val) {
                return val.toFixed(0);
              }
            },
        },
        yaxis: {
          title: {
            text: "",
          },
        },
        colors: [
          "#ae548e",
        ],
        tooltip: {
          y: {
            formatter: function (val) {
              return  + val.toFixed(0) + " คน";
            },
          },
        },
        title: {
          text: "กราฟแสดงจำนวนผู้ทำข้อสอบอัตนัย",
        },
        style: {
          fontSize: "9px",
          fontWeight: "bold",
          fontFamily: "Niramit",
          color: "#263238",
        },
      };
    },
    loadData() {
      this.series = [
        {
          name: "จำนวน",
          data: this.data,
        },
      ];
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
