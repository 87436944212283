<template>
  <div class="container-fluid row mt-5" style="padding: 30px">
      
    <div v-for="l in levels" :key="l.id">
    <div class="col-md-12">
      <label for="exampleInputEmail1" class="form-label">ระดับ{{l.name}}</label>
    </div>
    <div>
    <div class="input-group mb-3">
      <span class="input-group-text">ตั้งแต่</span>
  <input type="text" class="form-control-input" aria-label="Username" v-model="l.percent_from">
  <span class="input-group-text">-</span>
  <input type="text" class="form-control-input" style="text-align:right" aria-label="Server" v-model="l.percent_to">
  <span class="input-group-text"></span>
</div>
</div>
    </div>
    <div class="text-left mb-3 mt-3">
      <button @click="save()" type="button" class="btn btn-success btn-sm">
        บันทึก
      </button>
    </div>
  </div>
</template>

<script>
import LevelService from "../services/LevelService";
import ManageTest from "../services/ManageTest";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      list: [],
      question: {},
      levels:[]
    };
  },
  mounted() {
    this.getmanagetest()
    this.getlevels()
  },
  methods: {
    getmanagetest(){
ManageTest.getManageTest().then((res)=>{
  console.log(res.data);
  this.question = res.data
})
    },
    save() {
      for (let m = 0; m < this.levels.length; m++) {
        var data = {
        percent_from: this.levels[m].percent_from,
        percent_to: this.levels[m].percent_to
      };
        LevelService.updatelevel(this.levels[m].id,data).then(() => {
          // console.log(res.data);
        });
      }
     alert('บันทึกสำเร็จ')   
    },
    getlevels(){
LevelService.getlevel().then((res)=>{
  this.levels = res.data
})
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
.form-control-input {
    display: block;
    width: 10%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
}
</style>
