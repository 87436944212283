<template>
  <div class="container-fluid row">
    <div class="card mt-5 col-md-12" style="width: 500px">
      <div class="mb-3 mt-3">
        <label for="exampleInputEmail1" class="form-label">ชุดทดสอบ</label>
        <select
          v-model="question"
          class="form-select form-select-sm"
          aria-label=".form-select-sm example"
          @change="gettype"
        >
          <option :value="q.id" v-for="q in questionset" :key="q.id">
            {{ q.question_name }}
          </option>
        </select>
      </div>
    </div>
    <div
      class="col-md-12 mt-5"
      style="margin: 15px"
      v-for="q in list"
      :key="q.type"
    >
      <div class="text-right mb-3" style="float: right">
        <a :href="'/Q' + q.type + '/0' + '/' + question+ '/' + q.type+'/edit'">
          <button type="button" class="btn btn-success btn-sm">
            <i class="fa fa-plus"></i>
          </button>
        </a>
      </div>
      <table class="table table-bordered" v-if="q.type == 1 || q.type == 3">
        <thead>
           <tr style="background-color:#C0BEBE">
    <th colspan="19" style="text-align:center">ข้อสอบ{{q.name}}</th>
  </tr>
          <tr style="text-align: center">
            <th
              v-if="q.type == 1"
              scope="col"
              style="background-color: #92d050"
            >
              ข้อ
            </th>
            <th
              v-if="q.type == 3"
              scope="col"
              style="background-color: #92d050"
            >
              จำนวนคำตอบ
            </th>
            <th scope="col" style="background-color: #92d050">คำถาม</th>
             <th scope="col" style="background-color: #92d050">ระดับข้อสอบ</th>
            <th scope="col" style="background-color: #92d050" colspan="15">คำตอบที่ถูกต้อง</th>
            <!-- <th scope="col" style="background-color: #92d050">Correct Ans1</th>
            <th scope="col" style="background-color: #92d050">Correct Ans2</th>
            <th scope="col" style="background-color: #92d050">Correct Ans3</th>
            <th scope="col" style="background-color: #92d050">Correct Ans4</th>
            <th scope="col" style="background-color: #92d050">Correct Ans5</th>
            <th scope="col" style="background-color: #92d050">Correct Ans6</th>
            <th scope="col" style="background-color: #92d050">Correct Ans7</th>
            <th scope="col" style="background-color: #92d050">Correct Ans8</th>
            <th scope="col" style="background-color: #92d050">Correct Ans9</th>
            <th scope="col" style="background-color: #92d050">Correct Ans10</th>
            <th scope="col" style="background-color: #92d050">Correct Ans11</th>
            <th scope="col" style="background-color: #92d050">Correct Ans12</th>
            <th scope="col" style="background-color: #92d050">Correct Ans13</th>
            <th scope="col" style="background-color: #92d050">Correct Ans14</th>
            <th scope="col" style="background-color: #92d050">Correct Ans15</th> -->
            <th scope="col" style="background-color: #92d050"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="l in q.quess" :key="l.id">
            <th scope="row" style="text-align: center" v-if="l.type == 1">{{ l.index_id }}</th>
            <th scope="row" style="text-align: center" v-if="l.type == 3">{{ l.no }}</th>
            <td scope="row" style="width:40%">
              <span v-html="l.question"></span>
            </td>
            <td scope="row">
              <span v-html="l.levelname"></span>
            </td>
            <td scope="row">
              <span v-html="l.CorrectAns1"></span>
            </td>
            <td scope="row"><span v-html="l.CorrectAns2"></span></td>
            <td scope="row"><span v-html="l.CorrectAns3"></span></td>
            <td scope="row"><span v-html="l.CorrectAns4"></span></td>
            <td scope="row"><span v-html="l.CorrectAns5"></span></td>
            <td scope="row"><span v-html="l.CorrectAns6"></span></td>
            <td scope="row"><span v-html="l.CorrectAns7"></span></td>
            <td scope="row"><span v-html="l.CorrectAns8"></span></td>
            <td scope="row"><span v-html="l.CorrectAns9"></span></td>
            <td scope="row"><span v-html="l.CorrectAns10"></span></td>
            <td scope="row"><span v-html="l.CorrectAns11"></span></td>
            <td scope="row"><span v-html="l.CorrectAns12"></span></td>
            <td scope="row"><span v-html="l.CorrectAns13"></span></td>
            <td scope="row"><span v-html="l.CorrectAns14"></span></td>
            <td scope="row"><span v-html="l.CorrectAns15"></span></td>
            <td scope="row">
              <a :href="'/Q' + l.type + '/' + l.id + '/' + l.question_set_id+ '/' + l.type+'/edit'">
                <button type="button" class="btn btn-warning btn-sm">
                  <i class="fa fa-edit"></i>
                </button> </a
              >&nbsp;
              <button
                type="button"
                @click="getid(l)"
                class="btn btn-danger btn-sm"
                data-bs-toggle="modal"
                data-bs-target="#deletedata"
              >
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="table table-bordered" v-if="q.type == 2">
        <thead>
           <tr style="background-color:#C0BEBE">
    <th colspan="9" style="text-align:center">ข้อสอบ{{q.name}}</th>
  </tr>
          <tr style="text-align: center">
            <th scope="col" style="background-color: #92d050">ข้อ</th>
            <th scope="col" style="background-color: #92d050">คำถาม</th>
             <th scope="col" style="background-color: #92d050">ระดับข้อสอบ</th>
            <th scope="col" style="background-color: #92d050">คำตอบที่ถูกต้อง</th>
            <th scope="col" style="background-color: #ffc000" colspan="4">
              คำตอบลวง
            </th>
            <!-- <th scope="col" style="background-color: #92d050">Correct Ans</th>
            <th scope="col" style="background-color: #ffc000">
              Incorrect Ans 1
            </th>
            <th scope="col" style="background-color: #ffc000">
              Incorrect Ans 2
            </th>
            <th scope="col" style="background-color: #ffc000">
              Incorrect Ans 3
            </th>
            <th scope="col" style="background-color: #ffc000">
              Incorrect Ans 4
            </th> -->
             <th scope="col" style="background-color: #ffc000">
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="l in q.quess" :key="l.id">
            <th scope="row" style="text-align: center">{{ l.no }}</th>
            <td style="width:40%">
              <span v-html="l.question"></span>
              <!-- {{ l.question }} -->
              <br />
            </td>
            <td scope="row">
              <span v-html="l.levelname"></span>
            </td>
            <td scope="row">
              <span v-html="l.CorrectAns"></span>
            </td>
            <td scope="row">
              <span v-html="l.IncorrectAns1"></span>
            </td>
            <td scope="row">
              <span v-html="l.IncorrectAns2"></span>
            </td>
            <td scope="row">
              <span v-html="l.IncorrectAns3"></span>
            </td>
            <td scope="row">
              <span v-html="l.IncorrectAns4"></span>
            </td>
            <td scope="row">
              <a :href="'/Q' + l.type + '/' + l.id + '/' + l.question_set_id+ '/' + l.type+'/edit'">
                <button type="button" class="btn btn-warning btn-sm">
                  <i class="fa fa-edit"></i>
                </button> </a
              >&nbsp;
              <button
                type="button"
                @click="getid(l)"
                class="btn btn-danger btn-sm"
                data-bs-toggle="modal"
                data-bs-target="#deletedata"
              >
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="deletedata"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">ยืนยันการลบ</h5>
            <button
              id="closedeletedoc"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger btn-sm" @click="deletedata()">
              ยืนยัน
            </button>
            <button
              type="button"
              class="btn btn btn-secondary btn-sm"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionObject from "../services/QuestionObject";
import RandomService from "../services/RandomService";
import QuestionSetService from "../services/QuestionSetService";
import PrototypeTest from "../services/PrototypeTest";
import axios from 'axios';
import LinkService from "../services/LinkService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      concert_id: 0,
      menu: [],
      user: {},
      file: [],
      question: "1",
      list: [],
      questionid: {},
      questionset: [],
      format: "",
      type: "",
      typelist:[]
    };
  },
  mounted() {
    PrototypeTest.getPrototypeTests().then((res)=>{
      this.typelist = res.data
    })
    this.question = this.$route.query.id;
    QuestionSetService.getQuestionSet(this.question).then((res) => {
      // console.log(res.data);
      this.format = res.data.format;
      this.getdata();
    });
    this.getQuestionSets();
  },
  methods: {
    onChangeImage(evt){
const files = evt.target.files || evt.dataTransfer.files;
      // const selectedImage = evt.target.files[0]; // get first file
      // this.createBase64Image(selectedImage);
      if (!files.length) return;
      var count = files.length
      var c = 0
      for (let f = 0; f < files.length; f++) {
      this.onUploadFileChangeAns(files[f]);
      c=c+1
        if (count == c) {
          alert('อัพโหลดรูปภาพ '+count+' ไฟล์ เรียบร้อยแล้ว')
          document.getElementById("imagelist").value = null;          
        }
      }
    },
    onUploadFileChangeAns(selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile); // appending file
      //  sending file to the backend
      //console.log(this.filename);
      // console.log(selectedFile);
      var http = LinkService.getLink() + '/uploadimage?name=' +selectedFile.name;
      axios
        .post(http, formData)
        .then(() => {    
          // console.log(res.data);     
        })
    },
    getQuestionSets() {
      QuestionSetService.getQuestionSets().then((res) => {
        this.questionset = res.data;
        // console.log(res.data);
      });
    },
    getid(id) {
      // console.log(id);
      this.questionid = id;
    },
    deletedata() {
      // console.log(this.questionid);
      var ques = {
        status: 0,
        level: this.questionid.level,
      };

      RandomService.updateRandom(this.questionid.random_id, ques).then(() => {
        // console.log(res.data);
        this.getdata();
      document.getElementById("closedeletedoc").click();
      });
    },
    checkq(data) {
      // console.log(data);
      var result = "";
      if (data != null) {
        result = data.includes("Q");
      } else {
        result = data;
      }
      return result;
    },
    gettype(evt) {
      this.question = evt.target.value;
      QuestionSetService.getQuestionSet(this.question).then((res) => {
        // console.log(res.data);
        this.$router.push("/edittest?id=" + this.question);
        this.format = res.data.format;
        this.getdata();
      });
      // console.log(this.question);
    },
    getdata() {
      // console.log(this.format);
      this.list = [];
      QuestionObject.getimportlist(this.question).then((res) => {
        this.list = res.data;
        this.type = "";
        console.log(this.list);
      });
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
</style>
