import http from "../http-common";

class LevelService {
  getlevel() {
    return http.get(`/level`);
  }
  getlevelTest() {
    return http.get(`/level/findLevelTest`);
  }
  updatelevel(id, data) {
    return http.put(`/level/${id}`, data);
  }
}

export default new LevelService();