import http from "../http-common";

class QuestionObject {
  createQuestionObject(data) {
    return http.post('/question_objectivetest', data);
  }
  getQuestionObjects(type) {
    return http.get('/question_objectivetest?name='+type);
  }
  getQuestionObject(id,set_id) {
    return http.get('/question_objectivetest/'+id+'/'+set_id);
  }  
  gettestall(type) {
    return http.get('/question_objectivetest/gettestall?name='+type);
  }
  getimportlist(type) {
    return http.get('/question_objectivetest/getimportlist?name='+type);
  }
  updateQuestionObject(id, data) {
    return http.put(`/question_objectivetest/${id}`, data);
  }
  updateQuestionSubjectLevel(id,type, data) {
    return http.put(`/question_objectivetest/updatelevel/${id}/${type}`, data);
  }
  updateQuestionObjectStatus(id,type, data) {
    return http.put(`/question_objectivetest/updatestatus/${id}/${type}`, data);
  }
  updateQuestionObjectCount(id, data) {
    return http.put(`/question_objectivetest/updatecount/${id}`, data);
  }
  deleteQuestionObject(id, data) {
    return http.delete(`/question_objectivetest/deletebysetid/${id}`, data);
  }
}

export default new QuestionObject();