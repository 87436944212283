<template>
  <div class="container row mb-3" style="margin: auto">
        <clip-loader :loading="loading" :color="color" :size="size" class="mt-5"></clip-loader>
        <div class="text-center mt-3" v-if="loading">
          ระบบกำลังประมวลผล กรุณารอสักครู่
      </div>
        <div v-if="!loading">
        <div class="row mt-3" v-if="questionstatus">
      <div style="width: 5%"></div>
      <div class="col-md-8" style="width: 55%">
        <div class="card col-md-12" style="height: 100%">
          <div class="mt-3 mb-3">
            <div style="padding: 10px">
              <div v-if="time.status == 1" style="text-align:right">
            เวลาในการทำข้อสอบ {{ HourCount }}:{{ timerCount }} นาที
          </div>
          <!-- {{question}} -->
              <label class="mb-3 mt-3"
                >{{ question.index }}.  <span v-html="question.question"></span></label
              ><br />
              <div v-if="question.type == 3">
                <div
                  class="form-check mb-3"
                  v-for="(a, i) in question.key"
                  :key="i"
                >
                  <input
                    v-model="a.answer"
                    class="form-control"
                    style="width: 30%"
                    type="text"
                    id="flexRadioDefault1"
                    @change="saveanswer()"
                  />
                </div>
              </div>
              <div
                v-else
                class="form-check mb-3"
                v-for="(a, i) in question.key"
                :key="i"
              >
                <input
                  v-model="question.answer1"
                  class="form-check-input"
                  type="radio"
                  :name="question.index"
                  :id="a.answer"
                  :key="a.answer"
                  :value="a.answer"
                  @change="saveanswer()"
                />
                <label class="form-check-label" :for="a.answer">
                  <span v-html="a.answer"></span>
                </label>
              </div>
            </div>
          </div>
          
          <div class="text-right mb-3">
            <button
              type="button"
              class="btn btn-info"
              @click="back()"
              style="float: left; margin: 5px"
              v-if="question.index != 1"
            >
              <i class="fa fa-arrow-left"></i>
            </button>
            <button
              type="button"
              class="btn btn-info"
              @click="next()"
              style="float: right; margin: 5px"
              v-if="question.index != questions.length"
            >
              <i class="fa fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
      <div
        class="col-md-4"
        style="width: 35%; overflow-y: scroll; height: 600px"
      >
        <div style="padding: 15px; height: 100%">
          <div class="row">
            <div
              class="col-md-12 mb-3"
              v-for="(q, i) in questions"
              :key="i"
              @click="getquestion(q, i + 1)"
            >
              <div class="myDIV" style="margin: auto; cursor: pointer">
                <div
                  style="background-color: #93fba6;border-style: solid;
                    border-width: thin;
                    padding: 5px;
                  "
                  v-if="q.type == 3 && q.answer1"
                >
                  ข้อที่ {{ i + 1 }}.  <span v-html="q.question"></span><br />
                  <div
                    class="form-check col-md-5"
                    v-for="(a, i) in q.key"
                    :key="i"
                  >
                    <input
                      disabled
                      v-model="a.answer"
                      class="form-control form-control-sm"
                      style="width: 100%"
                      type="text"
                      id="flexRadioDefault1"
                    />
                  </div>
                </div>
                <div
                  v-if="q.type == 3 && !q.answer1"
                  style="border-style: solid; border-width: thin; padding: 5px"
                >
                  ข้อที่ {{ i + 1 }}. <span v-html="q.question"></span><br />
                  <div
                    class="form-check col-md-5"
                    v-for="(a, i) in q.key"
                    :key="i"
                  >
                    <input
                      disabled
                      :v-model="a.answer"
                      class="form-control form-control-sm"
                      style="width: 100%"
                      type="text"
                      id="flexRadioDefault1"
                    />
                  </div>
                </div>
                <div
                  style="
                    background-color: #93fba6;
                    border-style: solid;
                    border-width: thin;
                    padding: 5px;
                  "
                  v-if="q.type != 3 && q.answer1"
                >
                  ข้อที่ {{ i + 1 }}. <span v-html="q.question"></span><br />                  
                  <div class="form-check" v-for="(a, i) in q.key" :key="i">
                    <input
                      disabled
                      v-model="q.answer1"
                      class="form-check-input"
                      type="radio"
                      :name="q.question"
                      :id="a.answer"
                      :key="a.answer"
                      :value="a.answer"
                    />
                    
                    <label class="form-check-label">
                      <span v-html="a.answer"></span>
                    </label>
                  </div>
                </div>
                <div
                  style="border-style: solid; border-width: thin; padding: 5px"
                  v-if="q.type != 3 && !q.answer1"
                >
                  ข้อที่ {{ i + 1 }}. <span v-html="q.question"></span><br />
                  
                  <div class="form-check" v-for="(a, i) in q.key" :key="i">
                    <input
                      disabled
                      v-model="q.answer1"
                      class="form-check-input"
                      type="radio"
                      :name="q.question"
                      :id="a.answer"
                      :key="a.answer"
                      :value="a.answer"
                    />
                    <label class="form-check-label">
                      <span v-html="a.answer"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 5%"></div>
      <div class="text-center mt-3">
        <span style="color: red">*</span> ทำข้อสอบครบทุกข้อ
        คลิกปุ่มยืนยันการส่งข้อสอบ
        <br />
        <button
          type="button"
          class="btn btn-success btn-sm"
          @click="save()"
          style="float: center; margin: 5px"
        >
          ยืนยันการส่งข้อสอบ
        </button>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import RandomService from "../services/RandomService";
import QuestionSubject from "../services/QuestionSubject";
import QuestionObject from "../services/QuestionObject";
import AnswerTrainingService from "../services/AnswerTrainingService";
import AnswerTempTrainingService from "../services/AnswerTempTrainingService";
import ManageTime from "../services/ManageTime";
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  components: {
    ClipLoader
  },
  data() {
    return {
      questions: [],
      question: {},
      status: true,
      timerCount: 0,
      HourCount: 0,
      time: {},
      test_no:{},
      questionstatus:false,
      color: '#5bc0de',
      size: '50px',
      margin: '2px',
      radius: '2px',
      loading:false,
    };
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/");
    } else {
      this.getrandom();
    }
  },
  methods: {
    gettime() {
      ManageTime.getManageTime().then((res) => {
        this.time = res.data;
        // console.log(this.time);
        if (this.time.status == 1) {
          this.timerCount = 60;
          this.HourCount = this.time.time - 1;
          this.time.out = 10;
          // this.HourCount=2
          //  this.time.out = 1
        }
      });
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
   save() {
      var check = false;
      for (let q = 0; q < this.questions.length; q++) {
        if (this.questions[q].answer1 == "") {
          alert("กรุณาตอบคำถามให้ครบทุกข้อ");
          check = true;
          break;
        }
      }
      if (check == false) {
    this.loading = true
        for (let q = 0; q < this.questions.length; q++) {
          var answer = [];
          if (
            this.questions[q].type == 1 ||
            this.questions[q].type == 3
          ) {
            QuestionSubject.getQuestionSubject(
              this.questions[q].question_id,
              this.questions[q].question_set_id
            ).then((res) => {
              // console.log(res.data);
              var score = 0;
              answer = [];
              if (res.data.CorrectAns1 != "-" && res.data.CorrectAns1 != null) {
                answer.push(res.data.CorrectAns1);
              }
              if (res.data.CorrectAns2 != "-" && res.data.CorrectAns2 != null) {
                answer.push(res.data.CorrectAns2);
              }
              if (res.data.CorrectAns3 != "-" && res.data.CorrectAns3 != null) {
                answer.push(res.data.CorrectAns3);
              }
              if (res.data.CorrectAns4 != "-" && res.data.CorrectAns4 != null) {
                answer.push(res.data.CorrectAns4);
              }
              if (res.data.CorrectAns5 != "-" && res.data.CorrectAns5 != null) {
                answer.push(res.data.CorrectAns5);
              }
              if (res.data.CorrectAns6 != "-" && res.data.CorrectAns6 != null) {
                answer.push(res.data.CorrectAns6);
              }
              if (res.data.CorrectAns7 != "-" && res.data.CorrectAns7 != null) {
                answer.push(res.data.CorrectAns7);
              }
              if (res.data.CorrectAns8 != "-" && res.data.CorrectAns8 != null) {
                answer.push(res.data.CorrectAns8);
              }
              if (res.data.CorrectAns9 != "-" && res.data.CorrectAns9 != null) {
                answer.push(res.data.CorrectAns9);
              }
              if (res.data.CorrectAns10 != "-" && res.data.CorrectAns10 != null) {
                answer.push(res.data.CorrectAns10);
              }
              if (res.data.CorrectAns11 != "-" && res.data.CorrectAns11 != null) {
                answer.push(res.data.CorrectAns11);
              }
              if (res.data.CorrectAns12 != "-" && res.data.CorrectAns12 != null) {
                answer.push(res.data.CorrectAns12);
              }
              if (res.data.CorrectAns13 != "-" && res.data.CorrectAns13 != null) {
                answer.push(res.data.CorrectAns13);
              }
              if (res.data.CorrectAns14 != "-" && res.data.CorrectAns14 != null) {
                answer.push(res.data.CorrectAns14);
              }
              if (res.data.CorrectAns15 != "-" && res.data.CorrectAns15 != null) {
                answer.push(res.data.CorrectAns15);
              }
              var allscore = this.questions[q].no;
              allscore = 1 / allscore;
              // console.log(allscore);
              // console.log(answer);
              if (this.questions[q].type == 1) {
                // let foundans = answer.includes(this.questions[q].answer1)
                let foundans = answer.find(
                  (element) => element == this.questions[q].answer1
                );
                // console.log(foundans);

                if (foundans) {
                  score = 1;
                }
                var data = {
                  index: this.questions[q].index,
                  image: this.questions[q].image,
                  question_id: this.questions[q].question_id,
                  question: this.questions[q].question,
                  question_set_id: this.questions[q].question_set_id,
                  no: this.questions[q].no,
                  student_id: this.currentUser.user_id,
                  ans1: this.questions[q].ans1,
                  ans2: this.questions[q].ans2,
                  ans3: this.questions[q].ans3,
                  ans4: this.questions[q].ans4,
                  ans5: this.questions[q].ans5,
                  answer1: this.questions[q].answer1,
                  answer2: this.questions[q].answer2,
                  answer3: this.questions[q].answer3,
                  score: score,
                  type: this.questions[q].type,
                  random_id: this.questions[q].random_id,
                test_no: this.questions[q].test_no,
                };
                // console.log(data);
                // AnswerTrainingService.getallbtstuidandtestno(this.stuid,this.test_no,this.questions[q].index).then((count) => {
                  // console.log(count);
                  // if (count.data.length == 0) {
                    AnswerTrainingService.createAnswerStudent(data).then(() => {
                  // console.log(res.data);
                });
                  // }
              // })
                if (score != 0) {
                  var count = { weight: score };
                  RandomService.updateRandomWeight(
                    this.questions[q].random_id,
                    count
                  ).then(() => {
                    // console.log(res.data);
                  });
                }
              } else {
                score = 0;
                var ansstu = [];
                for (let a = 0; a < this.questions[q].key.length; a++) {
                  ansstu.push(this.questions[q].key[a].answer);
                  // console.log(this.questions[q].key[a].answer);
                //   var foundall = answer.filter(str => str.includes((this.questions[q].key[a].answer).toLowerCase())
                // );
                // // console.log(foundall);
                //   if (foundall.length != 0) {
                //     score = score + allscore;
                //   }
                RandomService.getCorrect(this.questions[q].question_set_id,this.questions[q].question_id,this.questions[q].key[a].answer).then((res)=>{
                // console.log(foundall);
                  if (res.data.length > 0) {
                    score = score + allscore;
                  }
                })
                }
                data = {
                  index: this.questions[q].index,
                  image: this.questions[q].image,
                  question_id: this.questions[q].question_id,
                  question: this.questions[q].question,
                  question_set_id: this.questions[q].question_set_id,
                  no: this.questions[q].no,
                  student_id: this.currentUser.user_id,
                  ans1: this.questions[q].ans1,
                  ans2: this.questions[q].ans2,
                  ans3: this.questions[q].ans3,
                  ans4: this.questions[q].ans4,
                  ans5: this.questions[q].ans5,
                  answer1: this.questions[q].answer1,
                  answer2: this.questions[q].answer2,
                  answer3: this.questions[q].answer3,
                  score: score,
                  type: this.questions[q].type,
                  random_id: this.questions[q].random_id,
                test_no: this.questions[q].test_no,
                };
                // console.log(data);
                // AnswerTrainingService.getallbtstuidandtestno(this.stuid,this.test_no,this.questions[q].index).then((count) => {
                //   // console.log(count);
                //   if (count.data.length == 0) {
                    AnswerTrainingService.createAnswerStudent(data).then(() => {
                  // console.log(res.data);
                });
              //     }
              // })
                if (score != 0) {
                  count = { weight: score };
                  RandomService.updateRandomWeight(
                    this.questions[q].random_id,
                    count
                  ).then(() => {
                    // console.log(res.data);
                  });
                }
                // console.log(data);
              }
            });
          } else if (this.questions[q].type == 2) {
            var score = 0;
            QuestionObject.getQuestionObject(
              this.questions[q].question_id,
              this.questions[q].question_set_id
            ).then((res) => {
              score = 0;
              // console.log(res.data.CorrectAns);
              console.log(this.questions[q].answer1);
              if (res.data.CorrectAns == this.questions[q].answer1) {
                score = 1;
              }
              var data = {
                index: this.questions[q].index,
                image: this.questions[q].image,
                question_id: this.questions[q].question_id,
                question: this.questions[q].question,
                question_set_id: this.questions[q].question_set_id,
                no: this.questions[q].no,
                student_id: this.currentUser.user_id,
                ans1: this.questions[q].ans1,
                ans2: this.questions[q].ans2,
                ans3: this.questions[q].ans3,
                ans4: this.questions[q].ans4,
                ans5: this.questions[q].ans5,
                answer1: this.questions[q].answer1,
                answer2: this.questions[q].answer2,
                answer3: this.questions[q].answer3,
                score: score,
                type: this.questions[q].type,
                random_id: this.questions[q].random_id,
                test_no: this.questions[q].test_no,
              };
              // console.log(data);

              // AnswerTrainingService.getallbtstuidandtestno(this.stuid,this.test_no,this.questions[q].index).then((count) => {
              //     // console.log(count);
              //     if (count.data.length == 0) {
                    AnswerTrainingService.createAnswerStudent(data).then(() => {
                  // console.log(res.data);
                });
              //     }
              // })
              if (score != 0) {
                var count = { weight: score };
                RandomService.updateRandomWeight(
                  this.questions[q].random_id,
                  count
                ).then(() => {
                  // console.log(res.data);
                });
              }
            });
          }
        }
        setTimeout(() => {
          AnswerTempTrainingService.DeleteAnswerTemps(
            this.currentUser.user_id
          ).then(() => {
            alert("บันทึกสำเร็จ");
            this.$router.push("/result");
          });
        }, 3000);
      }
    },
    saveanswer() {
      var data = {};
      if (this.question.type == 3) {
        var ans1 = "";
        var ans2 = "";
        var ans3 = "";
        for (let a = 0; a < this.question.key.length; a++) {
          if (a == 0) {
            ans1 = this.question.key[a].answer;
          }
          if (a == 1) {
            ans2 = this.question.key[a].answer;
          }
          if (a == 2) {
            ans3 = this.question.key[a].answer;
          }
        }
        data = {
          answer1: ans1,
          answer2: ans2,
          answer3: ans3,
        };
      } else {
        data = {
          answer1: this.question.answer1,
        };
      }
      AnswerTempTrainingService.updateAnswerTemp(this.question.id, data).then(
        () => {
          if (this.question.type == 3) {
            AnswerTempTrainingService.getAnswerTemps(
              this.currentUser.user_id,
              this.test_no
            ).then((res) => {
              // console.log(res.data);
              this.questions = res.data;
              this.question = this.questions[this.question.index-1];
              // this.question.index = this.question.index;
            });
          }
        }
      );
    },
    back() {
      // console.log(this.question);
      var data = {};
      if (this.question.type == 3) {
        var ans1 = "";
        var ans2 = "";
        var ans3 = "";
        for (let a = 0; a < this.question.key.length; a++) {
          if (a == 0) {
            ans1 = this.question.key[a].answer;
          }
          if (a == 1) {
            ans2 = this.question.key[a].answer;
          }
          if (a == 2) {
            ans3 = this.question.key[a].answer;
          }
        }
        data = {
          answer1: ans1,
          answer2: ans2,
          answer3: ans3,
        };
      } else {
        data = {
          answer1: this.question.answer1,
        };
      }
      AnswerTempTrainingService.updateAnswerTemp(this.question.id, data).then(
        () => {
          // console.log(res.data);
          for (let q = 0; q < this.questions.length; q++) {
        // console.log(q);
        if (q == this.question.index - 2) {
          // console.log(q);
          this.question = {};
          // console.log(this.questions[q]);
          this.question = this.questions[q];
          this.question.index = q + 1;
          break;
        }
      }
        }
      );
      
    },
    next() {
      // var ques = this.questions;
      // this.questions = [];
      // this.questions = ques;
      // console.log(this.question);
      var data = {};
      if (this.question.type == 3) {
        var ans1 = "";
        var ans2 = "";
        var ans3 = "";
        // console.log(this.question.key);
        for (let a = 0; a < this.question.key.length; a++) {
          // console.log(this.question.key[a].answer);
          if (a == 0) {
            ans1 = this.question.key[a].answer;
          }
          if (a == 1) {
            ans2 = this.question.key[a].answer;
          }
          if (a == 2) {
            ans3 = this.question.key[a].answer;
          }
        }
        data = {
          answer1: ans1,
          answer2: ans2,
          answer3: ans3,
        };
      } else {
        data = {
          answer1: this.question.answer1,
        };
      }
      AnswerTempTrainingService.updateAnswerTemp(this.question.id, data).then(
        () => {
          // console.log(res.data);
          for (let q = 0; q < this.questions.length; q++) {
        if (q == this.question.index) {
          // console.log(q);
          // console.log(this.question.index);
          this.question = {};
          // console.log(this.questions[q]);
          this.question = this.questions[q];
          this.question.index = q + 1;
          // console.log(this.question);
          break;
        }
      }
        }
      );
      
    },
    checkq(data) {
      if (data) {
        return data.includes("Q");
      } else {
        return false;
      }
    },
    getquestion(q, i) {
      this.question = q;
      this.question.index = i;
      // console.log(this.question.key);
    },
    getquestions() {
      // console.log(this.test_no);
      AnswerTempTrainingService.getAnswerTemps(this.currentUser.user_id,this.test_no).then(
        (res) => {
          // console.log(res.data.length);
          // console.log(this.questions.length);
          if (res.data.length != 0 && res.data.length == this.questions.length) {
        this.questions = [];
          this.questions = res.data;
          this.question = this.questions[0];               
            this.gettime();       
            this.questionstatus = true
          }else{
            this.questions = []
            this.questionstatus = false
            this.getrandom();
          }
        }
      );
    },
    createquestion() {
      RandomService.getRandoms(10).then((res) => {
        this.questions = res.data;
        console.log(this.questions);
        for (let q = 0; q < this.questions.length; q++) {
          var ans1 = "";
          var ans2 = "";
          var ans3 = "";
          var ans4 = "";
          var ans5 = "";
          for (let a = 0; a < this.questions[q].key.length; a++) {
            var index = a + 1;
            if (index == 1) {
              ans1 = this.questions[q].key[a].answer;
            } else if (index == 2) {
              ans2 = this.questions[q].key[a].answer;
            } else if (index == 3) {
              ans3 = this.questions[q].key[a].answer;
            } else if (index == 4) {
              ans4 = this.questions[q].key[a].answer;
            } else if (index == 5) {
              ans5 = this.questions[q].key[a].answer;
            }
          }
          if (this.questions[q].type != 3) {
            this.questions[q].no = 0;
          }

          var ans = {
            index: q + 1,
            image: this.questions[q].image,
            question_id: this.questions[q].id,
            question: this.questions[q].question,
            question_set_id: this.questions[q].questiontype,
            no: this.questions[q].no,
            student_id: this.currentUser.user_id,
            ans1: ans1,
            ans2: ans2,
            ans3: ans3,
            ans4: ans4,
            ans5: ans5,
            answer1: "",
            answer2: "",
            answer3: "",
            random_id: this.questions[q].random_id,
            type: this.questions[q].type,
            test_no:this.test_no
          };
          // console.log(ans);
          AnswerTempTrainingService.createAnswerTemp(ans).then(() => {
            // console.log(res.data);
            if (this.questions.length == q+1) {              
        this.getquestions();
            }
          });
        }
      });
    },
    getrandom() {
      setTimeout(() => {}, 100);
      AnswerTrainingService.getAnswerStudents(this.currentUser.user_id).then(
        (res) => {
          if (res.data.length > 0) {
             this.test_no = res.data[0].test_no+1    
          } else {
             this.test_no = 1   
          }
          // console.log(this.test_no);
          AnswerTempTrainingService.DeleteAnswerTemps(
              this.currentUser.user_id
            ).then(() => {
              this.createquestion();
            });
        }
      );
    },
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        } else if (this.HourCount > 0) {
          this.HourCount = this.HourCount - 1;
          this.timerCount = 60;
        }
        if (this.time.status == 1) {
          if (this.time.out == this.HourCount && this.timerCount == this.time.time-59) {
          alert("ใกล้หมดเวลาทำข้อสอบ กรุณาเตรียมยืนยันการส่งข้อสอบ");
        }
        if (this.HourCount == 0 && this.timerCount == 0) {
          AnswerTempTrainingService.DeleteAnswerTemps(
            this.currentUser.user_id
          ).then(() => {
            this.createquestion();
          });
        }
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.vertical-left {
  margin-left: 20px;
}
.title {
  color: blue;
}
.hide {
  display: none;
}

.myDIV:hover + .hide {
  display: inline-block;
  color: blue;
}
</style>
