import http from "../http-common";

class SubjectTestService {
  createsubjecttest(data) {
    return http.post('/subjecttest', data);
  }
  createanswer_subjecttest(data) {
    return http.post('/answer_subjecttest', data);
  }
  getsubjecttests() {
    return http.get(`/subjecttest?type=`);
  }
  getanswersubjecttests(subjecttest_id) {
    return http.get(`/answer_subjecttest/getrandomanswersubjecttest?subjecttest_id=`+subjecttest_id);
  }
  getsubjecttest(id,set_id) {
    return http.get(`/subjecttest/`+id+'/'+set_id);
  }
  updatesubjecttest(id, data) {
    return http.put(`/subjecttest/${id}`, data);
  }
  getobjectivetestnames() {
    return http.get('/objectivetest_name');
  }
  getobjectivetestname(id,set_id) {
    return http.get(`/objectivetest_name/`+id+'/'+set_id);
  }
  createobjectivetestname(data) {
    return http.post('/objectivetest_name', data);
  }
  createanswerobjectivetestname(data) {
    return http.post('/answer_objectivetest_name', data);
  }
  createobjectivetest(data) {
    return http.post('/objectivetest', data);
  }
  getobjectivetest(id,set_id) {
    return http.get(`/objectivetest/`+id+'/'+set_id);
  }
  createanswerobjectivetest(data) {
    return http.post('/answer_objectivetest', data);
  }
  updateobjectivetest(id, data) {
    return http.put(`/objectivetest/${id}`, data);
  }
  getobjectivetests(type) {
    return http.get(`/objectivetest?type=`+type);
  }
  getsubjecttestlist() {
    return http.get(`/subjecttest/getlist`);
  }
  getobjectivetestnamelist() {
    return http.get(`/objectivetest_name/getlist`);
  }
  getobjectivetestlist(type) {
    return http.get(`/objectivetest/getlist?type=`+type);
  }
  getanswersubjecttest(subjecttest_id) {
    return http.get(`/answer_subjecttest?id=`+subjecttest_id);
  }
  getanswerobjectivetestname(id) {
    return http.get(`/answer_objectivetest_name?id=`+id);
  }
  getanswerobjectivetest(id,ans) {
    return http.get(`/answer_objectivetest?id=`+id+'&ansid='+ans);
  }
  updateanswersubjecttest(id, data) {
    return http.put(`/answer_subjecttest/${id}`, data);
  }
  deleteanswersubjecttest(id) {
    return http.delete(`/answer_subjecttest/`+id);
  }
  updateobjectivetest_name(id, data) {
    return http.put(`/objectivetest_name/${id}`, data);
  }
  updateanswer_objectivetest(id, data) {
    return http.put(`/answer_objectivetest/${id}`, data);
  }
  deleteanswerobjectivetestname(id) {
    return http.delete(`/answer_objectivetest_name/`+id);
  }
  deleteanswerobjectivetest(id,ans_id) {
    return http.delete(`/answer_objectivetest/`+id+'/'+ans_id);
  }
}

export default new SubjectTestService();