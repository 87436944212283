import http from "../http-common";

class AnswerTimesService {
  createAnswerTime(data) {
    return http.post(`/answer_times`, data);
  }
  getAnswerTime() {
    return http.get(`/answer_times`);
  }
  updateAnswerTime(id, data) {
    return http.put(`/answer_times/${id}`, data);
  }
}

export default new AnswerTimesService();