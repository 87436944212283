import { render, staticRenderFns } from "./DetailLevelGraph.vue?vue&type=template&id=2e71b3b4&scoped=true&"
import script from "./DetailLevelGraph.vue?vue&type=script&lang=js&"
export * from "./DetailLevelGraph.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e71b3b4",
  null
  
)

export default component.exports