<template>
  <div class="container-fluid row">
    <div class="card mt-5 col-md-12" style="width: 500px">
      <div class="mb-3 mt-3">
        <label for="exampleInputEmail1" class="form-label">ชุดทดสอบ</label>
        <select
          v-model="question"
          class="form-select form-select-sm"
          aria-label=".form-select-sm example"
           @change="gettype"
        >
          <option :value="q.id" v-for="q in questionset" :key="q.id">
            {{ q.question_name }}
          </option>
        </select>
      </div>
      <div class="social-auth-links text-center mb-3 mt-3">
        <button @click="exportTableToExcel('tblData')" type="button" class="btn btn-success btn-sm">
          Export
        </button>
      </div>
    </div>
     <div class="container-fluid row mt-3" style="padding: 20px">     
       <div class="col-md-12">
        <table id="tblData">
      <table class="table table-bordered" v-for="(l,o) in orderBy(list,'id')" :key="o+1">
         <thead>
          <tr style="text-align: center">
            
            <th scope="col">ข้อที่ {{ l.id }} <span v-html="l.question"></span></th>
          </tr>
        </thead>
        <thead>
          <tr style="text-align: center">
            <th scope="col">คำตอบที่ถูกต้อง</th>
            <th scope="col" v-for="(c,i) in l.answercorrect" :key="i+2"><span v-html="c"> </span></th>
          </tr>
        </thead>
        <thead>
          <tr style="text-align: center">
            <!-- <th scope="col" style="background-color: #92d050">ข้อที่</th>
            <th scope="col" style="background-color: #92d050">คำถาม</th> -->
            <th scope="col" style="background-color: #92d050">ชุดข้อสอบ</th>
            <th scope="col" style="background-color: #92d050">
              จำนวนที่ตอบถูก
            </th>
            <th scope="col" style="background-color: #92d050">
              จำนวนผู้ทำข้อสอบ
            </th>
            <th scope="col" style="background-color: #92d050">เปอร์เซ็นต์</th>
            <!-- <th scope="col" style="background-color: #92d050">ระดับเดิม</th>
            <th scope="col" style="background-color: #92d050">ระดับใหม่</th> -->
            <!-- <th scope="col" style="background-color: #92d050"></th> -->
          </tr>
        </thead>
        <tbody>
          <tr>
            <!-- <td scope="row">{{ l.id }}</td>
            <td scope="row">{{ l.question }}</td> -->
            <td scope="row">{{ l.question_set_name }}</td>
            <td scope="row">{{ l.weight }}</td>
            <td scope="row">{{ l.notraing }}</td>
            <td scope="row">{{ l.percent }}%</td>
          </tr>
        </tbody>
        <thead v-if="l.answer.length > 0">
          <tr style="text-align: center">
            <!-- <th scope="col" style="background-color: #92d050">ข้อที่</th>
            <th scope="col" style="background-color: #92d050">คำถาม</th> -->
            <th scope="col" style="background-color: #92d050">คำตอบที่ตอบถูก</th>
            <th scope="col" style="background-color: #92d050">จำนวนคนที่ตอบถูก</th>
          </tr>
        </thead>
        <tbody v-if="l.answer.length > 0">
          <tr v-for="(a,i) in l.answer" :key="i+10">
            <!-- <td scope="row">{{ l.id }}</td>
            <td scope="row">{{ l.question }}</td> -->
            <td scope="row"><span v-html="a.answer"> </span></td>
            <td scope="row">{{a.point}}</td>
          </tr>
        </tbody>
         <thead v-if="l.wronganswer.length > 0">
          <tr style="text-align: center">
            <!-- <th scope="col" style="background-color: #92d050">ข้อที่</th>
            <th scope="col" style="background-color: #92d050">คำถาม</th> -->
            <th scope="col" style="background-color: #D05069">คำตอบที่ตอบผิด</th>
            <th scope="col" style="background-color: #D05069">จำนวนคนที่ตอบผิด</th>
          </tr>
        </thead>
        <tbody v-if="l.wronganswer.length > 0">
          <tr v-for="(l,w) in l.wronganswer" :key="w+5">
            <!-- <td scope="row">{{ l.id }}</td>
            <td scope="row">{{ l.question }}</td> -->
            <td scope="row"><span v-html="l.answer"> </span></td>
            <td scope="row">{{l.point}}</td>
          </tr>
        </tbody>
         <thead>
          <tr style="text-align: center">
            <th scope="col"></th>
          </tr>
        </thead>
      </table>
        </table>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="deletedata"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              ยืนยันการรีเซ็ตค่า
            </h5>
            <button
              id="closedeleteweight"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                value="1"
              />
              <label class="form-check-label" for="flexRadioDefault1">
                เปลี่ยน
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                value="0"
              />
              <label class="form-check-label" for="flexRadioDefault2">
                ไม่เปลี่ยน
              </label>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-success btn-sm"
              @click="deletedata()"
            >
              ยืนยัน
            </button>
            <button
              type="button"
              class="btn btn btn-secondary btn-sm"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import readXlsxFile from "read-excel-file";
import RandomService from "../services/RandomService";
import QuestionSetService from "../services/QuestionSetService";
import PrototypeTest from "../services/PrototypeTest";
import DashboardService from '../services/DashboardService.js'
import Vue2Filters from 'vue2-filters'
export default {
  name: "Nav",
  props: {
    msg: String,
  },
    mixins: [Vue2Filters.mixin],
  data() {
    return {
      concert_id: 0,
      menu: [],
      user: {},
      file: [],
      question: "1",
      list: [],
      questionid: {},
      questionset: [],
      typename: "",
      type: "",
      typelist:[],
      id:'',
      typedelete:''
    };
  },
  mounted() {
    PrototypeTest.getPrototypeTests().then((res)=>{
      this.typelist = res.data
    })
    this.question = this.$route.query.id;
    this.getdata()
    this.getQuestionSets();
  },
  methods: {
    exportTableToExcel(tableID, filename = "") {
      var downloadLink;
      var dataType = "application/vnd.ms-excel";
      var tableSelect = document.getElementById(tableID);
      var tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

      // Specify file name
      filename = filename
        ? filename + ".xls"
        : this.typename +".xls";

      // Create download link element
      downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        var blob = new Blob(["\ufeff", tableHTML], {
          type: dataType,
        });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = "data:" + dataType + ", " + tableHTML;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
    },
    getQuestionSets() {
      QuestionSetService.getQuestionSets().then((res) => {
        this.questionset = res.data;
        // console.log(res.data);
      });
    },
    gettype(evt){
      this.question = evt.target.value;
      this.$router.push("/export?id=" + this.question);
this.getdata()
    },    
    getid(id) {
      // console.log(id);
      this.questionid = id;
    },
    closedeletetype() {
      // console.log(this.questionid);
      var ques = {
        status: 0,
        type: this.typedelete,
      };

      RandomService.updateRandomBytype(this.question, ques).then(() => {
        // console.log(res.data);
        this.getdata();
      document.getElementById("closedeletetypemodal").click();
      });
    },
    deletedata() {
      // console.log(this.questionid);
      var ques = {
        status: 0,
        level: this.questionid.level,
      };

      RandomService.updateRandom(this.questionid.random_id, ques).then(() => {
        // console.log(res.data);
        this.getdata();
      document.getElementById("closedeletedoc").click();
      });
    },
    checkq(data) {
      // console.log(data);
      var result = "";
      if (data != null) {
        result = data.includes("Q");
      } else {
        result = data;
      }
      return result;
    },
    exportfile() {
    },
    getdata() {
      // console.log(this.format);
      this.list = [];
     DashboardService.getExcelDashboard(this.question).then((res)=>{
      // console.log(res.data);
      // this.list = res.data
      for (let r = 0; r < res.data.length; r++) {
          DashboardService.getDetailTypeDashboard(res.data[r].id).then((res)=>{
            this.list.push(
              res.data[0]
            )
          })
          
        }
      QuestionSetService.getQuestionSet(this.question).then((res) => {
        this.typename = res.data.question_name
        // console.log(res.data);
      });
    })
    },
    onChangeC1(event) {
      // var sheet = parseInt(this.question) + 1;
      // console.log(sheet);
      // readXlsxFile(event.target.files[0], { sheet: sheet }).then((data) => {
      //   this.file = data;
      // });
      readXlsxFile(event.target.files[0]).then((data) => {
        this.file = data;
      });
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
</style>
