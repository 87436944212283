<template>
  <div class="container-fluid row" style="width: 90%; margin: auto">
    <div class="card mt-5 col-md-12" style="margin: 10px">
      <div class="mb-3 mt-3">
        <div class="row">
          <div class="col-md-12 mb-3">
            <label for="exampleInputEmail1" class="form-label">ชุดทดสอบ</label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label for="exampleInputEmail1" class="form-label">คำถาม</label>
          </div>
          <div class="col-md-5 mb-3">
            <vue-editor 
            use-custom-image-handler 
            @image-added="handleImageAdded"
            v-model="question.question" />
            <!-- <input
              v-model="question.question"
              class="form-control"
              type="text"
              id="flexRadioDefault1"
            /> -->
          </div>
        </div>
         <!-- <div class="row">
          <div class="col-md-12">
        <label for="exampleFormControlSelect1">รูปภาพ</label>
         <input
          id="file"
          class="form-control form-control-sm"
          type="file"
          accept="image/*"
          @change="onFileChangeManual"
        />
      <img class="mt-3 mb-3" v-if="question.image != '-'" :src="'https://api-test.ponnipa.in.th/uploads/'+question.image" width="20%">
      </div>
         </div> -->
        <div class="row">
          <div class="col-md-12">
            <label for="exampleInputEmail1" class="form-label"
              >คำตอบที่ถูกต้อง</label
            >
          </div>
          <div class="col-md-5 mb-3">
            <input
              v-model="question.CorrectAns"
              class="form-control"
              type="text"
              id="flexRadioDefault1"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-3">
            <label for="exampleInputEmail1" class="form-label"
              >คำตอบลวง</label
            >
          </div>
          <div class="col-md-3 mb-3">
              <input
                v-model="question.IncorrectAns1"
                class="form-control"
                type="text"
                id="flexRadioDefault1"
              />
          </div>
           <div class="col-md-3 mb-3">
              <input
                v-model="question.IncorrectAns2"
                class="form-control"
                type="text"
                id="flexRadioDefault1"
              />
          </div>
           <div class="col-md-3 mb-3">
              <input
                v-model="question.IncorrectAns3"
                class="form-control"
                type="text"
                id="flexRadioDefault1"
              />
          </div>
           <div class="col-md-3 mb-3">
              <input
                v-model="question.IncorrectAns4"
                class="form-control"
                type="text"
                id="flexRadioDefault1"
              />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-3">
            <label for="exampleInputEmail1" class="form-label">สถานะ</label>
          </div>
          <div class="col-md-3 mb-3">
            <select
              v-model="question.status"
              class="form-select form-select-sm"
              aria-label=".form-select-sm example"
            >
              <option value="1">ใช้งานอยู่</option>
              <option value="0">ไม่ใช้งาน</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-3">
            <label for="exampleInputEmail1" class="form-label"
              >ระดับความยาก</label
            >
          </div>
          <div class="col-md-3 mb-3">
            <select
              v-model="question.level"
              class="form-select form-select-sm"
              aria-label=".form-select-sm example"
            >
              <option :value="l.id" v-for="(l, i) in levels" :key="'L1' + i">
                {{ l.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="social-auth-links text-center mb-3 mt-3">
        <button @click="save()" type="button" class="btn btn-success btn-sm">
          บันทึก
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SubjectTestService from "../services/SubjectTestService";
import LevelService from "../services/LevelService";
import RandomService from "../services/RandomService";
import QuestionObject from "../services/QuestionObject";
import axios from 'axios';

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  component: {},
  data() {
    return {
      question_id: 0,
      question: {},
      answer: [],
      levels: [],
      format:''
    };
  },
  mounted() {
    this.question_id = this.$route.params.id;
    this.format = this.$route.params.setid;
    console.log(this.question_id);
    if (this.question_id == 0) {
      this.getquestions();
      // this.answer.push({
      //   answer: "",
      //   id: 0,
      // });
      this.question.level = 1;
      this.question.status = 1;
    } else {
      this.getquestion();
    }
    this.getlevel();
  },
  methods: {
    handleImageAdded(file) {
      var formData = new FormData();
      formData.append("file", file);
      var http = this.link + "/uploadimage?name=" + file.name;
          axios
            .post(http, formData)
            .then(() => {
              // console.log(res.data);     
this.question.image = file.name 
var q = this.question.question
this.question.question = q + '<img style="width:30%" src="'+this.link+'/uploads/'+file.name+'"/>'  
// console.log(this.question.question);    
            })
            .catch((err) => {
              console.log(err);
            });
    },
    getnewfile(localUrl, name) {
      axios({
        method: "get",
        url: localUrl, // blob url eg. blob:http://127.0.0.1:8000/e89c5d87-a634-4540-974c-30dc476825cc
        responseType: "blob",
      }).then(function (response) {
        var reader = new FileReader();
        reader.readAsDataURL(response.data);
        reader.onloadend = function () {
          var base64data = reader.result;
          // console.log(base64data);
          var arr = base64data.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }

          var data = new File([u8arr], name, { type: mime });
          const formData = new FormData();
          formData.append("file", data); // appending file
          //  sending file to the backend

          // console.log(link);
          console.log(name);
          var http = this.link+"/uploadimage?name=" + name;
          axios
            .post(http, formData)
            .then(() => {
              // console.log(res.data);
              this.question.image = name
              
            })
            .catch((err) => {
              console.log(err);
            });
        };
      });
    },
    onFileChangeManual(evt) {
      const files = evt.target.files || evt.dataTransfer.files;
      // const selectedImage = evt.target.files[0]; // get first file
      // this.createBase64Image(selectedImage);
      if (!files.length) return;
      this.question.image = files[0].name
      this.onUploadFileImageManual(files[0]);
      // this.createBase64Image(selectedImage);
    },
    onUploadFileImageManual(selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile); // appending file
      //  sending file to the backend
      //console.log(this.filename);
      console.log(selectedFile);
      var http = this.link+'/uploadimage?name=' +selectedFile.name;
      axios
        .post(http, formData)
        .then(() => {         
        this.question.image = selectedFile.name
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteanswer(index) {
      for (let l = 0; l < this.answer.length; l++) {
        if (l == index) {
          this.answer.splice(index, 1);
        }
      }
    },
    addinputanswer() {
      this.answer.push({
        answer: "",
        id: 0,
      });
    },
    getlevel() {
      LevelService.getlevel(this.question_id).then((res) => {
        this.levels = res.data;
        // console.log(this.levels);
      });
    },
    getquestion() {
      QuestionObject.getQuestionObject(this.question_id,+this.format).then((res) => {
        this.question = res.data;
        this.question.question += '<img style="width:30%" src="'+this.link+'/uploads/'+this.question.image+'"/>'  
        console.log(this.question);
      });
    },
    getquestions() {
      QuestionObject.getQuestionObjects(this.format).then((res) => {
        this.question.count = res.data.length;
        console.log(this.question);
      });
    },
    getanswers() {
      SubjectTestService.getanswerobjectivetest(
        this.question_id,
        this.question.answer_id
      ).then((res) => {
        this.answer = res.data;
        // var ans = 15 - this.answer.length;
        // for (let a = 0; a < ans; a++) {
        //   this.answer.push({
        //     answer: "",
        //     id: 0,
        //     subjecttest_id: this.question_id,
        //   });
        // }
        console.log(this.answer);
      });
    },
    getimage(data){
      if (data) {
        // var im = data.includes('img')
        var path = data.split('src')
        // console.log(path[0]);
        path = path[1].split('"')
        console.log(path);
        return path[1]
      }else{
      return '-'
      }
    },
    save() {
      // console.log(this.question.description);
      // console.log(this.getimage(this.question.description));
      // this.getnewfile(
      //         message.files[f].localUrl,
      //         message.files[f].name + "." + message.files[f].extension
      //       );
      if (this.question.question == null) {
        alert("กรุณากรอกคำถาม");
      } else if (this.question.CorrectAns == null) {
        alert("กรุณากรอกคำตอบที่ถูกต้อง");
      } else if (this.question.IncorrectAns1 == "") {
        alert("กรุณากรอกคำตอบลวง");
      } else {
        console.log(this.question.question);
        // var image = this.getimage(this.question.question)
// var im = 'https://api-test.ponnipa.in.th/uploads/'+this.question.image
// console.log(this.question.question);
// this.question.question = this.question.question + '<img src="'+im+'">'
        var question = {
          question_set_id: +this.format,
              no: this.question.count+1,
              question: this.question.question,
              CorrectAns: this.question.CorrectAns,
              IncorrectAns1: this.question.IncorrectAns1,
              IncorrectAns2: this.question.IncorrectAns2,
              IncorrectAns3: this.question.IncorrectAns3,
              IncorrectAns4: this.question.IncorrectAns4,
              image:this.question.image,
        };
        // console.log(question);
        if (this.question_id == 0) {
          // console.log(test);
          QuestionObject.createQuestionObject(question).then((res) => {
            // console.log(res.data.id);
            this.question_id = res.data.id;
            var random = {
              question_set_id: +this.format,
              question_id: this.question_id,
              level: this.question.level,
              status: this.question.status,
              weight:0
            };
            RandomService.createrandom(random).then(() => {
            });
            // this.$router.push("/Q6/" + this.question_id);
            alert("บันทึกสำเร็จ");
            this.$router.push('/import?id='+this.format);
          });
        } else {
          QuestionObject.updateQuestionObject(
            this.question_id,
            question
          ).then(() => {
             var random = {
                level: this.question.level,
                status: this.question.status,
              };
              RandomService.updateRandom(this.question.random_id, random).then(
                () => {
                  });
                  setTimeout(() => {
                  this.$router.push('/import?id='+this.format);
          alert("บันทึกสำเร็จ");
        }, 500);
          });
        }
      }
    },
  },

  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.vertical-left {
  margin-left: 20px;
}
.title {
  color: blue;
}
</style>
