import http from "../http-common";

class MapTypeLevelService {
  createMap(data) {
    return http.post(`/map_types_level`, data);
  }
  deleteMap() {
    return http.delete(`/map_types_level`);
  }
  getcounttestbylevelandtype(level,type) {
    return http.get(`/map_types_level/getcounttestbylevelandtype?level=`+level+'&&type='+type);
  }
}

export default new MapTypeLevelService();