<template>
  <div class="container-fluid row" style="width: 90%; margin: auto">
    <div class="card mt-5 col-md-12" style="margin: 10px">
      <div class="mb-3 mt-3">
        <div class="row">
          <div class="col-md-12 mb-3">
            <label for="exampleInputEmail1" class="form-label">ชุดทดสอบ</label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label for="exampleInputEmail1" class="form-label">คำถาม</label>
          </div>
          <div class="col-md-5 mb-3">
            <input
              v-model="question.question"
              class="form-control"
              type="text"
              id="flexRadioDefault1"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label for="exampleInputEmail1" class="form-label"
              >คำตอบที่ถูกต้อง</label
            >
          </div>
          <div class="col-md-5 mb-3">
            <div v-if="checkq(question.CorrectAns)">
              <input
          id="correctans"
          class="form-control form-control-sm"
          type="file"
          accept="image/*"
          @change="onFileChangeAns"
        />        
        <img class="mt-3 mb-3" :src="link+'/uploads/'+question.CorrectAns" width="20%"></div>
            <input v-else
              v-model="question.CorrectAns"
              class="form-control"
              type="text"
              id="flexRadioDefault1"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-3">
            <label for="exampleInputEmail1" class="form-label"
              >คำตอบลวง</label
            >
          </div>
          <div class="col-md-3 mb-3">
             <div v-if="checkq(question.IncorrectAns1)">
            <input
          id="incorrectans1"
          class="form-control form-control-sm"
          type="file"
          accept="image/*"
          @change="onFileChangeAns"
        />        
             <img class="mt-3 mb-3" :src="link+'/uploads/'+question.IncorrectAns1" width="20%"></div>
              <input v-else
                v-model="question.IncorrectAns1"
                class="form-control"
                type="text"
                id="flexRadioDefault1"
              />
          </div>
           <div class="col-md-3 mb-3">
            <div v-if="checkq(question.IncorrectAns2)">
            <input
          id="incorrectans2"
          class="form-control form-control-sm"
          type="file"
          accept="image/*"
          @change="onFileChangeAns"
        />        
             <img class="mt-3 mb-3" :src="link+'/uploads/'+question.IncorrectAns2" width="20%"></div>
              <input v-else
                v-model="question.IncorrectAns2"
                class="form-control"
                type="text"
                id="flexRadioDefault1"
              />
          </div>
           <div class="col-md-3 mb-3">
            <div v-if="checkq(question.IncorrectAns3)">
            <input
          id="incorrectans3"
          class="form-control form-control-sm"
          type="file"
          accept="image/*"
          @change="onFileChangeAns"
        />       
             <img class="mt-3 mb-3" :src="link+'/uploads/'+question.IncorrectAns3" width="20%"></div>
              <input v-else
                v-model="question.IncorrectAns3"
                class="form-control"
                type="text"
                id="flexRadioDefault1"
              />
          </div>
           <div class="col-md-3 mb-3">
             <div v-if="checkq(question.IncorrectAns4)">
            <input
          id="incorrectans4"
          class="form-control form-control-sm"
          type="file"
          accept="image/*"
          @change="onFileChangeAns"
        />       
             <img class="mt-3 mb-3" :src="link+'/uploads/'+question.IncorrectAns4" width="20%"></div>
              <input v-else
                v-model="question.IncorrectAns4"
                class="form-control"
                type="text"
                id="flexRadioDefault1"
              />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-3">
            <label for="exampleInputEmail1" class="form-label">สถานะ</label>
          </div>
          <div class="col-md-3 mb-3">
            <select
              v-model="question.status"
              class="form-select form-select-sm"
              aria-label=".form-select-sm example"
            >
              <option value="1">ใช้งานอยู่</option>
              <option value="0">ไม่ใช้งาน</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-3">
            <label for="exampleInputEmail1" class="form-label"
              >ระดับความยาก</label
            >
          </div>
          <div class="col-md-3 mb-3">
            <select
              v-model="question.level"
              class="form-select form-select-sm"
              aria-label=".form-select-sm example"
            >
              <option :value="l.id" v-for="(l, i) in levels" :key="'L1' + i">
                {{ l.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="social-auth-links text-center mb-3 mt-3">
        <button @click="save()" type="button" class="btn btn-success btn-sm">
          บันทึก
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SubjectTestService from "../services/SubjectTestService";
import LevelService from "../services/LevelService";
import RandomService from "../services/RandomService";
import QuestionObject from "../services/QuestionObject";
import axios from 'axios';
import LinkService from "../services/LinkService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  component: {},
  data() {
    return {
      question_id: 0,
      question: {},
      answer: [],
      levels: [],
      format:'',
      link:''
    };
  },
  mounted() {
    this.link = LinkService.getLink()
    this.question_id = this.$route.params.id;
    this.format = this.$route.params.setid;
    // console.log(this.question_id);
    if (this.question_id == 0) {
      this.getquestions();
      // this.answer.push({
      //   answer: "",
      //   id: 0,
      // });
      this.question.level = 1;
      this.question.status = 1;
    } else {
      this.getquestion();
    }
    this.getlevel();
  },
  methods: {    
    onFileChangeAns(evt) {
      // console.log(document.getElementById("correctans").value);
      const files = evt.target.files || evt.dataTransfer.files;
      // const selectedImage = evt.target.files[0]; // get first file
      // this.createBase64Image(selectedImage);
      if (!files.length) return;
      this.onUploadFileChangeAns(files[0],evt.target.id);
      // this.createBase64Image(selectedImage);
    },
    onUploadFileChangeAns(selectedFile,name) {
      const formData = new FormData();
      formData.append("file", selectedFile); // appending file
      //  sending file to the backend
      //console.log(this.filename);
      // console.log(selectedFile);
      var http = LinkService.getLink()+'/uploadimage?name=' +selectedFile.name;
      axios
        .post(http, formData)
        .then(() => {         
          
      // console.log(name);
      if (name == 'correctans') {
        this.question.CorrectAns = selectedFile.name
      }else if (name == 'incorrectans1') {
        this.question.IncorrectAns1 = selectedFile.name
      }else if (name == 'incorrectans2') {
        this.question.IncorrectAns2 = selectedFile.name
      }else if (name == 'incorrectans3') {
        this.question.IncorrectAns3 = selectedFile.name
      }else if (name == 'incorrectans4') {
        this.question.IncorrectAns4 = selectedFile.name
      }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onFileChangeManual(evt) {
      // console.log(document.getElementById("correctans").value);
      const files = evt.target.files || evt.dataTransfer.files;
      // const selectedImage = evt.target.files[0]; // get first file
      // this.createBase64Image(selectedImage);
      if (!files.length) return;
      this.onUploadFileImageManual(files[0]);
      // this.createBase64Image(selectedImage);
    },
    onUploadFileImageManual(selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile); // appending file
      //  sending file to the backend
      //console.log(this.filename);
      console.log(selectedFile);
      var http = LinkService.getLink()+'/uploadimage?name=' +selectedFile.name;
      axios
        .post(http, formData)
        .then(() => {         
        this.question.image = selectedFile.name
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkq(data) {
      // console.log(data);
      var result = ''
      if (data != null) {
      result =  data.includes("Q");        
      }else{
result = data
      }
      return result
    },
    deleteanswer(index) {
      for (let l = 0; l < this.answer.length; l++) {
        if (l == index) {
          this.answer.splice(index, 1);
        }
      }
    },
    addinputanswer() {
      this.answer.push({
        answer: "",
        id: 0,
      });
    },
    getlevel() {
      LevelService.getlevel(this.question_id).then((res) => {
        this.levels = res.data;
        // console.log(this.levels);
      });
    },
    getquestion() {
      QuestionObject.getQuestionObject(this.question_id,this.format).then((res) => {
        this.question = res.data;
        console.log(this.question);
      });
    },
    getquestions() {
      QuestionObject.getQuestionObjects(this.format).then((res) => {
        this.question.no = res.data.length+1;
        console.log(this.question);
      });
    },
    getanswers() {
      SubjectTestService.getanswerobjectivetest(
        this.question_id,
        this.question.answer_id
      ).then((res) => {
        this.answer = res.data;
        // var ans = 15 - this.answer.length;
        // for (let a = 0; a < ans; a++) {
        //   this.answer.push({
        //     answer: "",
        //     id: 0,
        //     subjecttest_id: this.question_id,
        //   });
        // }
        console.log(this.answer);
      });
    },
    save() {
      if (this.question.question == null) {
        alert("กรุณากรอกคำถาม");
      } else if (this.question.CorrectAns == null) {
        alert("กรุณากรอกคำตอบที่ถูกต้อง");
      } else if (this.question.IncorrectAns1 == "") {
        alert("กรุณากรอกคำตอบลวง");
      } else {
        // console.log(this.question);
        var question = {
          question_set_id: this.format,
              no: this.question.no,
              question: this.question.question,
              CorrectAns: this.question.CorrectAns,
              IncorrectAns1: this.question.IncorrectAns1,
              IncorrectAns2: this.question.IncorrectAns2,
              IncorrectAns3: this.question.IncorrectAns3,
              IncorrectAns4: this.question.IncorrectAns4,
        };
        // console.log(question);
        if (this.question_id == 0) {
          // console.log(test);
          QuestionObject.createQuestionObject(question).then((res) => {
            // console.log(res.data.id);
            this.question_id = res.data.id;
            var random = {
              question_set_id: this.format,
              question_id: this.question_id,
              level: this.question.level,
              status: this.question.status,
              weight:0
            };
            RandomService.createrandom(random).then(() => {
            });
            // this.$router.push("/Q5/" + this.question_id);
            alert("บันทึกสำเร็จ");
            this.$router.push('/import?id='+this.format);
          });
        } else {
          QuestionObject.updateQuestionObject(
            this.question_id,
            question
          ).then(() => {
             var random = {
                level: this.question.level,
                status: this.question.status,
              };
              RandomService.updateRandom(this.question.random_id, random).then(
                () => {
                  });
                  alert("บันทึกสำเร็จ");
                  this.$router.push('/import?id='+this.format);
          });
        }
      }
    },
  },

  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.vertical-left {
  margin-left: 20px;
}
.title {
  color: blue;
}
</style>
