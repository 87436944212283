<template>
  <div class="container row">
    <div class="col mb-3 mt-3" style="text-align: right">
      <a>
        <button
          type="button"
          id="get_file"
          class="btn btn-success btn-sm"
          @click="getid(0)"
          data-bs-toggle="modal"
          data-bs-target="#AddUser"
        >
          <i class="fa fa-plus"></i> เพิ่มผู้รับผิดชอบรายวิชา
        </button></a
      >
    </div>
    <div class="col-md-12 mb-3 mt-3" style="margin: 15px;">
    <table class="table table-bordered">
      <thead>
        <tr class="table-active">
          <th scope="col">ลำดับที่</th>
          <th scope="col">ชื่อ-นามสกุล</th>
          <th scope="col">กลุ่มผู้ใช้งาน</th>
           <th scope="col">สถานะ</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(l, i) in list" :key="i">
          <td>
            {{ i + 1 }}
          </td>
          <td>
            {{ l.firstname }} {{ l.lastname }}
          </td>
          <td>{{ l.role }}</td>
           <td v-if="l.status == 1">
           ใช้งาน
          </td>
           <td v-else>
            ไม่ใช้งาน
          </td>
          <td>
            <a @click="getid(l.id)">
              <button
                type="button"
                class="btn btn-warning btn-sm"
                data-bs-toggle="modal"
                data-bs-target="#AddUser"
              >
                <i class="fa fa-edit"></i></button
            ></a>&nbsp;
            <a @click="deletedata(l.id)">
              <button
                type="button"
                class="btn btn-danger btn-sm"
                data-bs-toggle="modal"
                data-bs-target="#DeleteStatus"
              >
                <i class="fa fa-trash"></i></button
            ></a>
          </td>
        </tr>
      </tbody>
    </table>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="AddUser"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
            <button
              id="closeduser"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form>
              <div class="card-body mt-3">
                <div class="form-group">
                  <label for="username">ชื่อ</label>
                  <input
                    v-model="user.firstname"
                    type="text"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                  />
                </div>
                <div class="form-group mt-3">
                  <label for="username">นามสกุล</label>
                  <input
                    v-model="user.lastname"
                    type="text"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                  />
                </div>
                <div class="form-group mt-3">
          <label for="exampleFormControlSelect1">กลุ่มผู้ใช้งาน</label>
          <select
            v-model="user.role_id"
            class="form-select form-control-sm"
            id="exampleFormControlSelect1"
          >
            <option v-for="(s, i) in roles" v-bind:value="s.id" :key="i + 1">
              {{ s.name }}
            </option>
          </select>
        </div>
         <div class="form-group mt-3">
          <label for="exampleFormControlSelect1">สถานะ</label>
          <select
            v-model="user.status"
            class="form-select form-control-sm"
            id="exampleFormControlSelect1"
          >
            <option value="1">
              ใช้งาน
            </option>
             <option value="0">
              ไม่ใช้งาน
            </option>
          </select>
        </div>
              </div>
            </form>
          </div>
          <div class="modal-footer mt-3">
            <button type="button" class="btn btn-success btn-sm" @click="save()">
              บันทึก
            </button>
            <button
              type="button"
              class="btn btn btn-secondary btn-sm"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="DeleteStatus"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">ยืนยันการลบข้อมูล</h5>
          </div>
          <div class="modal-footer mt-3">
            <button type="button" class="btn btn-danger btn-sm" @click="deletebyid()">
              <label>ลบข้อมูล</label>
            </button>
            <button
            id="closeddeleteuser"
              type="button"
              class="btn btn-secondary  btn-sm"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "../services/UserService";
import TeacherService from "../services//TeacherService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      concert_id: 0,
      list: [],
      user: {},
      user_id: 0,
      hash: 0,
      title: "",
      roles:[],
      delete_id:0
    };
  },
  mounted() {
    this.getRoles()
    this.getUsers();
  },
  methods: {
    deletebyid(){
      TeacherService.deleteteacher(this.delete_id).then(() => {
        alert('ลบข้อมูลเรียบร้อย')
        document.getElementById("closeddeleteuser").click();
        this.getRoles()
    this.getUsers();
      });
    },
    deletedata(id){
      this.delete_id = id;
    },
     getRoles() {
      UserService.getRolesAll().then((res) => {
        console.log(res.data);
        this.roles = res.data;
      });
    },
    getid(id) {
      this.user_id = id;
      if (this.user_id != 0) {
        this.title = "แก้ไขข้อมูลผู้รับผิดชอบรายวิชา";
        TeacherService.getteacher(this.user_id).then((res) => {
          this.user = res.data;
          this.hash = this.user.password;
        });
      } else {
        this.title = "เพิ่มข้อมูลผู้รับผิดชอบรายวิชา";
        this.user = [];
      }
    },
    save() {
      if (this.user.firstname == null) {
        alert("กรุณากรอกชื่อ");
      } else if (this.user.role_id == null) {
        alert("กรุณาเลือกกลุ่มผู้ใช้งาน");
      }  else if (this.user.status == null) {
        alert("กรุณาเลือกสถานะการใช้งาน");
      }  else {
       
        var userdata = {
          firstname: this.user.firstname,
          lastname: this.user.lastname,
          role_id: this.user.role_id,
          status: this.user.status,
          created_by:1,
          delete_status:1
        };
        console.log(userdata);
        if (this.user_id == 0) {
           TeacherService.getteacherss(this.user.firstname,this.user.lastname).then((res)=>{
          console.log(res.data);
          if (res.data.length == 0) {
          TeacherService.createteachers(userdata).then(() => {
            document.getElementById("closeduser").click();
            this.getUsers();
          });
          }
          else{
            alert('อีเมลนี้มีในระบบแล้ว')
          }
          });
        } else {
          TeacherService.updateteachers(this.user_id, userdata).then(() => {
            // console.log(res.data);
            document.getElementById("closeduser").click();
            this.getUsers();
            //       setTimeout(function () {
            //   location.reload();
            // }, 500);
            // window.scrollTo(0, 0);
          });
        }
      }
    },
    getUsers() {
      TeacherService.getteacherss('','').then((res) => {
        this.list = res.data;
        // console.log(res.data);
      });
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
</style>
