import http from "../http-common";

class UserService {
    getUsers(email) {
        return http.get('/user?name='+email);
    }
    getUser(id) {
        return http.get('/user/' + id);
    }
    createUser(data) {
        return http.post('/user', data);
    }
    updateUser(id, data) {
        return http.put(`/user/${id}`, data);
    }
    updateToken(id, data) {
        return http.put(`/user/token/${id}`, data);
    }
    getUserBytoken(id) {
        return http.get('/user/getbytoken/' + id);
    }
    getMenuByRole(id) {
        return http.get('/user/getmenubyrole?role_id=' + id);
    }
    getMenusAll() {
        return http.get('/menus');
      }
      getRolesAll() {
        return http.get('/roles');
      }
      getMenubyRoleIDAll(id) {
        return http.get('/user/getmenuarray/' + id);
    }
    createRoleMenu(data) {
        return http.post('/role_menu', data);
      }
      createRole(data) {
        return http.post(`/roles`, data);
      }
      updateRole(id,data) {
        return http.put(`/roles/${id}`, data);
      }
      deleteRoleMenu(id) {
        return http.delete(`/role_menu/` + id);
      }
      deleteUser(id) {
        return http.delete(`/user/` + id);
      }
      deleteRole(id) {
        return http.delete(`/roles/` + id);
      }
}

export default new UserService();