<template>
  <div class="container-fluid row" style="width: 90%; margin: auto">
    <div class="card mt-5 col-md-12" style="margin: 10px">
      <div class="mb-3 mt-3">
        <div class="row">
          <div class="col-md-12 mb-3">
            <label for="exampleInputEmail1" class="form-label">ชุดทดสอบ {{ name }} (ข้อสอบอัตนัย)</label>
          </div>
        </div>
        <div class="row"> 
          <div class="col-md-12">
            <label for="exampleInputEmail1" class="form-label">คำถาม</label>
            &nbsp;<button
              v-if="showquestion"
              type="button"
              class="btn btn-warning btn-sm"
              @click="gettextform(question.question, 'question')"
            >
              <i class="fa fa-edit"></i>
            </button>
          </div>
          <div class="col-md-12 mb-3">
            <div v-if="showquestion">
              <label v-html="question.question"></label><br />
            </div>
            <div v-else>
              <vue-editor
                use-custom-image-handler
                @image-added="handleImageAdded"
                v-model="question.question"
              /><br /><button
                type="button"
                class="btn btn-success btn-sm"
                @click="saveform(question.question, 'question')"
              >
                <i class="fa fa-save"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label for="exampleInputEmail1" class="form-label"
              >จำนวนคำตอบถูกต้องที่จะได้คะแนนเต็ม</label
            >
          </div>
          <div class="col-md-5 mb-3">
            <input
              v-model="question.no"
              class="form-control"
              type="number"
              id="flexRadioDefault1"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-3">
            <label for="exampleInputEmail1" class="form-label">คำตอบ</label>
          </div>
          <div class="col-md-3 mb-3">
            <div v-if="showCorrectAns1">
              <label v-html="question.CorrectAns1"></label><br />
              <button
                type="button"
                class="btn btn-warning btn-sm"
                @click="gettextform(question.CorrectAns1, 'CorrectAns1')"
              >
                <i class="fa fa-edit"></i>
              </button>
            </div>
            <div v-else>
              <vue-editor
                use-custom-image-handler
                @image-added="handleImageAdded"
                v-model="question.CorrectAns1"
              /><br /><button
                type="button"
                class="btn btn-success btn-sm"
                @click="saveform(question.CorrectAns1, 'CorrectAns1')"
              >
                <i class="fa fa-save"></i>
              </button>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div v-if="showCorrectAns2">
              <label v-html="question.CorrectAns2"></label><br />
              <button
                type="button"
                class="btn btn-warning btn-sm"
                @click="gettextform(question.CorrectAns2, 'CorrectAns2')"
              >
                <i class="fa fa-edit"></i>
              </button>
            </div>
            <div v-else>
              <vue-editor
                use-custom-image-handler
                @image-added="handleImageAdded"
                v-model="question.CorrectAns2"
              /><br /><button
                type="button"
                class="btn btn-success btn-sm"
                @click="saveform(question.CorrectAns2, 'CorrectAns2')"
              >
                <i class="fa fa-save"></i>
              </button>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div v-if="showCorrectAns3">
              <label v-html="question.CorrectAns3"></label><br />
              <button
                type="button"
                class="btn btn-warning btn-sm"
                @click="gettextform(question.CorrectAns3, 'CorrectAns3')"
              >
                <i class="fa fa-edit"></i>
              </button>
            </div>
            <div v-else>
              <vue-editor
                use-custom-image-handler
                @image-added="handleImageAdded"
                v-model="question.CorrectAns3"
              /><br /><button
                type="button"
                class="btn btn-success btn-sm"
                @click="saveform(question.CorrectAns3, 'CorrectAns3')"
              >
                <i class="fa fa-save"></i>
              </button>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div v-if="showCorrectAns4">
              <label v-html="question.CorrectAns4"></label><br />
              <button
                type="button"
                class="btn btn-warning btn-sm"
                @click="gettextform(question.CorrectAns4, 'CorrectAns4')"
              >
                <i class="fa fa-edit"></i>
              </button>
            </div>
            <div v-else>
              <vue-editor
                use-custom-image-handler
                @image-added="handleImageAdded"
                v-model="question.CorrectAns4"
              /><br /><button
                type="button"
                class="btn btn-success btn-sm"
                @click="saveform(question.CorrectAns4, 'CorrectAns4')"
              >
                <i class="fa fa-save"></i>
              </button>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div v-if="showCorrectAns5">
              <label v-html="question.CorrectAns5"></label><br />
              <button
                type="button"
                class="btn btn-warning btn-sm"
                @click="gettextform(question.CorrectAns5, 'CorrectAns5')"
              >
                <i class="fa fa-edit"></i>
              </button>
            </div>
            <div v-else>
              <vue-editor
                use-custom-image-handler
                @image-added="handleImageAdded"
                v-model="question.CorrectAns5"
              /><br /><button
                type="button"
                class="btn btn-success btn-sm"
                @click="saveform(question.CorrectAns5, 'CorrectAns5')"
              >
                <i class="fa fa-save"></i>
              </button>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div v-if="showCorrectAns6">
              <label v-html="question.CorrectAns6"></label><br />
              <button
                type="button"
                class="btn btn-warning btn-sm"
                @click="gettextform(question.CorrectAns6, 'CorrectAns6')"
              >
                <i class="fa fa-edit"></i>
              </button>
            </div>
            <div v-else>
              <vue-editor
                use-custom-image-handler
                @image-added="handleImageAdded"
                v-model="question.CorrectAns6"
              /><br /><button
                type="button"
                class="btn btn-success btn-sm"
                @click="saveform(question.CorrectAns6, 'CorrectAns6')"
              >
                <i class="fa fa-save"></i>
              </button>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div v-if="showCorrectAns7">
              <label v-html="question.CorrectAns7"></label><br />
              <button
                type="button"
                class="btn btn-warning btn-sm"
                @click="gettextform(question.CorrectAns7, 'CorrectAns7')"
              >
                <i class="fa fa-edit"></i>
              </button>
            </div>
            <div v-else>
              <vue-editor
                use-custom-image-handler
                @image-added="handleImageAdded"
                v-model="question.CorrectAns7"
              /><br /><button
                type="button"
                class="btn btn-success btn-sm"
                @click="saveform(question.CorrectAns7, 'CorrectAns7')"
              >
                <i class="fa fa-save"></i>
              </button>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div v-if="showCorrectAns8">
              <label v-html="question.CorrectAns8"></label><br />
              <button
                type="button"
                class="btn btn-warning btn-sm"
                @click="gettextform(question.CorrectAns8, 'CorrectAns8')"
              >
                <i class="fa fa-edit"></i>
              </button>
            </div>
            <div v-else>
              <vue-editor
                use-custom-image-handler
                @image-added="handleImageAdded"
                v-model="question.CorrectAns8"
              /><br /><button
                type="button"
                class="btn btn-success btn-sm"
                @click="saveform(question.CorrectAns8, 'CorrectAns8')"
              >
                <i class="fa fa-save"></i>
              </button>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div v-if="showCorrectAns9">
              <label v-html="question.CorrectAns9"></label><br />
              <button
                type="button"
                class="btn btn-warning btn-sm"
                @click="gettextform(question.CorrectAns9, 'CorrectAns9')"
              >
                <i class="fa fa-edit"></i>
              </button>
            </div>
            <div v-else>
              <vue-editor
                use-custom-image-handler
                @image-added="handleImageAdded"
                v-model="question.CorrectAns9"
              /><br /><button
                type="button"
                class="btn btn-success btn-sm"
                @click="saveform(question.CorrectAns9, 'CorrectAns9')"
              >
                <i class="fa fa-save"></i>
              </button>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div v-if="showCorrectAns10">
              <label v-html="question.CorrectAns10"></label><br />
              <button
                type="button"
                class="btn btn-warning btn-sm"
                @click="gettextform(question.CorrectAns10, 'CorrectAns10')"
              >
                <i class="fa fa-edit"></i>
              </button>
            </div>
            <div v-else>
              <vue-editor
                use-custom-image-handler
                @image-added="handleImageAdded"
                v-model="question.CorrectAns10"
              /><br /><button
                type="button"
                class="btn btn-success btn-sm"
                @click="saveform(question.CorrectAns10, 'CorrectAns10')"
              >
                <i class="fa fa-save"></i>
              </button>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div v-if="showCorrectAns11">
              <label v-html="question.CorrectAns11"></label><br />
              <button
                type="button"
                class="btn btn-warning btn-sm"
                @click="gettextform(question.CorrectAns11, 'CorrectAns11')"
              >
                <i class="fa fa-edit"></i>
              </button>
            </div>
            <div v-else>
              <vue-editor
                use-custom-image-handler
                @image-added="handleImageAdded"
                v-model="question.CorrectAns11"
              /><br /><button
                type="button"
                class="btn btn-success btn-sm"
                @click="saveform(question.CorrectAns11, 'CorrectAns11')"
              >
                <i class="fa fa-save"></i>
              </button>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div v-if="showCorrectAns12">
              <label v-html="question.CorrectAns12"></label><br />
              <button
                type="button"
                class="btn btn-warning btn-sm"
                @click="gettextform(question.CorrectAns12, 'CorrectAns12')"
              >
                <i class="fa fa-edit"></i>
              </button>
            </div>
            <div v-else>
              <vue-editor
                use-custom-image-handler
                @image-added="handleImageAdded"
                v-model="question.CorrectAns12"
              /><br /><button
                type="button"
                class="btn btn-success btn-sm"
                @click="saveform(question.CorrectAns12, 'CorrectAns12')"
              >
                <i class="fa fa-save"></i>
              </button>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div v-if="showCorrectAns13">
              <label v-html="question.CorrectAns13"></label><br />
              <button
                type="button"
                class="btn btn-warning btn-sm"
                @click="gettextform(question.CorrectAns13, 'CorrectAns13')"
              >
                <i class="fa fa-edit"></i>
              </button>
            </div>
            <div v-else>
              <vue-editor
                use-custom-image-handler
                @image-added="handleImageAdded"
                v-model="question.CorrectAns13"
              /><br /><button
                type="button"
                class="btn btn-success btn-sm"
                @click="saveform(question.CorrectAns13, 'CorrectAns13')"
              >
                <i class="fa fa-save"></i>
              </button>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div v-if="showCorrectAns14">
              <label v-html="question.CorrectAns14"></label><br />
              <button
                type="button"
                class="btn btn-warning btn-sm"
                @click="gettextform(question.CorrectAns14, 'CorrectAns14')"
              >
                <i class="fa fa-edit"></i>
              </button>
            </div>
            <div v-else>
              <vue-editor
                use-custom-image-handler
                @image-added="handleImageAdded"
                v-model="question.CorrectAns14"
              /><br /><button
                type="button"
                class="btn btn-success btn-sm"
                @click="saveform(question.CorrectAns14, 'CorrectAns14')"
              >
                <i class="fa fa-save"></i>
              </button>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div v-if="showCorrectAns15">
              <label v-html="question.CorrectAns15"></label><br />
              <button
                type="button"
                class="btn btn-warning btn-sm"
                @click="gettextform(question.CorrectAns15, 'CorrectAns15')"
              >
                <i class="fa fa-edit"></i>
              </button>
            </div>
            <div v-else>
              <vue-editor
                use-custom-image-handler
                @image-added="handleImageAdded"
                v-model="question.CorrectAns15"
              /><br /><button
                type="button"
                class="btn btn-success btn-sm"
                @click="saveform(question.CorrectAns15, 'CorrectAns15')"
              >
                <i class="fa fa-save"></i>
              </button>
            </div>
          </div>
          <div class="col-md-3 mb-3"></div>
        </div>
        <!-- <div class="row">
          <div class="col-md-12 mt-3">
            <label for="exampleInputEmail1" class="form-label">สถานะ</label>
          </div>
          <div class="col-md-3 mb-3">
            <select
              v-model="question.status"
              class="form-select form-select-sm"
              aria-label=".form-select-sm example"
            >
              <option value="1">ใช้งานอยู่</option>
              <option value="0">ไม่ใช้งาน</option>
            </select>
          </div>
        </div> -->
        <div class="row">
          <div class="col-md-12 mt-3">
            <label for="exampleInputEmail1" class="form-label"
              >ระดับความยาก</label
            >
          </div>
          <div class="col-md-3 mb-3">
            <select
              v-model="question.level"
              class="form-select form-select-sm"
              aria-label=".form-select-sm example"
            >
              <option :value="l.id" v-for="(l, i) in levels" :key="'L1' + i">
                {{ l.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="social-auth-links text-center mb-3 mt-3">
        <button @click="save()" type="button" class="btn btn-success btn-sm">
          บันทึก
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SubjectTestService from "../services/SubjectTestService";
import LevelService from "../services/LevelService";
import RandomService from "../services/RandomService";
import QuestionSubject from "../services/QuestionSubject";
import axios from "axios";
import LinkService from "../services/LinkService";
import QuestionSetService from "../services/QuestionSetService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  component: {},
  data() {
    return {
      question_id: 0,
      question: {},
      answer: [],
      levels: [],
      format: "",
      showquestion: true,
      showCorrectAns1: true,
      showCorrectAns2: true,
      showCorrectAns3: true,
      showCorrectAns4: true,
      showCorrectAns5: true,
      showCorrectAns6: true,
      showCorrectAns7: true,
      showCorrectAns8: true,
      showCorrectAns9: true,
      showCorrectAns10: true,
      showCorrectAns11: true,
      showCorrectAns12: true,
      showCorrectAns13: true,
      showCorrectAns14: true,
      showCorrectAns15: true,
      type:'',
      name:'',
      page:''
    };
  },
  mounted() {
    this.question_id = this.$route.params.id;
    this.format = this.$route.params.setid;
    this.type = this.$route.params.type
    this.page = this.$route.params.page
    QuestionSetService.getQuestionSet(this.format).then(
        (res) => {
          // console.log(res.data);
          this.name = res.data.question_name
        }
      );
    // console.log(this.question_id);
    if (this.question_id == 0) {
      this.getquestions();
      // this.answer.push({
      //   answer: "",
      //   id: 0,
      // });
      this.question.level = 1;
      this.question.status = 1;
    } else {
      this.getquestion();
    }
    this.getlevel();
  },
  methods: {
    saveform(data, type) {
      if (type == "question") {
        this.showquestion = true;
      } else if (type == "CorrectAns1") {
        this.showCorrectAns1 = true;
      } else if (type == "CorrectAns2") {
        this.showCorrectAns2 = true;
      } else if (type == "CorrectAns3") {
        this.showCorrectAns3 = true;
      } else if (type == "CorrectAns4") {
        this.showCorrectAns4 = true;
      } else if (type == "CorrectAns5") {
        this.showCorrectAns5 = true;
      } else if (type == "CorrectAns6") {
        this.showCorrectAns6 = true;
      } else if (type == "CorrectAns7") {
        this.showCorrectAns7 = true;
      } else if (type == "CorrectAns8") {
        this.showCorrectAns8 = true;
      } else if (type == "CorrectAns9") {
        this.showCorrectAns9 = true;
      } else if (type == "CorrectAns10") {
        this.showCorrectAns10 = true;
      } else if (type == "CorrectAns11") {
        this.showCorrectAns11 = true;
      } else if (type == "CorrectAns12") {
        this.showCorrectAns12 = true;
      } else if (type == "CorrectAns13") {
        this.showCorrectAns13 = true;
      } else if (type == "CorrectAns14") {
        this.showCorrectAns14 = true;
      } else if (type == "CorrectAns15") {
        this.showCorrectAns15 = true;
      }
    },
    gettextform(data, type) {
      if (type == "question") {
        this.showquestion = false;
      } else if (type == "CorrectAns1") {
        this.showCorrectAns1 = false;
      } else if (type == "CorrectAns2") {
        this.showCorrectAns2 = false;
      } else if (type == "CorrectAns3") {
        this.showCorrectAns3 = false;
      } else if (type == "CorrectAns4") {
        this.showCorrectAns4 = false;
      } else if (type == "CorrectAns5") {
        this.showCorrectAns5 = false;
      } else if (type == "CorrectAns6") {
        this.showCorrectAns6 = false;
      } else if (type == "CorrectAns7") {
        this.showCorrectAns7 = false;
      } else if (type == "CorrectAns8") {
        this.showCorrectAns8 = false;
      } else if (type == "CorrectAns9") {
        this.showCorrectAns9 = false;
      } else if (type == "CorrectAns10") {
        this.showCorrectAns10 = false;
      } else if (type == "CorrectAns11") {
        this.showCorrectAns11 = false;
      } else if (type == "CorrectAns12") {
        this.showCorrectAns12 = false;
      } else if (type == "CorrectAns13") {
        this.showCorrectAns13 = false;
      } else if (type == "CorrectAns14") {
        this.showCorrectAns14 = false;
      } else if (type == "CorrectAns15") {
        this.showCorrectAns15 = false;
      }
    },
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData();
      formData.append("file", file);
      var http = LinkService.getLink()+"/uploadimage?name=" + file.name;
      axios
        .post(http, formData)
        .then(() => {
      Editor.insertEmbed(
        cursorLocation,
        "image",
        LinkService.getLink()+"/uploads/" + file.name
      );
      resetUploader();
      var im = this.question.question.split('img')
      this.question.question = im[0]+'img style="width:30%;"'+im[1]
          // console.log(res.data);
          // this.question.image = file.name;
          // var q = this.question.question;
          // this.question.question =
          //   q +
          //   '<img style="width:30%" src="' +
          //   "https://api-test.ponnipa.in.th/uploads/" +
          //   file.name +
          //   '"/>';
          // console.log(this.question);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteanswer(index) {
      for (let l = 0; l < this.answer.length; l++) {
        if (l == index) {
          this.answer.splice(index, 1);
        }
      }
    },
    addinputanswer() {
      this.answer.push({
        answer: "",
        id: 0,
      });
    },
    getlevel() {
      LevelService.getlevel(this.question_id).then((res) => {
        this.levels = res.data;
        // console.log(this.levels);
      });
    },
    getquestion() {
      QuestionSubject.getQuestionSubject(this.question_id, this.format).then(
        (res) => {
          this.question = res.data;
          console.log(this.question);
        }
      );
    },
    getquestions() {
      QuestionSubject.getQuestionSubjects(this.format).then((res) => {
        this.question.count = res.data.length + 1;
        console.log(this.question);
      });
    },
    getanswers() {
      SubjectTestService.getanswersubjecttest(this.question_id).then((res) => {
        this.answer = res.data;
        // var ans = 15 - this.answer.length;
        // for (let a = 0; a < ans; a++) {
        //   this.answer.push({
        //     answer: "",
        //     id: 0,
        //     subjecttest_id: this.question_id,
        //   });
        // }
        // console.log(this.answer);
      });
    },
    save() {
      if (this.question.question == null) {
        alert("กรุณากรอกคำถาม");
      }
      else if (this.question.no == null){
        alert("กรุณากรอกจำนวนคำตอบถูกต้องที่จะได้คะแนนเต็ม");
      }
      else if (this.question.CorrectAns1 == null){
        alert("กรุณากรอกคำตอบ");
      }
      else {
        // console.log(this.question);
        var test = {
          question_set_id: this.format,
          no: this.question.no,
          question: this.question.question,
          CorrectAns1: this.question.CorrectAns1,
          CorrectAns2: this.question.CorrectAns2,
          CorrectAns3: this.question.CorrectAns3,
          CorrectAns4: this.question.CorrectAns4,
          CorrectAns5: this.question.CorrectAns5,
          CorrectAns6: this.question.CorrectAns6,
          CorrectAns7: this.question.CorrectAns7,
          CorrectAns8: this.question.CorrectAns8,
          CorrectAns9: this.question.CorrectAns9,
          CorrectAns10: this.question.CorrectAns10,
          CorrectAns11: this.question.CorrectAns11,
          CorrectAns12: this.question.CorrectAns12,
          CorrectAns13: this.question.CorrectAns13,
          CorrectAns14: this.question.CorrectAns14,
          CorrectAns15: this.question.CorrectAns15,
          index_id:this.question.count,
          type:this.type
        };
        // console.log(question);
        if (this.question_id == 0) {
          // console.log(test);
          QuestionSubject.createQuestionSubject(test).then((res) => {
            // console.log(res.data.id);
            this.question_id = res.data.id;
            var random = {
              question_set_id: this.format,
              question_id: this.question_id,
              level: this.question.level,
              status: this.question.status,
              weight: 0,
              type:this.type
            };
            RandomService.createrandom(random).then(() => {});
            alert("บันทึกสำเร็จ");
            // this.$router.go(-1)
            // // location.reload();
            // this.$router.push("/import?id=" + this.format);
            if (this.page == 'import') {
                    this.$router.push("/import?id=" + this.format);
                  }else{
                    this.$router.push("/edittest?id=" + this.format);
                  }
          });
        } else {
          QuestionSubject.updateQuestionSubject(this.question_id, test).then(
            (res) => {
              console.log(res.data);
              var random = {
                level: this.question.level,
                status: this.question.status,
              };
              RandomService.updateRandom(this.question.random_id, random).then(
                (res) => {
                  console.log(res.data);
                  alert("บันทึกสำเร็จ");
                  // this.$router.go(-1)
                  if (this.page == 'import') {
                    this.$router.push("/import?id=" + this.format);
                  }else{
                    this.$router.push("/edittest?id=" + this.format);
                  }
                  // location.reload();
                  // this.$router.push("/import?id=" + this.format);
                }
              );
            }
          );
        }
      }
    },
  },

  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.vertical-left {
  margin-left: 20px;
}
.title {
  color: blue;
}
</style>
