import http from "../http-common";

class PrototypeTest {
  createPrototypeTest(data) {
    return http.post(`/prototype_test`, data);
  }
  getPrototypeTests() {
    return http.get(`/prototype_test`);
  }
  updatePrototypeTest(id, data) {
    return http.put(`/prototype_test/${id}`, data);
  }
}

export default new PrototypeTest();