<template>
  <div class="container-fluid row">   
    <div class="col-md-12" style="margin: 15px;margin-top: 80px">
      <table class="table table-bordered">
        <thead>
          <tr style="text-align: center">
            <th scope="col" style="background-color: #92d050">ครั้งที่</th>
            <th scope="col" style="background-color: #92d050">จำนวนข้อสอบทั้งหมด</th>
            <th scope="col" style="background-color: #92d050">คะแนน</th>
            <th scope="col" style="background-color: #92d050"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="l in list" :key="l.id">
            <td scope="row"><span v-html="l.test_no"></span></td>
            <td scope="row"><span v-html="l.count"></span> ข้อ</td>
            <td scope="row">{{ l.score }}</td>
            <td>
              <a :href="'/testtrainingresult/' + l.test_no">
          <button type="button" class="btn btn-warning btn-sm">
            <i class="fa fa-eye"></i>
          </button>
        </a></td>
          </tr>
        </tbody>
      </table> 
    </div>

  </div>
</template>

<script>
import AnswerTrainingService from '../services/AnswerTrainingService';

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      list: [],
      score:0
    };
  },
  mounted() {
    this.getdata();
  },
  methods: {
    getdata() {
      this.list = [];
      AnswerTrainingService.getTestAnswerStudentsAll(this.currentUser.user_id).then((res)=>{
        // console.log(res.data);
        this.list = res.data
      })
      AnswerTrainingService.getScoreStudent(this.currentUser.user_id).then((res)=>{
        // console.log(res.data);
        this.score = res.data.score
      })
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
</style>
