import http from "../http-common";

class ManageTraining {
  createManageTest(data) {
    return http.post(`/manage_test`, data);
  }
  getManageTest() {
    return http.get(`/manage_test`);
  }
  updateManageTest(id, data) {
    return http.put(`/manage_test/${id}`, data);
  }
}

export default new ManageTraining();