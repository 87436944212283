import http from "../http-common";

class ManageTime {
  createManageTime(data) {
    return http.post(`/manage_times`, data);
  }
  getManageTime() {
    return http.get(`/manage_times`);
  }
  updateManageTime(id, data) {
    return http.put(`/manage_times/${id}`, data);
  }
}

export default new ManageTime();