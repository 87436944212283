<template>
  <div class="container-fluid row mt-5" style="padding: 30px">
 <div class="col-md-12">
      <label for="exampleInputEmail1" class="form-label">กำหนดวันที่เปิดให้ดูเฉลย</label>
    </div>
    <div class="input-group mb-3">
      <div class="col-sm-10">
         <date-picker
              v-model="times.start_date"
              format="DD/MM/YYYY"
              valueType="format"
            ></date-picker>
      </div>
    </div>
    <div class="col-md-12">
      <label for="exampleInputEmail1" class="form-label">กำหนดวันที่สิ้นสุดให้ดูเฉลย</label>
    </div>
    <div class="input-group mb-3">
      <div class="col-sm-10">
         <date-picker
              v-model="times.end_date"
              format="DD/MM/YYYY"
              valueType="format"
            ></date-picker>
      </div>
    </div>
    <!-- <div class="col-md-12">
      <label for="exampleInputEmail1" class="form-label">เวลาเริ่มต้นดูเฉลย</label>
    </div>
    <div class="input-group mb-3">
      <div class="col-sm-10">
        <input v-model="times.time_start" class="form-control" type="time" id="appt" name="appt" />
      </div>
    </div>
    <div class="col-md-12">
      <label for="exampleInputEmail1" class="form-label">เวลาสิ้นสุดดูเฉลย</label>
    </div>
    <div class="input-group mb-3">
      <div class="col-sm-10">
        <input v-model="times.time_end" class="form-control" type="time" id="appt" name="appt" />
      </div>
    </div> -->
    <div class="col-md-12">
        <label for="exampleInputEmail1" class="form-label"
          >สถานะการแสดงคำตอบ</label
        >
      </div>
      <div class="col-md-3 mb-3">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value="true"
            id="flexCheckChecked"
            v-model="times.view_answer_status"
          />
          <label class="form-check-label" for="flexCheckChecked">
            แสดงคำตอบ
          </label>
        </div>
      </div>
    <div class="text-left mb-3 mt-3">
      <button @click="save()" type="button" class="btn btn-success btn-sm">
        บันทึก
      </button>
    </div>
  </div>
</template>

<script>
import AnswerTimesService from "../services/AnswerTimesService";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      times: {},
    };
  },
  mounted() {
    this.getmanagetest()
  },
  methods: {
    getmanagetest() {
      AnswerTimesService.getAnswerTime().then((res) => {
        console.log(res.data);
        this.times = res.data[0]
        this.times.start_date = this.convertdate(this.times.start_date)
        this.times.end_date = this.convertdate(this.times.end_date)
      })
    },
    convertdate(date){
var dates = new Date(date)
var dd = dates.getDate()
var mm = dates.getMonth()+1
var yy = dates.getFullYear()
if (String(dd).length == 1) {
  dd = '0'+dd
}
if (String(mm).length == 1) {
  mm = '0'+mm
}
return dd+'/'+mm+'/'+yy
    }, 
    convertdatesave(date){
var dd = date.split('/')[1]
var mm = date.split('/')[0]
var yy = date.split('/')[2]
return yy+'/'+dd+'/'+mm
    },
    save() {
      var data = {
        start_date:this.convertdatesave(this.times.start_date),
        time_start: this.times.time_start,
        time_end: this.times.time_end,
        view_answer_status:this.times.view_answer_status,
        end_date:this.convertdatesave(this.times.end_date),
      };
      AnswerTimesService.updateAnswerTime(this.times.id, data).then(() => {
        alert('บันทึกสำเร็จ')
      });
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}

.card {
  margin: 0 auto;
  /* Added */
  float: none;
  /* Added */
  margin-bottom: 10px;
  /* Added */
}

body {
  background-color: gray;
}

.form-control-input {
  display: block;
  width: 10%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
}
</style>
