import Vue from "vue";
import Router from "vue-router";
import Import from './components/import.vue';
import Q1 from './components/Q1.vue';
import Q2 from './components/Q2.vue';
import Q3 from './components/Q3.vue';
import Q4 from './components/Q4.vue';
import Q5 from './components/Q5.vue';
import Q6 from './components/Q6.vue';
import Q1Edit from './components/Q1Edit.vue';
import Q2Edit from './components/Q2Edit.vue';
import Q3Edit from './components/Q3Edit.vue';
import Q4Edit from './components/Q4Edit.vue';
import Q5Edit from './components/Q5Edit.vue';
// import Q6Edit from './components/Q6Edit.vue';
import Test from './components/Test.vue';
import Result from './components/Result.vue';
import Register from './components/Register.vue';
import ActivateEmail from './components/ActivateEmail.vue';
import Login from './components/Login.vue';
import TrainingTest from './components/TrainingTest.vue';
import SettingTrain from './components/SettingTrain.vue';
import TestAll from './components/TestAll.vue';
import TrainingResult from './components/TrainingResult.vue';
import SettingTest from './components/SettingTest.vue';
import SettingWeight from './components/SettingWeight.vue';
import SettingLevel from './components/SettingLevel.vue';
import managemenus from './components/managemenus.vue';
import users from './components/users.vue';
import SettingTestSet from './components/SettingTestSet.vue';
import SettingPrototype from './components/SettingPrototype.vue';
import Dashboard from './components/Dashboard.vue';
import edittest from './components/edittest.vue';
import DetailLevelGraph from './components/DetailLevelGraph.vue';
import DetailTypeGraph from './components/DetailTypeGraph.vue';
import TestTrainingResult from './components/TestTrainingResult.vue';
import TestResult from './components/TestResult.vue';
import SettingAnswerTime from './components/SettingAnswerTime.vue';
import Export from './components/export.vue';
import Menus from './components/Menus.vue';
import teachers from './components/teachers.vue';
import SettingPeople from './components/SettingPeople.vue';
import addtest from './components/addtest.vue';
import TypeThreeGraph from './components/TypeThreeGraph.vue';
import ResultAll from './components/ResultAll.vue';
import TestResultAll from './components/TestResultAll.vue';

Vue.use(Router);
const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/import",
      alias: "/import",
      name: "import",
      component: Import
    },
    {
      path: "/addtest",
      alias: "/addtest",
      name: "addtest",
      component: addtest
    },
    {
      path: "/export",
      alias: "/export",
      name: "export",
      component: Export
    },
    {
      path: "/edittest",
      alias: "/edittest",
      name: "edittest",
      component: edittest
    },
    {
      path: "/Q1",
      alias: "/Q1",
      name: "Q1",
      component: Q1
    },
    {
      path: "/Q2",
      alias: "/Q2",
      name: "Q2",
      component: Q2
    },
    {
      path: "/Q3",
      alias: "/Q3",
      name: "Q3",
      component: Q3
    },
    {
      path: "/Q4",
      alias: "/Q4",
      name: "Q4",
      component: Q4
    },
    {
      path: "/Q5",
      alias: "/Q5",
      name: "Q5",
      component: Q5
    },
    {
      path: "/Q6",
      alias: "/Q6",
      name: "Q6",
      component: Q6
    },
    {
      path: "/Q1/:id/:setid/:type/:page",
      alias: "/Q1",
      name: "Q1",
      component: Q1Edit
    },
    {
      path: "/Q2/:id/:setid/:type/:page",
      alias: "/Q2",
      name: "Q2",
      component: Q2Edit
    },
    {
      path: "/Q3/:id/:setid/:type/:page",
      alias: "/Q3",
      name: "Q3",
      component: Q3Edit
    },
    {
      path: "/Q4/:id/:setid",
      alias: "/Q4",
      name: "Q4",
      component: Q4Edit
    },
    {
      path: "/Q5/:id/:setid",
      alias: "/Q5",
      name: "Q5",
      component: Q5Edit
    },
    // {
    //   path: "/Q6/:id/:setid",
    //   alias: "/Q6",
    //   name: "Q6",
    //   component: Q6Edit
    // },
    {
      path: "/test",
      alias: "/test",
      name: "test",
      component: Test
    },
    {
      path: "/trainingtest",
      alias: "/trainingtest",
      name: "trainingtest",
      component: TrainingTest
    },{
      path: "/result",
      alias: "/result",
      name: "result",
      component: Result
    },
    {
      path: "/resultall",
      alias: "/resultall",
      name: "resultall",
      component: ResultAll
    },
    {
      path: "/register",
      alias: "/register",
      name: "register",
      component: Register
    },
    {
      path: "/activateemail/:id",
      alias: "/activateemail",
      name: "activateemail",
      component: ActivateEmail
    },
    {
      path: "/",
      alias: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/settingtrain",
      alias: "/settingtrain",
      name: "settingtrain",
      component: SettingTrain
    },
    {
      path: "/testall",
      alias: "/testall",
      name: "testall",
      component: TestAll
    },
    {
      path: "/trainingresult",
      alias: "/trainingresult",
      name: "trainingresult",
      component: TrainingResult
    },
    {
      path: "/settingtest",
      alias: "/settingtest",
      name: "settingtest",
      component: SettingTest
    },
    {
      path: "/settingweight",
      alias: "/settingweight",
      name: "settingweight",
      component: SettingWeight
    },
    {
      path: "/settinglevel",
      alias: "/settinglevel",
      name: "settinglevel",
      component: SettingLevel
    },
    {
      path: "/settinganswertime",
      alias: "/settinganswertime",
      name: "settinganswertime",
      component: SettingAnswerTime
    },
    
    {
      path: "/menus",
      alias: "/menus",
      name: "menus",
      component: managemenus
    },
    {
      path: "/users",
      alias: "/users",
      name: "users",
      component: users
    },
    {
      path: "/settingtestset",
      alias: "/settingtestset",
      name: "settingtestset",
      component: SettingTestSet
    },
    {
      path: "/settingprototype",
      alias: "/settingprototype",
      name: "settingprototype",
      component: SettingPrototype
    },
    {
      path: "/dashboard",
      alias: "/dashboard",
      name: "dashboard",
      component: Dashboard
    },
    {
      path: "/detaillevel/:id",
      alias: "/detaillevel",
      name: "detaillevel",
      component: DetailLevelGraph
    },
    {
      path: "/detailtype/:id",
      alias: "/detailtype",
      name: "detailtype",
      component: DetailTypeGraph
    },    
    {
      path: "/testtrainingresult/:id",
      alias: "/testtrainingresult",
      name: "testtrainingresult",
      component: TestTrainingResult
    },
    {
      path: "/testresult/:id",
      alias: "/testresult",
      name: "testresult",
      component: TestResult
    },
    {
      path: "/testresultall/:stuid/:id",
      alias: "/testresultall",
      name: "testresultall",
      component: TestResultAll
    },
    {
      path: "/menu",
      alias: "/menu",
      name: "menu",
      component: Menus
    },{
      path: "/teachers",
      alias: "/teachers",
      name: "teachers",
      component: teachers
    },
    {
      path: "/settingpeople",
      alias: "/settingpeople",
      name: "settingpeople",
      component: SettingPeople
    },
    {
      path: "/TypeThreeGraph",
      alias: "/TypeThreeGraph",
      name: "TypeThreeGraph",
      component: TypeThreeGraph
    },
  ]
});


router.beforeEach((to, from, next) => {
  var page = to.path.split("/")
  // console.log(page[1]);
  var activateemail = ''
  if (page[1] == 'activateemail') {
    activateemail = to.path
    // console.log(activateemail);
  }
  const publicPages = ['/register','/login',activateemail];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('userpharmacy');
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) { 
      next('/login');
    // next();
  } else {
    next();
  }

});

export default router;
