import http from "../http-common";

class ManageTraining {
  createManageTraining(data) {
    return http.post(`/manage_training`, data);
  }
  getManageTraining() {
    return http.get(`/manage_training`);
  }
  updateManageTraining(id, data) {
    return http.put(`/manage_training/${id}`, data);
  }
  updateManageNoTraining(id, data) {
    return http.put(`/manage_training/updatenotest/${id}`, data);
  }
}

export default new ManageTraining();