<template>
  <div class="container-fluid row mt-5" style="padding: 30px">
    <div class="col-md-12">
<LevelGraph/>
    </div>
     <div class="col-md-6">
<!-- <TypeOneGraph/> -->
    </div>
     <div class="col-md-6">
<!-- <TypeTwoGraph/> -->
    </div>
     <div class="col-md-6">
<!-- <TypeThreeGraph/> -->
    </div>
  </div>
</template>

<script>
import LevelGraph from './LevelGraph.vue'
// import TypeOneGraph from './TypeOneGraph.vue'
// import TypeTwoGraph from './TypeTwoGraph.vue'
// import TypeThreeGraph from './TypeThreeGraph.vue'

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  components:{
    LevelGraph,
    // TypeOneGraph,
    // TypeTwoGraph,
    // TypeThreeGraph
  },
  data() {
    return {
     
    };
  },
  mounted() {
  
  },
  methods: {
    
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
