<template>
  <div class="container row mb-3" style="margin: auto">
    <div class="row mt-5">
      <div style="width: 5%"></div>
      <div class="col-md-10" v-if="!loading">
        <h4>รหัสนิสิต {{ code.email }}</h4>
        <h4>ชื่อ-นามสกุล {{ code.firstname }} {{ code.lastname }}</h4>
        <h4 style="text-align: right">ผลการสอบครั้งที่ {{ test_no }}</h4>
        <h4 style="text-align: right">
          ผลคะแนน {{ score }} / {{ questions.length }} คะแนน
        </h4>
      </div>
      <div style="width: 5%"></div>
    </div>
    <div class="row mt-3">
      <clip-loader
        :loading="loading"
        :color="color"
        :size="size"
        class="mt-5"
      ></clip-loader>

      <div style="width: 5%" v-if="!loading"></div>
      <div class="col-md-8" style="width: 55%" v-if="!loading">
        <div class="card col-md-12" style="height: 100%">
          <div class="mt-3 mb-3">
            <div style="padding: 10px">
              <a
                style="color: black"
                :href="
                  '/Q' +
                  question.type +
                  '/' +
                  question.question_id +
                  '/' +
                  question.question_set_id +
                  '/' +
                  question.type +
                  '/edit'
                "
                target="_blank"
              >
                <label class="mb-3 mt-3"
                  >{{ question.index }}.
                  <span
                    v-html="question.question"
                    style="cursor: pointer"
                  ></span></label></a
              ><br />
              <div v-if="question.type == 3">
                <div
                  class="form-check mb-3"
                  v-for="(a, i) in question.key"
                  :key="i"
                >
                  <input
                    disabled
                    v-model="a.answer"
                    class="form-control"
                    :style="'width: 30%;' + a.check"
                    type="text"
                    id="flexRadioDefault1"
                  />
                </div>
              </div>
              <div
                v-else
                class="form-check mb-3"
                v-for="(a, i) in question.key"
                :key="i"
              >
                <input
                  disabled
                  v-model="question.answer1"
                  class="form-check-input"
                  type="radio"
                  :name="question.index"
                  :id="a.answer"
                  :key="a.answer"
                  :value="a.answer"
                />
                <label class="form-check-label" :for="a.answer">
                  <span v-html="a.answer"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="col-md-12" style="padding: 10px">
            {{ question.score }} คะแนน
          </div>
          <div class="col-md-12" style="padding: 10px" v-if="question">
            <div>คำตอบที่ถูกต้อง</div>
            <!-- {{question}} -->
            <table class="table table-bordered">
              <thead>
                <tr style="text-align: center">
                  <!-- <th scope="col" style="background-color: #92d050">ข้อที่</th>
            <th scope="col" style="background-color: #92d050">คำถาม</th> -->
                  <th
                    scope="col"
                    v-for="(c, i) in question.answercorrect"
                    :key="i + 2"
                  >
                    <span v-html="c"> </span>
                  </th>
                </tr>
              </thead>
            </table>
          </div>
          <div class="text-right mb-3">
            <button
              type="button"
              class="btn btn-info"
              @click="back()"
              style="float: left; margin: 5px"
              v-if="question.index != 1"
            >
              <i class="fa fa-arrow-left"></i>
            </button>
            <button
              type="button"
              class="btn btn-info"
              @click="next()"
              style="float: right; margin: 5px"
              v-if="question.index != questions.length"
            >
              <i class="fa fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
      <div
        class="col-md-4"
        style="width: 35%; overflow-y: scroll; height: 600px"
        v-if="!loading"
      >
        <div style="padding: 15px; height: 100%">
          <div class="row">
            <div
              class="col-md-12 mb-3"
              v-for="(q, i) in questions"
              :key="i"
              @click="getquestion(q, i + 1)"
            >
              <div class="myDIV" style="margin: auto; cursor: pointer">
                <div
                  style="
                    background-color: #93fba6;
                    border-style: solid;
                    border-width: thin;
                    padding: 5px;
                  "
                  v-if="q.type == 3 && q.score"
                >
                  ข้อที่ {{ i + 1 }}. <span v-html="q.question"></span><br />
                  <div
                    class="form-check col-md-5"
                    v-for="(a, i) in q.key"
                    :key="i"
                  >
                    <input
                      disabled
                      v-model="a.answer"
                      class="form-control form-control-sm"
                      style="width: 100%"
                      type="text"
                      id="flexRadioDefault1"
                    />
                  </div>
                </div>
                <div
                  style="
                    background-color: #fba093;
                    border-style: solid;
                    border-width: thin;
                    padding: 5px;
                  "
                  v-if="q.type == 3 && !q.score"
                >
                  ข้อที่ {{ i + 1 }}. <span v-html="q.question"></span><br />
                  <div
                    class="form-check col-md-5"
                    v-for="(a, i) in q.key"
                    :key="i"
                  >
                    <input
                      disabled
                      v-model="a.answer"
                      class="form-control form-control-sm"
                      style="width: 100%"
                      type="text"
                      id="flexRadioDefault1"
                    />
                  </div>
                </div>
                <div
                  style="
                    background-color: #93fba6;
                    border-style: solid;
                    border-width: thin;
                    padding: 5px;
                  "
                  v-if="q.type != 3 && q.score"
                >
                  ข้อที่ {{ i + 1 }}. <span v-html="q.question"></span><br />
                  <div class="form-check" v-for="(a, i) in q.key" :key="i">
                    <input
                      disabled
                      v-model="q.answer1"
                      class="form-check-input"
                      type="radio"
                      :name="q.question"
                      :id="a.answer"
                      :key="a.answer"
                      :value="a.answer"
                    />

                    <label class="form-check-label">
                      <span v-html="a.answer"></span>
                    </label>
                  </div>
                </div>
                <div
                  style="
                    border-style: solid;
                    border-width: thin;
                    padding: 5px;
                    background-color: #fba093;
                  "
                  v-if="q.type != 3 && !q.score"
                >
                  ข้อที่ {{ i + 1 }}. <span v-html="q.question"></span><br />

                  <div class="form-check" v-for="(a, i) in q.key" :key="i">
                    <input
                      disabled
                      v-model="q.answer1"
                      class="form-check-input"
                      type="radio"
                      :name="q.question"
                      :id="a.answer"
                      :key="a.answer"
                      :value="a.answer"
                    />
                    <label class="form-check-label">
                      <span v-html="a.answer"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 5%"></div>
      <!-- <div class="text-center mt-3">
        <span style="color: red">*</span> ทำข้อสอบครบทุกข้อ
        คลิกปุ่มยืนยันการส่งข้อสอบ
        <br />
        <button
          type="button"
          class="btn btn-success btn-sm"
          @click="save()"
          style="float: center; margin: 5px"
        >
          ยืนยันการส่งข้อสอบ
        </button>
      </div> -->
    </div>
  </div>
</template>

<script>
import AnswerTrainingService from "../services/AnswerTrainingService";
import AnswerTimesService from "../services/AnswerTimesService";
import RandomService from '../services/RandomService';
import UserService from '../services/UserService';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import QuestionSubject from "../services/QuestionSubject";
import QuestionObject from "../services/QuestionObject";

export default {
  name: "TestResult",
  props: {
    msg: String,
  },
  components: {
    ClipLoader
  },
  data() {
    return {
      questions: [],
      question: {},
      score: 0,
      test_no: 0,
      times: {},
      stuid:'',
      code:{},
      color: '#5bc0de',
      size: '50px',
      margin: '2px',
      radius: '2px',
      loading:true,
    };
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/");
    } else {
      this.getmanagetest()
      this.test_no = this.$route.params.id;
      this.stuid = this.$route.params.stuid;
      UserService.getUser(this.stuid).then((res)=>{
        this.code = res.data
        // console.log( this.code);
      })
      console.log(this.stuid);
      (this.questions = []), (this.question = {}), this.getquestions();
      AnswerTrainingService.getScoreStudent(this.stuid, this.test_no).then(
        (res) => {
          // console.log(res.data,'scrore');
          var scoreall = 0
    //    console.log(res);
        for (let s = 0; s < res.data.length; s++) {
            // console.log(res[s].score);
            scoreall = scoreall+res.data[s].score
            if (s+1 == res.data.length) {
                this.score = scoreall
            }
        }
          
        }
      );
    }
  },
  methods: {

    save() {
      AnswerTrainingService.deleteallbystuidandno(this.stuid,this.test_no).then(()=>{
        var check = false;
      for (let q = 0; q < this.questions.length; q++) {
        if (this.questions[q].answer1 == "") {
          alert("กรุณาตอบคำถามให้ครบทุกข้อ");
          check = true;
          break;
        }
      }
      if (check == false) {
        for (let q = 0; q < this.questions.length; q++) {
          var answer = [];
          if (
            this.questions[q].type == 1 ||
            this.questions[q].type == 3
          ) {
            QuestionSubject.getQuestionSubject(
              this.questions[q].question_id,
              this.questions[q].question_set_id
            ).then((res) => {
              // console.log(res.data);
              var score = 0;
              answer = [];
              if (res.data.CorrectAns1 != "-" && res.data.CorrectAns1 != null) {
                answer.push(res.data.CorrectAns1);
              }
              if (res.data.CorrectAns2 != "-" && res.data.CorrectAns2 != null) {
                answer.push(res.data.CorrectAns2);
              }
              if (res.data.CorrectAns3 != "-" && res.data.CorrectAns3 != null) {
                answer.push(res.data.CorrectAns3);
              }
              if (res.data.CorrectAns4 != "-" && res.data.CorrectAns4 != null) {
                answer.push(res.data.CorrectAns4);
              }
              if (res.data.CorrectAns5 != "-" && res.data.CorrectAns5 != null) {
                answer.push(res.data.CorrectAns5);
              }
              if (res.data.CorrectAns6 != "-" && res.data.CorrectAns6 != null) {
                answer.push(res.data.CorrectAns6);
              }
              if (res.data.CorrectAns7 != "-" && res.data.CorrectAns7 != null) {
                answer.push(res.data.CorrectAns7);
              }
              if (res.data.CorrectAns8 != "-" && res.data.CorrectAns8 != null) {
                answer.push(res.data.CorrectAns8);
              }
              if (res.data.CorrectAns9 != "-" && res.data.CorrectAns9 != null) {
                answer.push(res.data.CorrectAns9);
              }
              if (res.data.CorrectAns10 != "-" && res.data.CorrectAns10 != null) {
                answer.push(res.data.CorrectAns10);
              }
              if (res.data.CorrectAns11 != "-" && res.data.CorrectAns11 != null) {
                answer.push(res.data.CorrectAns11);
              }
              if (res.data.CorrectAns12 != "-" && res.data.CorrectAns12 != null) {
                answer.push(res.data.CorrectAns12);
              }
              if (res.data.CorrectAns13 != "-" && res.data.CorrectAns13 != null) {
                answer.push(res.data.CorrectAns13);
              }
              if (res.data.CorrectAns14 != "-" && res.data.CorrectAns14 != null) {
                answer.push(res.data.CorrectAns14);
              }
              if (res.data.CorrectAns15 != "-" && res.data.CorrectAns15 != null) {
                answer.push(res.data.CorrectAns15);
              }
              var allscore = this.questions[q].no;
              allscore = 1 / allscore;
              // console.log(allscore);
              // console.log(answer);
              if (this.questions[q].type == 1) {
                // let foundans = answer.includes(this.questions[q].answer1)
                let foundans = answer.find(
                  (element) => element == this.questions[q].answer1
                );
                // console.log(foundans);

                if (foundans) {
                  score = 1;
                }
                var data = {
                  index: this.questions[q].index,
                  image: this.questions[q].image,
                  question_id: this.questions[q].question_id,
                  question: this.questions[q].question,
                  question_set_id: this.questions[q].question_set_id,
                  no: this.questions[q].no,
                  student_id: this.stuid,
                  ans1: this.questions[q].ans1,
                  ans2: this.questions[q].ans2,
                  ans3: this.questions[q].ans3,
                  ans4: this.questions[q].ans4,
                  ans5: this.questions[q].ans5,
                  answer1: this.questions[q].answer1,
                  answer2: this.questions[q].answer2,
                  answer3: this.questions[q].answer3,
                  score: score,
                  type: this.questions[q].type,
                  random_id: this.questions[q].random_id,
                test_no: this.test_no,
                };
                // console.log(data);
                AnswerTrainingService.getallbtstuidandtestno(this.stuid,this.test_no,this.questions[q].index).then((count) => {
                  // console.log(count);
                  if (count.data.length == 0) {
                    AnswerTrainingService.createAnswerStudent(data).then(() => {
                  // console.log(res.data);
                });
                  }
              })
              } else {
                score = 0;
                var ansstu = [];
                for (let a = 0; a < this.questions[q].key.length; a++) {
                  ansstu.push(this.questions[q].key[a].answer);
                  // console.log(this.questions[q].key[a].answer);
                  // console.log(answer);
                //   var foundall = answer.filter(str => str.toLowerCase().includes((this.questions[q].key[a].answer).toLowerCase())
                // );
                RandomService.getCorrect(this.questions[q].question_set_id,this.questions[q].question_id,this.questions[q].key[a].answer).then((res)=>{
                // console.log(foundall);
                  if (res.data.length > 0) {
                    score = score + allscore;
                  }
                })
              }
                data = {
                  index: this.questions[q].index,
                  image: this.questions[q].image,
                  question_id: this.questions[q].question_id,
                  question: this.questions[q].question,
                  question_set_id: this.questions[q].question_set_id,
                  no: this.questions[q].no,
                  student_id: this.stuid,
                  ans1: this.questions[q].ans1,
                  ans2: this.questions[q].ans2,
                  ans3: this.questions[q].ans3,
                  ans4: this.questions[q].ans4,
                  ans5: this.questions[q].ans5,
                  answer1: this.questions[q].answer1,
                  answer2: this.questions[q].answer2,
                  answer3: this.questions[q].answer3,
                  score: score,
                  type: this.questions[q].type,
                  random_id: this.questions[q].random_id,
                test_no: this.test_no,
                };
                // console.log(data);
                AnswerTrainingService.getallbtstuidandtestno(this.stuid,this.test_no,this.questions[q].index).then((count) => {
                  // console.log(count);
                  if (count.data.length == 0) {
                    AnswerTrainingService.createAnswerStudent(data).then(() => {
                  // console.log(res.data);
                });
                  }
              })
                // console.log(data);
              }
            });
          } else if (this.questions[q].type == 2) {
            var score = 0;
            QuestionObject.getQuestionObject(
              this.questions[q].question_id,
              this.questions[q].question_set_id
            ).then((res) => {
              score = 0;
              // console.log(res.data.CorrectAns);
              console.log(this.questions[q].answer1);
              if (res.data.CorrectAns == this.questions[q].answer1) {
                score = 1;
              }
              var data = {
                index: this.questions[q].index,
                image: this.questions[q].image,
                question_id: this.questions[q].question_id,
                question: this.questions[q].question,
                question_set_id: this.questions[q].question_set_id,
                no: this.questions[q].no,
                student_id: this.stuid,
                ans1: this.questions[q].ans1,
                ans2: this.questions[q].ans2,
                ans3: this.questions[q].ans3,
                ans4: this.questions[q].ans4,
                ans5: this.questions[q].ans5,
                answer1: this.questions[q].answer1,
                answer2: this.questions[q].answer2,
                answer3: this.questions[q].answer3,
                score: score,
                type: this.questions[q].type,
                random_id: this.questions[q].random_id,
                test_no: this.test_no,
              };
              // console.log(data);

              AnswerTrainingService.getallbtstuidandtestno(this.stuid,this.test_no,this.questions[q].index).then((count) => {
                // console.log(count);
                  if (count.data.length == 0) {
                    AnswerTrainingService.createAnswerStudent(data).then(() => {
                  // console.log(res.data);
                });
                  }
              })
            });
          }
        }
      }
    })
    },
    getmanagetest() {
      AnswerTimesService.getAnswerTime().then((res) => {
        this.times = res.data[0]
      })
    },
    back() {
      // console.log(this.question);
      for (let q = 0; q < this.questions.length; q++) {
        // console.log(q);
        if (q == this.question.index - 2) {
          // console.log(q);
          this.question = {};
          // console.log(this.questions[q]);
          this.question = this.questions[q];
          this.question.index = q + 1;
          break;
        }
      }
    },
    next() {
      // var ques = this.questions;
      // this.questions = [];
      // this.questions = ques;
      // console.log(this.question.index);

      for (let q = 0; q < this.questions.length; q++) {
        if (q == this.question.index) {
          // console.log(q);
          this.question = {};
          // console.log(this.questions[q]);
          this.question = this.questions[q];
          this.question.index = q + 1;
          break;
        }
      }
    },
    checkq(data) {
      // console.log(data);
      var result = false
      if (data != null) { 
      if (data.includes("Q")) {
        result = true
      }   else{
        result = false
      }
      }
      console.log(result);
      return result
    },
    
    getquestion(q, i) {
      this.question = q;
      this.question.index = i;
      // console.log(this.question.key);
    },
    savenewanswer(){

      AnswerTrainingService.getTestAnswerStudents(
        this.stuid,
        this.test_no
      ).then(async (res) => {
        // console.log(res.data);
        // this.questions = []
        // this.questions = res.data;
        var score = 0
        for (let q = 0; q < res.data.length; q++) {
          for (let k = 0; k < res.data[q].key.length; k++) {
            RandomService.getCorrect(res.data[q].question_set_id,res.data[q].question_id,res.data[q].key[k].answer).then((key=>{
              // console.log(res.data);
              // var check = 'background-color: #ff8829'
              if (key.data.length > 0) {
                // check = 'background-color: #a5c90f'
                // console.log(res.data[q]);
                if (res.data[q].type == 3) {
                  var allscore = res.data[q].no;
              allscore = 1 / allscore;
                    score = score + allscore;
          // console.log(score);
                  // console.log(res.data);
                if (k+1 == res.data[q].key.length) {
              // res.data[q].key[k].check = check
                    // console.log(score);
                    if (res.data[q].score == 0) {
                      // console.log(res.data[q].no,'no',res.data[q].index,'index');
                      if (res.data[q].no == 1) {
                        score = 1
                      }
                      var count = { weight: score };
                      // console.log(count,'count');
                  RandomService.updateRandomWeight(
                    res.data[q].random_id,
                    count
                  ).then(() => {
                    var rands = {score:count.weight}
                // console.log('rands', rands);
                    AnswerTrainingService.updatescore(res.data[q].id,rands).then(()=>{

                      score = 0
                    })
                  });
                    }
              }
                }
              }
              if (q+1 == res.data.length && k+1 == res.data[q].key.length) {
                this.getquestions()
              }
            }))
            
          }
         
          
        }
      });
    },
    getquestions() {
      AnswerTrainingService.getTestAnswerStudents(
        this.stuid,
        this.test_no
      ).then(async (res) => {
        console.log(res.data);
        this.questions = res.data;
        for (let q = 0; q < this.questions.length; q++) {
          for (let k = 0; k < this.questions[q].key.length; k++) {
            // RandomService.getCorrect(this.questions[q].question_set_id,this.questions[q].question_id,this.questions[q].key[k].answer).then((res=>{
              // console.log(res.data);
              var check = 'background-color: #ff8829'
              if (this.questions[q].score > 0) {
                check = 'background-color: #a5c90f'
                
              }
              this.questions[q].key[k].check = check
              if (q+1 == this.questions.length && k+1 == this.questions[q].key.length) {
                
        this.question = this.questions[0];
        this.question.index = 1;
        this.loading = false
        // console.log(this.question);
              }
            // }))
            
          }
         
          
        }
      });
    },
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}

.vertical-left {
  margin-left: 20px;
}

.title {
  color: blue;
}

.hide {
  display: none;
}

.myDIV:hover + .hide {
  display: inline-block;
  color: blue;
}
</style>
