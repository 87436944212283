import http from "../http-common";

class RandomService {
  updateRandom(id, data) {
    return http.put(`/random/${id}`, data);
  }
  updatestatusdelete(id, data) {
    return http.put(`/random/updatestatusdelete/${id}`, data);
  }  
  updateRandomBytype(id, data) {
    return http.put(`/random/updateRandomBytype/${id}`, data);
  }
  getRandoms(count) {
    return http.get('/random?count='+count);
  }
  
  getCorrect(question_set_id,question_id,answer1) {
    return http.get('/random/getcorrect?question_set_id='+question_set_id+"&&question_id="+question_id+"&&answer1="+answer1);
  }
  findOneweight(id) {
    return http.get('/random/findOneweight/'+id);
  }
  findOneLevel(id,level) {
    return http.get('/random/findOneLevel/'+id+'/'+level);
  }
  updateRandomStatus(id, type,data) {
    return http.put(`/random/updatestatus/${id}/${type}`, data);
  }
  createrandom(data) {
    return http.post('/random', data);
  }
  getRandom(count) {
    return http.get('/random/gettrain?count='+count);
  }
  getTestAll(count) {
    return http.get('/random/gettestall?count='+count);
  }
  gettestallbytype(type) {
    return http.get('/random/gettestallbytype?type='+type);
  }
  getweight(count) {
    return http.get('/random/getweight?count='+count);
  }
  gettesttrain(start,end,test_no) {
    return http.get('/random/gettesttrain?start='+start+'&&end='+end+'&&test_no='+test_no);
  }
  updateRandomWeight(id,data) {
    return http.put(`/random/updateweight/${id}`, data);
  }
  updateweightnew(id,data) {
    return http.put(`/random/updateweightnew/${id}`, data);
  }
  resetweight(id,type) {
    return http.delete(`/random/`+id+'/'+type);
  }
  deleteRandom(id, data) {
    return http.delete(`/random/deletebysetid/${id}`, data);
  }
  deleteRandomquestion_set_id(id, data) {
    return http.put(`/random/updatestatusdelete/${id}`, data);
  }
}

export default new RandomService();