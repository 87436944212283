<template>
  <div class="container row mb-3" style="margin: auto">
    <div class="row mt-5">
      <div style="width: 5%"></div>
      <div class="col-md-8">
        <h4>ผลการสอบครั้งที่ {{test_no}} </h4>
        <h4>ผลคะแนนรวม {{ score }} คะแนน</h4>
      </div>
      <div style="width: 5%"></div>
    </div>
    <div class="row mt-3">
      <div style="width: 5%"></div>
      <div class="col-md-8" style="width: 55%">
        <div class="card col-md-12" style="height: 100%">
          <div class="mt-3 mb-3">
            <div style="padding: 10px">
              <label class="mb-3 mt-3"
                >{{ question.index }}.
                <span v-html="question.question"></span></label
              ><br />
              <div v-if="question.type == 3">
                <div
                  class="form-check mb-3"
                  v-for="(a, i) in question.key"
                  :key="i"
                >
                  <input
                  disabled
                    v-model="a.answer"
                    class="form-control"
                    style="width: 30%"
                    type="text"
                    id="flexRadioDefault1"
                  />
                </div>
              </div>
              <div
                v-else
                class="form-check mb-3"
                v-for="(a, i) in question.key"
                :key="i"
              >
                <input
                disabled
                  v-model="question.answer1"
                  class="form-check-input"
                  type="radio"
                  :name="question.index"
                  :id="a.answer"
                  :key="a.answer"
                  :value="a.answer"
                />
                <label class="form-check-label" :for="a.answer">
                  <span v-html="a.answer"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="col-md-12" style="padding:10px" v-if="times.view_answer_status">
        <div>คำตอบที่ถูกต้อง</div>
      <table class="table table-bordered">
        <thead>
          <tr style="text-align: center">
            <!-- <th scope="col" style="background-color: #92d050">ข้อที่</th>
            <th scope="col" style="background-color: #92d050">คำถาม</th> -->
            <th scope="col" v-for="(c,i) in question.answercorrect" :key="i+2"><span v-html="c"> </span></th>
          </tr>
        </thead>
      </table>
    </div>
          <div class="text-right mb-3">
            <button
              type="button"
              class="btn btn-info"
              @click="back()"
              style="float: left; margin: 5px"
              v-if="question.index != 1"
            >
              <i class="fa fa-arrow-left"></i>
            </button>
            <button
              type="button"
              class="btn btn-info"
              @click="next()"
              style="float: right; margin: 5px"
              v-if="question.index != questions.length"
            >
              <i class="fa fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
      <div
        class="col-md-4"
        style="width: 35%; overflow-y: scroll; height: 600px"
      >
        <div style="padding: 15px; height: 100%">
          <div class="row">
            <div
              class="col-md-12 mb-3"
              v-for="(q, i) in questions"
              :key="i"
              @click="getquestion(q, i + 1)"
            >
              <div class="myDIV" style="margin: auto; cursor: pointer">
                <div
                  style="
                    background-color: #93fba6;
                    border-style: solid;
                    border-width: thin;
                    padding: 5px;
                  "
                  v-if="q.type == 3 && q.score"
                >
                  ข้อที่ {{ i + 1 }}. <span v-html="q.question"></span><br />
                  <div
                    class="form-check col-md-5"
                    v-for="(a, i) in q.key"
                    :key="i"
                  >
                    <input
                      disabled
                      v-model="a.answer"
                      class="form-control form-control-sm"
                      style="width: 100%"
                      type="text"
                      id="flexRadioDefault1"
                    />
                  </div> 
                </div>
              <div
                  style="
                    background-color: #fba093;
                    border-style: solid;
                    border-width: thin;
                    padding: 5px;
                  "
                  v-if="q.type == 3 && !q.score"
                >
                  ข้อที่ {{ i + 1 }}. <span v-html="q.question"></span><br />
                  <div
                    class="form-check col-md-5"
                    v-for="(a, i) in q.key"
                    :key="i"
                  >
                    <input
                      disabled
                      v-model="a.answer"
                      class="form-control form-control-sm"
                      style="width: 100%"
                      type="text"
                      id="flexRadioDefault1"
                    />
                  </div>
                </div>
                <div
                  style="
                    background-color: #93fba6;
                    border-style: solid;
                    border-width: thin;
                    padding: 5px;
                  "
                  v-if="q.type != 3 && q.score"
                >
                  ข้อที่ {{ i + 1 }}. <span v-html="q.question"></span><br />
                  <div class="form-check" v-for="(a, i) in q.key" :key="i">
                    <input
                      disabled
                      v-model="q.answer1"
                      class="form-check-input"
                      type="radio"
                      :name="q.question"
                      :id="a.answer"
                      :key="a.answer"
                      :value="a.answer"
                    />

                    <label class="form-check-label">
                      <span v-html="a.answer"></span>
                    </label>
                  </div>
                </div>
                <div
                  style="border-style: solid; border-width: thin; padding: 5px;background-color: #fba093;"
                  v-if="q.type != 3 && !q.score"
                >
                  ข้อที่ {{ i + 1 }}. <span v-html="q.question"></span><br />

                  <div class="form-check" v-for="(a, i) in q.key" :key="i">
                    <input
                      disabled
                      v-model="q.answer1"
                      class="form-check-input"
                      type="radio"
                      :name="q.question"
                      :id="a.answer"
                      :key="a.answer"
                      :value="a.answer"
                    />
                    <label class="form-check-label">
                      <span v-html="a.answer"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 5%"></div>
    </div>
  </div>
</template>

<script>
import AnswerTrainingService from "../services/AnswerTrainingService";
import AnswerTimesService from "../services/AnswerTimesService";

export default {
  name: "TestResult",
  props: {
    msg: String,
  },
  component: {},
  data() {
    return {
      questions: [],
      question: {},
      score: 0,
      test_no: 0,
      times:{}
    };
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/");
    } else {
      this.getmanagetest()
      this.test_no = this.$route.params.id;
      (this.questions = []), (this.question = {}), this.getquestions();
      AnswerTrainingService.getScoreStudent(this.currentUser.user_id,this.test_no).then(
        (res) => {
          // console.log(res.data);
          this.score = res.data.score;
        }
      );
    }
  },
  methods: {
    getmanagetest() {
      AnswerTimesService.getAnswerTime().then((res) => {
        this.times = res.data[0]
      })
    },
    back() {      
      for (let q = 0; q < this.questions.length; q++) {
        // console.log(q);
        if (q == this.question.index - 2) {
          // console.log(q);
          this.question = {};
          // console.log(this.questions[q]);
          this.question = this.questions[q];
          this.question.index = q + 1;
          break;
        }
      }
    },
    next() {
      // var ques = this.questions;
      // this.questions = [];
      // this.questions = ques;
      // console.log(this.question);
      
      for (let q = 0; q < this.questions.length; q++) {
        if (q == this.question.index) {
          // console.log(q);
          this.question = {};
          // console.log(this.questions[q]);
          this.question = this.questions[q];
          this.question.index = q + 1;
          break;
        }
      }
    },
    checkq(data) {
      if (data) {
        return data.includes("Q");
      } else {
        return false;
      }
    },
    getquestion(q, i) { 
      this.question = q;
      this.question.index = i;
      // console.log(this.question.key);
    },
    getquestions() {
      AnswerTrainingService.getTestAnswerStudents(
        this.currentUser.user_id,
        this.test_no
      ).then((res) => {
        // console.log(res.data);
        // this.questions = []
        this.questions = res.data;
        this.question = this.questions[0];
        this.question.index = 1;
        // console.log(this.question);
      });
    },
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.vertical-left {
  margin-left: 20px;
}
.title {
  color: blue;
}
.hide {
  display: none;
}

.myDIV:hover + .hide {
  display: inline-block;
  color: blue;
}
</style>
