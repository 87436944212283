import axios from "axios";
import LinkService from '../src/services/LinkService';

export default axios.create({
  baseURL: LinkService.getLink() + "/api",

  // baseURL : "https://api-test.ponnipa.in.th/api",
  
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST',
    'Access-Control-Allow-Headers': 'Content-Type',
  },
  mode: 'no-cors'
});