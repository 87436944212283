import http from "../http-common";

class TeacherService {
  createteachers(data) {
    return http.post(`/teachers`, data);
  }
  getteacherss(firstname,lastname) {
    return http.get(`/teachers?firstname=`+firstname+'&&lastname='+lastname);
  }
  getteacher(id) {
    return http.get('/teachers/' + id);
}
  updateteachers(id, data) {
    return http.put(`/teachers/${id}`, data);
  }
  deleteteacher(id) {
    return http.delete('/teachers/' + id);
}
}

export default new TeacherService();