<template>
  <div class="container-fluid row" style="padding: 30px">
    <div class="col-md-12 mt-5">
      <label for="exampleInputEmail1" class="form-label"
        >ต้นแบบชุดข้อสอบ</label
      >
    </div>   
    <div class="accordion" id="accordionExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button accordion-sm" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
       ปรนัย แบบที่ 1
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
         <VueDocPreview :value="link+'/template1.docx'" :type="'office'" height="100" />
        <button class="btn btn-success btn-sm mt-3" type="button"><i class="fa fa-file-excel-o"></i>&nbsp;
     <a :href="link+'/Template1.xlsx'" target="_blank" style="color:white;text-decoration:none">ดาวน์โหลดตัวอย่างไฟล์</a>
  </button>       
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        ปรนัย แบบที่ 2
      </button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
                 <VueDocPreview :value="link+'/template2.docx'" :type="'office'" height="100"/>
         <button class="btn btn-success btn-sm mt-3" type="button"> <i class="fa fa-file-excel-o"></i>&nbsp;
     <a :href="link+'/Template2.xlsx'" target="_blank" style="color:white;text-decoration:none">ดาวน์โหลดตัวอย่างไฟล์</a>
  </button>   
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
        อัตนัย
      </button>
    </h2>
    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div class="accordion-body">
                 <VueDocPreview :value="link+'/template3.docx'" :type="'office'" height="100"/>
         <button class="btn btn-success btn-sm mt-3" type="button"><i class="fa fa-file-excel-o"></i>&nbsp;
     <a :href="link+'/Template3.xlsx'" target="_blank" style="color:white;text-decoration:none">ดาวน์โหลดตัวอย่างไฟล์</a>
  </button>   
      </div>
    </div>
  </div>
</div>
  </div>
</template>

<script>
import PrototypeTest from "../services/PrototypeTest";
import LinkService from "../services/LinkService";
import VueDocPreview from 'vue-doc-preview'

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  components: {
    VueDocPreview
  },
  data() {
    return {
      list: [],
      link:''
    };
  },
  mounted() {
    this.link = LinkService.getLink()+'/uploads'
    console.log(this.link);
    // this.getprototype();
  },
  methods: {
    getprototype() {
      PrototypeTest.getPrototypeTests().then((res)=>{
        this.list = res.data
      })
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.accordion-button{
padding: 0.5rem 1rem;
}
.accordion-body {
    padding: 1rem 1rem;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
</style>
