<template>
  <div class="container row">
    <div class="card mt-5 col-md-12" style="width: 90%">
      <div>
        <div v-for="(l, i) in list" :key="l.id" class="mt-5">
          <label class="mb-3">{{ i + 1 }}. {{ l.questionname }}</label>
          <div v-for="(a, i) in l.no" :key="i" class="mb-3">
            <input
             class="form-control"
             style="width:30%"
              type="text"
              id="flexRadioDefault1"
            />
          </div>
        </div>
      </div>
      <!-- <div class="social-auth-links text-center mb-3 mt-3">
        <button @click="save()" type="button" class="btn btn-success btn-sm">
          บันทึก
        </button>
      </div> -->
    </div>
  </div>
</template>

<script>
import SubjectTestService from "../services/SubjectTestService.js";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      list: [],
      status:false
    };
  },
  mounted() {
    this.getlist();
  },
  methods: {
    getlist() {
      SubjectTestService.getobjectivetestnames().then((res) => {
        // console.log(res.data);
        this.list = res.data;
      });
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
</style>
