<template>
  <div class="container-fluid row">
    <div class="card mt-5 col-md-12" style="width: 500px">
      <div class="mb-3 mt-3">
        <label for="exampleInputEmail1" class="form-label">ชุดทดสอบ</label>
        <select v-model="question" class="form-select form-select-sm" aria-label=".form-select-sm example"
          @change="gettype">
          <option :value="q.id" v-for="q in questionset" :key="q.id">
            {{ q.question_name }}
          </option>
        </select>
      </div>
    </div>
    <clip-loader :loading="loading" :color="color" :size="size" class="mt-5"></clip-loader>
    <div v-if="!loading">
    <div class="col-md-12 mt-5" style="margin: 15px" v-for="q in list" :key="q.type">
      <table class="table table-bordered" v-if="q.type == 1 || q.type == 3">
        <thead>
          <tr style="background-color:#C0BEBE">
            <th colspan="19" style="text-align:center">ข้อสอบ{{ q.name }}</th>
          </tr>
          <tr style="text-align: center">
            <th v-if="q.type == 1" scope="col" style="background-color: #92d050">
              ข้อ
            </th>
            <th v-if="q.type == 3" scope="col" style="background-color: #92d050">
              จำนวนคำตอบ
            </th>
            <th scope="col" style="background-color: #92d050">คำถาม</th>
            <th scope="col" style="background-color: #92d050">ระดับข้อสอบ</th>
            <th scope="col" style="background-color: #92d050">จำนวนผู้ที่ทำข้อสอบ</th>
            <th scope="col" style="background-color: #92d050">จำนวนที่ตอบถูก</th>
            <th scope="col" style="background-color: #92d050">สถานะ</th>

          </tr>
        </thead>
        <tbody>
          <tr v-for="l in q.quess" :key="l.id">
            <th scope="row" style="text-align: center" v-if="l.type == 1">{{ l.index_id }}</th>
            <th scope="row" style="text-align: center" v-if="l.type == 3">{{ l.no }}</th>
            <td scope="row" style="width:40%">
              <span v-html="l.question"></span>
            </td>
            <td scope="row"><span v-html="l.levelname"></span></td>
            <td scope="row"> <span v-html="l.count || 0"></span></td>
            <td scope="row"><span v-html="l.weight || 0"> </span></td>
            <td scope="row">
              <div v-if="l.status == 1">ใช้งานอยู่</div>
              <div v-else>ไม่ใช้งาน</div>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="table table-bordered" v-if="q.type == 2">
        <thead>
          <tr style="background-color:#C0BEBE">
            <th colspan="9" style="text-align:center">ข้อสอบ{{ q.name }}</th>
          </tr>
          <tr style="text-align: center">
            <th scope="col" style="background-color: #92d050">ข้อ</th>
            <th scope="col" style="background-color: #92d050">คำถาม</th>
            <th scope="col" style="background-color: #92d050">ระดับข้อสอบ</th>
            <th scope="col" style="background-color: #92d050">จำนวนผู้ที่ทำข้อสอบ</th>
            <th scope="col" style="background-color: #92d050">จำนวนที่ตอบถูก</th>
            <th scope="col" style="background-color: #92d050">สถานะ</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="l in q.quess" :key="l.id">
            <th scope="row" style="text-align: center">{{ l.no }}</th>
            <td style="width:40%">
              <span v-html="l.question"></span>
              <!-- {{ l.question }} -->
            </td>
            <td scope="row">
              <span v-html="l.levelname"></span>
            </td>
            <td scope="row"><span v-html="l.count || 0"></span></td>
            <td scope="row">
              <span v-html="l.weight || 0"></span>
            </td>
            <td scope="row">
              <div v-if="l.status == 1">ใช้งานอยู่</div>
              <div v-else>ไม่ใช้งาน</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
    <!-- Modal -->
    <div class="modal fade" id="deletedata" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">ยืนยันการลบ</h5>
            <button id="closedeletedoc" type="button" class="btn-close" data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger btn-sm" @click="deletedata()">
              ยืนยัน
            </button>
            <button type="button" class="btn btn btn-secondary btn-sm" data-bs-dismiss="modal">
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="deletetypedata" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">ยืนยันการลบข้อมูลทั้งหมดในชุดทดสอบนี้</h5>
            <button id="closedeletetypemodal" type="button" class="btn-close" data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger btn-sm" @click="closedeletetype()">
              ยืนยัน
            </button>
            <button type="button" class="btn btn btn-secondary btn-sm" data-bs-dismiss="modal">
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import readXlsxFile from "read-excel-file";
import QuestionSubject from "../services/QuestionSubject";
import QuestionObject from "../services/QuestionObject";
import RandomService from "../services/RandomService";
import QuestionSetService from "../services/QuestionSetService";
import PrototypeTest from "../services/PrototypeTest";
import axios from 'axios';
import LinkService from "../services/LinkService";
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default {
  components: {
    ClipLoader
  },
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      color: '#5bc0de',
      size: '50px',
      margin: '2px',
      radius: '2px',
      loading:false,
      concert_id: 0,
      menu: [],
      user: {},
      file: [],
      question: "1",
      list: [],
      questionid: {},
      questionset: [],
      format: "",
      type: "",
      typelist: [],
      id: '',
      typedelete: ''
    };
  },
  mounted() {

    this.question = this.$route.query.id;
    // console.log(this.question);
    if (this.question) {
      QuestionSetService.getQuestionSet(this.question).then((res) => {
      // console.log(res.data);
      this.format = res.data.format;
      this.getdata();
    });
    }
    this.getQuestionSets();
    var link = LinkService.getLink() + '/uploads'
    PrototypeTest.getPrototypeTests().then((res) => {
      // console.log(res.data);
      this.typelist = res.data
      for (let t = 0; t < this.typelist.length; t++) {
        if (this.typelist[t].name == "ปรนัย แบบที่ 1") {
          this.typelist[t].desc = "ตั้งคำถาม และใส่คำตอบที่ถูกต้องได้ไม่จำกัดจำนวน ระบบจะสุ่มคำตอบที่ถูกต้องมา 1 คำตอบ และจะสุ่มคำตอบลวงจากข้ออื่นๆ (ไม่ซ้ำกับคำตอบที่ถูก) มาให้ผู้ทำข้อสอบแต่ละชุดแบบอัตโนมัติ"
          this.typelist[t].url = link + '/Template1.xlsx'
        } if (this.typelist[t].name == "ปรนัย แบบที่ 2") {
          this.typelist[t].desc = "ตั้งคำถาม และใส่คำตอบที่ถูกต้อง 1 คำตอบ และคำตอบลวง 4 คำตอบ ผู้ทำข้อสอบแต่ละชุดจะได้รับตัวเลือกสลับตำแหน่งกันจากคำตอบที่ให้มาแบบอัตโนมัติ"
          this.typelist[t].url = link + '/Template2.xlsx'
        } if (this.typelist[t].name == "อัตนัย") {
          this.typelist[t].desc = "ตั้งคำถาม และใส่ keyword เพื่อให้ระบบตรวจคำตอบให้อัตโนมัติ"
          this.typelist[t].url = link + '/Template3.xlsx'
        }
      }
    })
  },
  methods: {
    getweight(random_id) {

      RandomService.findOneweight(random_id).then((res) => {
        // console.log(res.data.weight);
        return res.data.weight
      })
    },
    getcount(random_id) {

      RandomService.findOneweight(random_id).then((res) => {
        // console.log(res.data);
        return res.data.count
      })
    },
    onChangeImage(evt) {
      const files = evt.target.files || evt.dataTransfer.files;
      // const selectedImage = evt.target.files[0]; // get first file
      // this.createBase64Image(selectedImage);
      if (!files.length) return;
      var count = files.length
      var c = 0
      for (let f = 0; f < files.length; f++) {
        this.onUploadFileChangeAns(files[f]);
        c = c + 1
        if (count == c) {
          alert('อัพโหลดรูปภาพ ' + count + ' ไฟล์ เรียบร้อยแล้ว')
          document.getElementById("imagelist").value = null;
        }
      }
    },
    onUploadFileChangeAns(selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile); // appending file
      //  sending file to the backend
      //console.log(this.filename);
      // console.log(selectedFile);
      var http = LinkService.getLink() + '/uploadimage?name=' + selectedFile.name;
      axios
        .post(http, formData)
        .then(() => {
          // console.log(res.data);     
        })
    },
    gotopage() {
      if (this.type == '') {
        alert('กรุณาเลือกต้นแบบชุดข้อสอบ')
      } else {
        var page = '/Q' + this.type + '/0' + '/' + this.question + '/' + this.type
        this.$router.push(page);
      }
    },
    getQuestionSets() {
      this.loading = true
      QuestionSetService.getQuestionSets().then((res) => {
        this.questionset = res.data;
        // console.log(res.data);

        // console.log(this.question);
    if (!this.question) {
      this.question = this.questionset[0].id
      QuestionSetService.getQuestionSet(this.question).then((res) => {
        // console.log(res.data);
        this.$router.push("/testall?id=" + this.question);
        this.format = res.data.format;
        this.getdata();
      });
    }
      });
    },
    gettypedelete(type) {
      this.typedelete = type
    },
    getid(id) {
      // console.log(id);
      this.questionid = id;
    },
    closedeletetype() {
      // console.log(this.questionid);
      var ques = {
        status: 0,
        type: this.typedelete,
      };

      RandomService.updateRandomBytype(this.question, ques).then(() => {
        // console.log(res.data);
        this.getdata();
        document.getElementById("closedeletetypemodal").click();
      });
    },
    deletedata() {
      // console.log(this.questionid);
      var ques = {
        status: 0,
      };

      RandomService.updateRandom(this.questionid.random_id, ques).then(() => {
        // console.log(res.data);
        this.getdata();
        document.getElementById("closedeletedoc").click();
      });
    },
    checkq(data) {
      // console.log(data);
      var result = "";
      if (data != null) {
        result = data.includes("Q");
      } else {
        result = data;
      }
      return result;
    },
    save() {
      var levels = 1
      var levelsname = ''
      // console.log(this.file);
      if (this.type == "") {
        alert("กรุณาเลือกต้นแบบชุดข้อสอบ");
      } else if (this.file.length > 0) {
        var test = {};
        var question_id = "";
        // var ques = "";
        // var trimmedString = "";
        // var trimmedimage = "";
        // var imagefile = "";
        if (this.type == 1) {
          for (let i = 1; i < this.file.length; i++) {
            // console.log(this.file[i][this.file[i].length-1])
            test = {};
            test = {
              question_set_id: this.question,
              no: this.file[i][0],
              question: this.file[i][1],
              CorrectAns1: this.file[i][2],
              CorrectAns2: this.file[i][3],
              CorrectAns3: this.file[i][4],
              CorrectAns4: this.file[i][5],
              CorrectAns5: this.file[i][6],
              CorrectAns6: this.file[i][7],
              CorrectAns7: this.file[i][8],
              CorrectAns8: this.file[i][9],
              CorrectAns9: this.file[i][10],
              CorrectAns10: this.file[i][11],
              CorrectAns11: this.file[i][12],
              CorrectAns12: this.file[i][13],
              CorrectAns13: this.file[i][14],
              CorrectAns14: this.file[i][15],
              CorrectAns15: this.file[i][16],
              type: this.type,
              index_id: i
            };
            // console.log(test);

            QuestionSubject.createQuestionSubject(test).then((response) => {
              levelsname = this.file[i][this.file[i].length - 1]
              //  console.log(levelsname);
              if (levelsname == 'ปานกลาง') {
                levels = 2
              } else if (levelsname == 'ยาก') {
                levels = 3
              } else {
                levels = 1
              }
              // console.log(levels);
              // console.log(response.data);
              question_id = response.data.id;
              var random = {
                question_set_id: this.question,
                question_id: question_id,
                level: levels,
                status: 1,
                weight: 0,
                type: this.type,
              };
              RandomService.createrandom(random).then(() => {
                // console.log(res.data);
              });
            });
          }
        } else if (this.type == 3) {
          for (let i = 1; i < this.file.length; i++) {
            test = {};
            test = {
              question_set_id: this.question,
              no: this.file[i][0],
              question: this.file[i][1],
              CorrectAns1: this.file[i][2],
              CorrectAns2: this.file[i][3],
              CorrectAns3: this.file[i][4],
              CorrectAns4: this.file[i][5],
              CorrectAns5: this.file[i][6],
              CorrectAns6: this.file[i][7],
              CorrectAns7: this.file[i][8],
              CorrectAns8: this.file[i][9],
              CorrectAns9: this.file[i][10],
              CorrectAns10: this.file[i][11],
              CorrectAns11: this.file[i][12],
              CorrectAns12: this.file[i][13],
              CorrectAns13: this.file[i][14],
              CorrectAns14: this.file[i][15],
              CorrectAns15: this.file[i][16],
              type: this.type,
              index_id: i
            };
            QuestionSubject.createQuestionSubject(test).then((res) => {
              levelsname = this.file[i][this.file[i].length - 1]
              //  console.log(levelsname);
              if (levelsname == 'ปานกลาง') {
                levels = 2
              } else if (levelsname == 'ยาก') {
                levels = 3
              } else {
                levels = 1
              }
              // console.log(levels);
              // console.log(res.data);
              // console.log(res.data.id);
              question_id = res.data.id;
              var random = {
                question_set_id: this.question,
                question_id: question_id,
                level: levels,
                status: 1,
                weight: 0,
                type: this.type,
              };
              RandomService.createrandom(random).then(() => { });
            });
          }
        } else if (this.type == 2) {
          for (let i = 1; i < this.file.length; i++) {
            // console.log(this.file[i].length)
            // test = {};
            // imagefile = this.file[i][1].includes("Q");
            // if (imagefile) {
            //   ques = this.file[i][1].replace("(", "");
            //   ques = ques.replace(")", "");
            //   var index = ques.indexOf("Q");
            //   trimmedString = ques.substring(0, index);
            //   trimmedimage = ques.substring(index);
            //   // console.log(index);
            //   // console.log(ques);
            //   // console.log(trimmedString);
            //   // console.log(trimmedimage);
            // } else {
            //   trimmedString = this.file[i][1];
            //   trimmedimage = null;
            // }
            test = {
              question_set_id: this.question,
              no: this.file[i][0],
              question: this.file[i][1],
              image: null,
              CorrectAns: this.file[i][2],
              IncorrectAns1: this.file[i][3],
              IncorrectAns2: this.file[i][4],
              IncorrectAns3: this.file[i][5],
              IncorrectAns4: this.file[i][6],
              type: this.type,
              index_id: i
            };
            // console.log(test);
            QuestionObject.createQuestionObject(test).then((res) => {
              levelsname = this.file[i][this.file[i].length - 1]
              //  console.log(levelsname);
              if (levelsname == 'ปานกลาง') {
                levels = 2
              } else if (levelsname == 'ยาก') {
                levels = 3
              } else {
                levels = 1
              }
              // console.log(levels);
              // console.log(res.data.id);
              question_id = "";
              question_id = res.data.id;
              var random = {
                question_set_id: this.question,
                question_id: question_id,
                level: levels,
                status: 1,
                weight: 0,
                type: this.type,
              };
              RandomService.createrandom(random).then(() => { });
            });
          }
        }
        //  else if (this.format == 4) {
        //   for (let i = 1; i < this.file.length; i++) {
        //     test = {};
        //     trimmedString = "";
        //     trimmedimage = "";
        //     imagefile = "";
        //     ques = "";
        //     imagefile = this.file[i][1].includes("Q");
        //     if (imagefile) {
        //       ques = this.file[i][1].replace("(", "");
        //       ques = ques.replace(")", "");
        //       var index = ques.indexOf("Q");
        //       trimmedString = ques.substring(0, index);
        //       trimmedimage = ques.substring(index);
        //       // console.log(index);
        //       // console.log(ques);
        //       // console.log(trimmedString);
        //       // console.log(trimmedimage);
        //     } else {
        //       trimmedString = this.file[i][1];
        //       trimmedimage = null;
        //     }

        //     test = {
        //       question_set_id: this.question,
        //       no: this.file[i][0],
        //       image: trimmedimage,
        //       question: trimmedString,
        //       CorrectAns: this.file[i][2],
        //       IncorrectAns1: this.file[i][3],
        //       IncorrectAns2: this.file[i][4],
        //       IncorrectAns3: this.file[i][5],
        //       IncorrectAns4: this.file[i][6],
        //     };
        //     // console.log(test);
        //     QuestionObject.createQuestionObject(test).then((res) => {
        //       // console.log(res.data.id);
        //       question_id = "";
        //       question_id = res.data.id;
        //       var random = {
        //         question_set_id: this.question,
        //         question_id: question_id,
        //         level: 1,
        //         status: 1,
        //         weight:0
        //       };
        //       RandomService.createrandom(random).then(() => {});
        //     });
        //   }
        // } else if (this.format == 5) {
        //   for (let i = 1; i < this.file.length; i++) {
        //     test = {};
        //     test = {
        //       no: this.file[i][0],
        //       question_set_id: this.question,
        //       image: this.file[i][1],
        //       question: this.file[i][2],
        //       CorrectAns: this.file[i][3],
        //       IncorrectAns1: this.file[i][4],
        //       IncorrectAns2: this.file[i][5],
        //       IncorrectAns3: this.file[i][6],
        //       IncorrectAns4: this.file[i][7],
        //     };
        //     // console.log(test);
        //     QuestionObject.createQuestionObject(test).then((res) => {
        //       // console.log(res.data.id);
        //       question_id = "";
        //       question_id = res.data.id;
        //       var random = {
        //         question_set_id: this.question,
        //         question_id: question_id,
        //         level: 1,
        //         status: 1,
        //         weight:0
        //       };
        //       RandomService.createrandom(random).then(() => {});
        //     });
        //   }
        // }
        setTimeout(() => {
          this.file = [];
          document.getElementById("exampleFormControlFile1").value = null;
          alert("บันทึกสำเร็จ");
          this.getdata();
        }, 5000);
      } else {
        alert("กรุณาเลือกไฟล์ข้อมูล");
      }
    },
    gettype(evt) {
      this.loading = true
      this.list = []
      this.question = evt.target.value;
      QuestionSetService.getQuestionSet(this.question).then((res) => {
        // console.log(res.data);
        this.$router.push("/testall?id=" + this.question);
        this.format = res.data.format;
        this.getdata();
      });
      // console.log(this.question);
    },
    async getdata() {
      // console.log(this.format);
      // var lists = [];
      QuestionObject.gettestall(this.question).then(async (res) => {
        this.list = res.data;
        this.type = "";
        console.log(res.data);
        this.loading = false
      });
    },
    onChangeC1(event) {
      // var sheet = parseInt(this.question) + 1;
      // console.log(sheet);
      // readXlsxFile(event.target.files[0], { sheet: sheet }).then((data) => {
      //   this.file = data;
      // });
      readXlsxFile(event.target.files[0]).then((data) => {
        this.file = data;
      });
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}

.card {
  margin: 0 auto;
  /* Added */
  float: none;
  /* Added */
  margin-bottom: 10px;
  /* Added */
}

body {
  background-color: gray;
}
</style>
