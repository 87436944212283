import http from "../http-common";

class QuestionSetService {
  createQuestionSet(data) {
    return http.post(`/question_set`, data);
  }
  getQuestionSets() {
    return http.get(`/question_set`);
  }
  getQuestionSet(id) {
    return http.get(`/question_set/`+id);
  }
  updateQuestionSet(id, data) {
    return http.put(`/question_set/${id}`, data);
  }
  deleteQuestionSet(id, data) {
    return http.delete(`/question_set/${id}`, data);
  }
  getquestionsettestAll() {
    return http.get(`/question_set/getquestionsettestAll`);
  }
  updatequestionsettest(id, data) {
    return http.put(`/question_set/updatequestionsettest/${id}`, data);
  }
  setfalseteststatus() {
    return http.get(`/question_set/setfalseteststatus`);
  }
}

export default new QuestionSetService();