<template>
  <div class="container">
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <div class="card mt-5">
          <div class="card-body login-card-body mt-3">
              <div class="card-body mt-3">
               <div class="social-auth-links text-center mb-3 mt-3">
                  <button
                    @click="activate()"
                    type="button"
                    class="btn btn-success btn-sm"
                  >
                    ยืนยันตัวตน
                  </button>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class="col-md-3"></div>
    </div>
  </div>
</template>

<script>
import UserService from "../services/UserService.js";
export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      token: 0,
      menu: [],
      user: {},
      user_id:0
    };
  },
  mounted() {
   this.token = this.$route.params.id
  if (this.token) {
     this.activate()
  }
  },
  methods: {
    activate(){
      var data ={}
UserService.updateToken(this.token,data).then((res)=>{
  // console.log(res.data);
  if (res.data.id) {
    UserService.getUserBytoken(this.token).then((res)=>{
      // console.log(res.data);
       var user = {
          email: res.data.email,
          type:'token'
        };
        this.$store.dispatch("auth/login", user).then(
          () => {
            this.loading = true;
             this.$router.push('/test');
          },
          (error) => {
            alert("ชื่อหรือรหัสผ่านไม่ถูกต้อง");
            console.log(error);
            // this.loading = false;
            // this.message =
            //   (error.response &&
            //     error.response.data &&
            //     error.response.data.message) ||
            //   error.message ||
            //   error.toString();
          }
        );
  
    })
 }
})
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
</style>
