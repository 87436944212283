<template>
  <div class="container-fluid row mt-5" style="padding: 30px">
    <h5>ข้อที่ {{list[0].id}} <span v-html="list[0].question"></span></h5>
     <div class="col-md-12">
      <table class="table table-bordered">
        <thead>
          <tr style="text-align: center">
            <th scope="col" style="background-color: #92d050">ข้อที่</th>
            <th scope="col" style="background-color: #92d050">คำถาม</th>
            <th scope="col" style="background-color: #92d050">ชุดทดสอบ</th>
            <th scope="col" style="background-color: #92d050">
              จำนวนที่ตอบถูก
            </th>
            <th scope="col" style="background-color: #92d050">
              จำนวนผู้ทำข้อสอบ
            </th>
            <th scope="col" style="background-color: #92d050">เปอร์เซ็นต์</th>
            <th scope="col" style="background-color: #92d050">ระดับเดิม</th>
            <th scope="col" style="background-color: #92d050">ระดับใหม่</th>
            <th scope="col" style="background-color: #92d050;width: 150px;">แก้ไขระดับข้อสอบ</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="l in list" :key="l.id">
            <td scope="row">{{ l.id }}</td>
            <td scope="row">{{ l.question }}</td>
            <td scope="row">{{ l.question_set_name }}</td>
            <td scope="row">{{ l.weight }}</td>
            <td scope="row">{{ l.notraing }}</td>
            <td scope="row">{{ l.percent }}%</td>
            <td scope="row">{{ l.level }}</td>
            <td scope="row">{{ l.levelnews }}</td>
            <td scope="row">
              <button
                type="button"
                @click="getid(l)"
                class="btn btn-warning btn-sm"
                data-bs-toggle="modal"
                data-bs-target="#deletedata"
              >
                <i class="fa fa-edit"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="deletedata"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              ยืนยันการรีเซ็ตค่า
            </h5>
            <button
              id="closedeleteweight"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                value="1"
              />
              <label class="form-check-label" for="flexRadioDefault1">
                เปลี่ยน
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                value="0"
              />
              <label class="form-check-label" for="flexRadioDefault2">
                ไม่เปลี่ยน
              </label>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success btn-sm" @click="deletedata()">
              ยืนยัน
            </button>
            <button
              type="button"
              class="btn btn btn-secondary btn-sm"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardService from '../services/DashboardService'
import RandomService from "../services/RandomService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  components:{
  },
  data() {
    return {
     random_id:'',
     list:[],
     question: {},
    };
  },
  mounted() {
  this.random_id = this.$route.params.id
  this.getTest()
  },
  methods: {
    getid(id) {
      this.question = id;
    },
    deletedata() {
      if (document.querySelector('input[name="flexRadioDefault"]:checked')) {
        var check = document.querySelector(
          'input[name="flexRadioDefault"]:checked'
        ).value;

        var level = 1
            if (check == 1) {
level = this.question.levelnews_id
            }
              RandomService.resetweight( 
                  this.question.id,
                  level
                  ).then(()=>{
document.getElementById("closedeleteweight").click();
        this.$router.push('/dashboard');
                  })

      } else {
        alert("กรุณาเลือกค่า");
      }
    },
    getTest(){
      DashboardService.getAllLevelDashboard(this.random_id).then((res)=>{
        this.list = res.data
        console.log(this.list);
      })
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
