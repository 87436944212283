<template>
  <div class="container-fluid row mt-5" style="padding: 30px">
    <div>
      <div class="col-md-12">
        <label for="exampleInputEmail1" class="form-label"
          >เวลาในการทำข้อสอบ</label
        >
      </div>
      <div class="col-md-3 mb-3">
        <input
          v-model="time.time"
          class="form-control"
          type="number"
          id="flexRadioDefault1"
          min="0"
        />
      </div>
    </div>
    <div>
      <div class="col-md-12">
        <label for="exampleInputEmail1" class="form-label"
          >สถานะการนับเวลาทำข้อสอบ</label
        >
      </div>
      <div class="col-md-3 mb-3">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value="true"
            id="flexCheckChecked"
            v-model="time.status"
          />
          <label class="form-check-label" for="flexCheckChecked">
            แสดงเวลาในการทำข้อสอบ
          </label>
        </div>
      </div>
    </div>
    <div>
      <div class="col-md-12 mt-5">
        <label for="exampleInputEmail1" class="form-label">จำนวนข้อสอบทั้งหมด</label>
      </div>
      <div class="col-md-3 mb-3">
        <input
          v-model="question.no"
          @keyup="getData"
          class="form-control"
          type="number"
          id="flexRadioDefault1"
          min="0"
        />
      </div>
      <div class="col-md-12">
        <label for="exampleInputEmail1" class="form-label">ชุดทดสอบ</label>
      </div>
       <div class="col-md-3 mb-3" v-for="t in set" :key="t.id">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value="true"
            :id="t.question_name+t.id"
            v-model="t.test_status"
            @change="gettest"
          />
          <label class="form-check-label" :for="t.question_name+t.id">
            {{ t.question_name }}
          </label>
        </div>
      </div>      
    </div>
    <div>
      <div class="row">
        <div class="col-4" v-for="l in levels" :key="l.id"><div class="col-md-12 mt-3">
        <label for="exampleInputEmail1" class="form-label"
          >ระดับข้อสอบ{{ l.name }}</label
        >
      </div>
      <div class="col-md-12">
        <label for="exampleInputEmail1" class="form-label">จำนวนข้อสอบ</label>
      </div>
      <div class="col-md-6 mb-3">
        <input
          v-model="l.no"
          class="form-control"
          type="number"
          id="flexRadioDefault1"
          :max="l.allcount"
        />
      </div>
      มีทั้งหมด {{ l.allcount }} ข้อ
      <div class="col-md-12 mb-3" v-for="t in l.type" :key="t.id">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value="true"
            :id="t.name+l.id"
            v-model="t.status"
          />
          <label class="form-check-label" :for="t.name+l.id">
            {{ t.name }} ({{ t.count }} ข้อ)
          </label>
        </div>
      </div> </div>
      </div>
           
    </div>
    <!-- <div class="col-md-12 mt-3">
      <label for="exampleInputEmail1" class="form-label">จำนวนคนทำข้อสอบเพื่อใช้ในการปรับระดับความยาก ปานกลาง ง่ายของข้อสอบ</label>
    </div>
    <div class="col-md-3 mb-3">
      <input
        v-model="train.no_test"
        class="form-control"
        type="number"
        id="flexRadioDefault1"
        min="0"
      />
    </div> -->
    <div class="text-left mb-3 mt-3">
      <button @click="save()" type="button" class="btn btn-success btn-sm">
        บันทึก
      </button>
    </div>
  </div>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/vue/2.5.2/vue.min.js"></script>

<script>
import LevelService from "../services/LevelService";
import ManageTest from "../services/ManageTest";
import ManageTime from "../services/ManageTime";
import ManageTraining from "../services/ManageTraining";
import MapTypeLevelService from "../services/MapTypeLevelService";
import QuestionSetService from "../services/QuestionSetService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      list: [],
      question: {},
      levels: [],
      time: {},
      train: {},
      set:[]
    };
  },
  mounted() {
    this.getmanagetest();
    this.getmanagetimes();
    this.getlevels();
    this.getmanagettrain();
    this.getprototypetest()
  },
  methods: {
    gettest(){
        for (let s = 0; s < this.set.length; s++) {
          // console.log(this.set[s].test_status);
              var sets={
                test_status:this.set[s].test_status
              }
              QuestionSetService.updatequestionsettest(this.set[s].id,sets).then(()=>{
                
                this.getmanagetest();
    this.getmanagetimes();
    this.getlevels();
    this.getmanagettrain();
    this.getprototypetest()
              })
          }
    },
    getData(evt){
// console.log(evt.target.value);
var v = evt.target.value
// console.log(v.length);
if (v.length > 1 && v.length <= 3) {
  // console.log(v);
  var easy = Math.floor((20*v)/100)
  var hard = Math.floor((30*v)/100)
  var medium = v - (easy+hard)
  // console.log(Math.floor(easy));
  // console.log(Math.floor(hard))
  // console.log(medium);
  for (let i = 0; i < this.levels.length; i++) {
    if (this.levels[i].id == 1) {
      this.levels[i].no = easy
      
    } else if (this.levels[i].id == 2) {
      this.levels[i].no = medium
      
    } else if (this.levels[i].id == 3) {
      this.levels[i].no = hard
      
    }    
  }
}
    },
    getprototypetest() {
      QuestionSetService.getquestionsettestAll().then((res) => {
        // console.log(res.data);
        this.set = res.data;
      });
    },
    getmanagetest() {
      ManageTest.getManageTest().then((res) => {
        // console.log(res.data);
        this.question = res.data;
      });
    },
    getmanagetimes() {
      ManageTime.getManageTime().then((res) => {
        // console.log(res.data);
        this.time = res.data;
      });
    },
    getmanagettrain() {
      ManageTraining.getManageTraining().then((res) => {
        // console.log(res.data);
        this.train = res.data[0];
      });
    },
    save() {
      let sumno = 0;
      for (let mm = 0; mm < this.levels.length; mm++) {
        sumno = sumno + parseInt(this.levels[mm].no);
      }
      // console.log(sumno);
      if (sumno != this.question.no) {
        alert("กรุณากรอกจำนวนให้ถูกต้อง");
      } else {
        //         var trains ={
        //           no_test : this.train.no_test
        //         }
        //         console.log(trains);
        //         ManageTraining.updateManageNoTraining(this.train.id,trains).then(()=>{
        // // console.log(res.data);
        //         })
        var times = {
          time: this.time.time,
          status: this.time.status,
        };
        // console.log(times);
        
          
           MapTypeLevelService.deleteMap().then(()=>{

           })
          //  QuestionSetService.setfalseteststatus().then(()=>{

          //  })
        ManageTime.updateManageTime(this.time.id, times).then(() => {});
        for (let m = 0; m < this.levels.length; m++) {
          var data = {
            level_id: this.levels[m].level_id,
            no: this.levels[m].no,
          };
          if (this.levels[m].id == null) {
            ManageTest.createManageTest(data).then(() => {
              // console.log(res.data);
            });
          } else {
            ManageTest.updateManageTest(this.levels[m].id, data).then(() => {
              // console.log(res.data);
            });
          }
          for (let t = 0; t < this.levels[m].type.length; t++) {
            if (this.levels[m].type[t].status == true) {
              var maps={
                manage_test_id:this.levels[m].id,
                type:this.levels[m].type[t].id
              }
              MapTypeLevelService.createMap(maps).then((res)=>{
                console.log(res.data);
              })
            }
            
          }
        }
        for (let s = 0; s < this.set.length; s++) {
          // console.log(this.set[s].test_status);
              var sets={
                test_status:this.set[s].test_status
              }
              QuestionSetService.updatequestionsettest(this.set[s].id,sets).then(()=>{
                // console.log(res.data);
              })
          }
        alert("บันทึกสำเร็จ");
      }
    },
    getlevels() {
      LevelService.getlevelTest().then((res)=> {
        this.levels = res.data;
        // console.log(this.levels);
      });
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
</style>
