<template>
  <div class="container row">
    <div class="card mt-5 col-md-12" style="width: 90%">
      <div class="mt-3 mb-5">
        <div v-for="(l, i) in list" :key="l.id">
          <label class="mb-3 mt-3">{{ i + 1 }}. {{ l.questionname }}</label>
          <div class="row">
          <div class="col-md-6" v-for="(a, i) in l.key" :key="i">
              <div class="form-check"> 
                <input
              class="form-check-input"
              type="radio"
              :name="l.id"
              :id="a.id"
            />
            <img v-if="checkq(a.answer)" :src="link+'/uploads/'+a.answer" style="width:30%"/>
            <label class="form-check-label" :for="a.id" v-else>
              {{ a.answer }}
            </label></div>
            </div> 
            </div>        
        </div>
      </div>
      <!-- <div class="social-auth-links text-center mb-3 mt-3">
        <button @click="save()" type="button" class="btn btn-success btn-sm">
          บันทึก
        </button>
      </div> -->
    </div>
  </div>
</template>

<script>
import SubjectTestService from "../services/SubjectTestService.js";
import LinkService from "../services/LinkService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      list: [],
      status:false,
      link:''
    };
  },
  mounted() {
    this.getlist();
    this.link = LinkService.getLink()
  },
  methods: {
    checkq(data){
      if (data) {
        return data.includes('Q')
      }else{
      return false
      }
    },
    getlist() {
      SubjectTestService.getobjectivetests(5).then((res) => {
        this.list = res.data;
        // console.log(res.data);
      });
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
</style>
