import http from "../http-common";

class AnswerTempTrainingService {
  updateAnswerTemp(id, data) {
    return http.put(`/answer_temp_training/${id}`, data);
  }
  createAnswerTemp(data) {
    return http.post(`/answer_temp_training`, data);
  }
  getAnswerTemps(student_id,test_no) {
    return http.get('/answer_temp_training?student_id='+student_id+'&&test_no='+test_no);
  }
  getTimeAnswerTemps(student_id) {
    return http.get('/answer_temp_training/gettime/'+student_id);
  }
  DeleteAnswerTemps(student_id) {
    return http.delete('/answer_temp_training/'+student_id);
  }
}

export default new AnswerTempTrainingService();