<template>
  <div class="container row">
    <div class="col mb-3 mt-3" style="text-align: right">
      <a>
        <button
          type="button"
          id="get_file"
          class="btn btn-success btn-sm"
          @click="getid(0)"
          data-bs-toggle="modal"
          data-bs-target="#AddUser"
        >
          <i class="fa fa-plus"></i> เพิ่มผู้ใช้งาน
        </button></a
      >&nbsp;
      <!-- <a>
        <button
          type="button"
          class="btn btn-success btn-sm"
        @click="chooseFiles()"
        >
          <i class="fa fa-file-excel-o"></i> นำเข้าข้อมูลผู้ใช้งาน
        </button></a
      >
         <input
        type="file"
        class="form-control-file"
        id="fileUpload"
        accept=".xlsx, .xls, .csv"
        @change="onChangeFile"
        hidden
      /> -->
    </div>
    <div class="col-md-12 mb-3 mt-3" style="margin: 15px;">
    <table class="table table-bordered">
      <thead>
        <tr class="table-active">
          <th scope="col">ลำดับที่</th>
          <th scope="col">ชื่อ-นามสกุล</th>
          <th scope="col">อีเมล</th>
          <th scope="col">กลุ่มผู้ใช้งาน</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(l, i) in list" :key="i">
          <td>
            {{ i + 1 }}
          </td>
          <td>
            {{ l.firstname }} {{ l.lastname }}
          </td>
          <td>{{ l.email }}</td>
           <td>
            {{ l.name }}
          </td>
          <td>
            <a @click="getid(l.id)">
              <button
                type="button"
                class="btn btn-warning btn-sm"
                data-bs-toggle="modal"
                data-bs-target="#AddUser"
              >
                <i class="fa fa-edit"></i></button
            ></a>&nbsp;
            <a @click="deletedata(l.id)">
              <button
                type="button"
                class="btn btn-danger btn-sm"
                data-bs-toggle="modal"
                data-bs-target="#DeleteStatus"
              >
                <i class="fa fa-trash"></i></button
            ></a>
          </td>
        </tr>
      </tbody>
    </table>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="AddUser"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
            <button
              id="closeduser"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form>
              <div class="card-body mt-3">
                <div class="form-group">
                  <label for="username">ชื่อ</label>
                  <input
                    v-model="user.firstname"
                    type="text"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                  />
                </div>
                <div class="form-group mt-3">
                  <label for="username">นามสกุล</label>
                  <input
                    v-model="user.lastname"
                    type="text"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                  />
                </div>
                <div class="form-group mt-3">
                  <label for="username">อีเมล</label>
                  <input
                    v-model="user.email"
                    type="email"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                  />
                </div>
                <div class="form-group mt-3">
                  <label for="password">รหัสผ่าน</label>
                  <input
                    v-model="user.password"
                    type="password"
                    class="form-control form-control-sm"
                    id="password"
                  />
                </div>
                <div class="form-group mt-3">
          <label for="exampleFormControlSelect1">กลุ่มผู้ใช้งาน</label>
          <select
            v-model="user.role"
            class="form-select form-control-sm"
            id="exampleFormControlSelect1"
          >
            <option v-for="(s, i) in roles" v-bind:value="s.id" :key="i + 1">
              {{ s.name }}
            </option>
          </select>
        </div>
              </div>
            </form>
          </div>
          <div class="modal-footer mt-3">
            <button type="button" class="btn btn-success btn-sm" @click="save()">
              บันทึก
            </button>
            <button
              type="button"
              class="btn btn btn-secondary btn-sm"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="DeleteStatus"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">ยืนยันการลบข้อมูล</h5>
          </div>
          <div class="modal-footer mt-3">
            <button type="button" class="btn btn-danger btn-sm" @click="deletebyid()">
              <label>ลบข้อมูล</label>
            </button>
            <button
            id="closeddeleteuser"
              type="button"
              class="btn btn-secondary  btn-sm"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "../services/UserService";
import readXlsxFile from "read-excel-file";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      concert_id: 0,
      list: [],
      user: {},
      user_id: 0,
      hash: 0,
      title: "",
      roles:[],
      delete_id:0,
    };
  },
  mounted() {
    this.getUsers();
    this.getRoles()
  },
  methods: {
    onChangeFile(event) {
      readXlsxFile(event.target.files[0]).then((data) => {
        // this.file = data;
        console.log(data);
        for (let d = 1; d < data.length; d++) {
          // console.log(data[d]);
          
        UserService.getUsers(data[d][2]).then((res)=>{
          // console.log(res.data);
          if (res.data.length == 0) {
            var userdata = {
          firstname: data[d][0],
          lastname: data[d][1],
          email: data[d][2],
          password: String(data[d][3]),
          role: data[d][4],
          active:1,
          token:''
        };
        console.log(userdata);
          UserService.createUser(userdata).then(() => {
            // console.log(data.length, d);
            if (data.length == d+2) {
              alert('บันทึกเรียบร้อย')
              location.reload();
            }
          });
          }
          else{
            alert('อีเมล '+data[d][2]+' มีในระบบแล้ว')
            if (data.length == d+2) {
              alert('บันทึกเรียบร้อย')
              location.reload();
            }
          }
          });
        }
      });
    },
    chooseFiles() {
        document.getElementById("fileUpload").click()
    },
    deletebyid(){
      UserService.deleteUser(this.delete_id).then(() => {
        alert('ลบข้อมูลเรียบร้อย')
        document.getElementById("closeddeleteuser").click();
        this.getRoles()
    this.getUsers();
      });
    },
    deletedata(id){
      this.delete_id = id;
    },
     getRoles() {
      UserService.getRolesAll().then((res) => {
        // console.log(res.data);
        this.roles = res.data;
      });
    },
    getid(id) {
      this.user_id = id;
      if (this.user_id != 0) {
        this.title = "แก้ไขข้อมูลผู้ใช้งาน";
        UserService.getUser(this.user_id).then((res) => {
          this.user = res.data;
          this.hash = this.user.password;
        });
      } else {
        this.title = "เพิ่มข้อมูลผู้ใช้งาน";
        this.user = [];
      }
    },
    save() {
      /* eslint-disable no-useless-escape */
      console.log(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(this.user.email));
      if (this.user.firstname == null) {
        alert("กรุณากรอกชื่อ");
      } else if (this.user.lastname == null) {
        alert("กรุณากรอกนามสกุล");
      } else if (this.user.email == null) {
        alert("กรุณากรอกอีเมล");
        /* eslint-disable no-useless-escape */
      }else if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(this.user.email) == false) {
        alert ('กรุณากรอกอีเมลให้ถูกต้อง');
      }
       else if (this.user.password == null) {
        alert("กรุณากรอกรหัสผ่าน");
      } else if (this.user.role == null) {
        alert("กรุณาเลือกกลุ่มผู้ใช้งาน");
      } else {
       
        var userdata = {
          firstname: this.user.firstname,
          lastname: this.user.lastname,
          email: this.user.email,
          password: this.user.password,
          role: this.user.role,
          hash: this.hash,
          active:1,
          token:''
        };
        if (this.user_id == 0) {
           UserService.getUsers(this.user.email).then((res)=>{
          console.log(res.data);
          if (res.data.length == 0) {
          UserService.createUser(userdata).then(() => {
            document.getElementById("closeduser").click();
            this.getUsers();
          });
          }
          else{
            alert('อีเมลนี้มีในระบบแล้ว')
          }
          });
        } else {
          UserService.updateUser(this.user_id, userdata).then(() => {
            // console.log(res.data);
            document.getElementById("closeduser").click();
            this.getUsers();
            //       setTimeout(function () {
            //   location.reload();
            // }, 500);
            // window.scrollTo(0, 0);
          });
        }
      }
    },
    getUsers() {
      UserService.getUsers('').then((res) => {
        this.list = res.data;
        // console.log(res.data);
      });
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
</style>
