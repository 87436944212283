<template>
  <div class="container-fluid row" style="padding: 30px">
    <!-- <div class="col-md-12 mt-5">
      <label for="exampleInputEmail1" class="form-label"
        >ข้อสอบทั้งหมด {{ list.length }} ข้อ</label
      >
    </div>
    <div class="col-md-12">
      <label for="exampleInputEmail1" class="form-label">ข้อที่</label>
    </div>
    <div class="col-md-3 mb-3">
      <input
        v-model="question.from_no"
        class="form-control"
        type="number"
        id="flexRadioDefault1"
        min="0"
        :max="list.length"
      />
    </div>
    <div class="col-md-12">
      <label for="exampleInputEmail1" class="form-label">ถึง</label>
    </div>
    <div class="col-md-3 mb-3">
      <input
        v-model="question.to_no"
        class="form-control"
        type="number"
        id="flexRadioDefault1"
        min="0"
        :max="list.length"
      />
    </div> -->
    <div class="col-md-12 mt-5">
      <label for="exampleInputEmail1" class="form-label">จำนวนคนทำข้อสอบเพื่อใช้ในการปรับระดับความยาก ปานกลาง ง่ายของข้อสอบ</label>
    </div>
    <div class="col-md-3 mb-3">
      <input
        v-model="question.no_train"
        class="form-control"
        type="number"
        id="flexRadioDefault1"
        min="0"
      />
    </div>
    <div class="text-left mb-3 mt-3">
      <button @click="save()" type="button" class="btn btn-success btn-sm">
        บันทึก
      </button>
    </div>
  </div>
</template>

<script>
import RandomService from "../services/RandomService";
import ManageTraining from "../services/ManageTraining";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      list: [],
      question: {},
    };
  },
  mounted() {
    this.getrandom();
  },
  methods: {
    save() {
      var data = {
        from_no: this.question.from_no,
        to_no: this.question.to_no,
        no_train:this.question.no_train,
      };
      if (this.question.id == null) {
        ManageTraining.createManageTraining(data).then(() => {
          // console.log(res.data);
          alert('บันทึกสำเร็จ')
        });
      } else {
        ManageTraining.updateManageTraining(this.question.id, data).then(
          () => {
            // console.log(res.data);
            alert('บันทึกสำเร็จ')
          }
        );
      }
    },
    getrandom() {
      this.list = [];
      RandomService.getRandom(20).then((res) => {
        // console.log(res.data);
        this.list = res.data;
      });
      ManageTraining.getManageTraining().then((res) => {
        // console.log(res.data);
        if (res.data.length > 0) {
        this.question = res.data[0];          
        }
      });
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
</style>
