<template>
  <div class="container-fluid row">
    <div class="col-md-12" style="margin-top:80px">
      <table class="table table-bordered">
        <thead>
          <tr style="text-align: center">
            <th scope="col" style="background-color: #92d050">ข้อที่</th>
            <th scope="col" style="background-color: #92d050">คำถาม</th>
            <th scope="col" style="background-color: #92d050">ชุดทดสอบ</th>
            <th scope="col" style="background-color: #92d050">
              จำนวนที่ตอบถูก
            </th>
            <th scope="col" style="background-color: #92d050">
              จำนวนผู้ทำข้อสอบ
            </th>
            <th scope="col" style="background-color: #92d050">เปอร์เซ็นต์</th>
            <th scope="col" style="background-color: #92d050">ระดับเดิม</th>
            <th scope="col" style="background-color: #92d050">ระดับใหม่</th>
            <th scope="col" style="background-color: #92d050;width: 150px;">แก้ไขระดับข้อสอบ</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="l in list" :key="l.id">
            <td scope="row">{{ l.id }}</td>
            <td style="width:40%">
              <span v-html="l.question"></span>
              <!-- {{ l.question }} -->
            </td>
            <td scope="row">{{ l.question_set_name }}</td>
            <td scope="row">{{ l.weight }}</td>
            <td scope="row">{{ l.notraing }}</td>
            <td scope="row">{{ l.percent.toFixed(2) }}%</td>
            <td scope="row">{{ l.level }}</td>
            <td scope="row">{{ l.levelnews }}</td>
            <td scope="row">
              <button
                type="button"
                @click="getid(l)"
                class="btn btn-warning btn-sm"
                data-bs-toggle="modal"
                data-bs-target="#deletedata"
              >
                <i class="fa fa-edit"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="deletedata"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              ยืนยันการรีเซ็ตค่า
            </h5>
            <button
              id="closedeletedoc"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                value="1"
              />
              <label class="form-check-label" for="flexRadioDefault1">
                เปลี่ยน
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                value="0"
              />
              <label class="form-check-label" for="flexRadioDefault2">
                ไม่เปลี่ยน
              </label>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success btn-sm" @click="deletedata()">
              ยืนยัน
            </button>
            <button
              type="button"
              class="btn btn btn-secondary btn-sm"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RandomService from "../services/RandomService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      list: [],
      question: {},
    };
  },
  mounted() {
    this.getdata();
  },
  methods: {
    getid(id) {
      this.question = id;
      console.log(this.question);
    },
    deletedata() {
      if (document.querySelector('input[name="flexRadioDefault"]:checked')) {
        var check = document.querySelector(
          'input[name="flexRadioDefault"]:checked'
        ).value;
            // console.log(res.data);
            var level = 1
            if (check == 1) {
level = this.question.levelnews_id
            }
              RandomService.resetweight( 
                  this.question.id,
                  level
                  ).then(()=>{
 document.getElementById("closedeletedoc").click();
              this.getdata();
                  })
      } else {
        alert("กรุณาเลือกค่า");
      }
    },
    getdata() {
      this.list = [];
      RandomService.getweight(0).then((res) => {
        this.list = res.data;
        console.log(res.data);
      });
      // ManageTraining.getManageTraining().then((res)=>{
      //   // console.log(res.data[0].no_train);
      //   var no_train = res.data[0].no_train
      //   RandomService.getweight(0).then((res)=>{
      //   console.log(res.data);
      //   for (let t = 0; t < res.data.length; t++) {
      //     if (res.data[t].notraing == no_train) {
      //   this.list.push(res.data[t])
      //     }
      //   }
      // })
      // })
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
</style>
