<template>
  <div class="container-fluid row mt-5" style="padding: 30px">
    <h5 v-if="list.length > 0">ข้อที่ {{ list[0].id }} <span v-html="list[0].question"></span></h5>
     
       <div class="col-md-12">
        <div>คำตอบที่ถูกต้อง</div>
      <table class="table table-bordered">
        <thead>
          <tr style="text-align: center">
            <!-- <th scope="col" style="background-color: #92d050">ข้อที่</th>
            <th scope="col" style="background-color: #92d050">คำถาม</th> -->
            <th scope="col" v-for="(c,i) in list[0].answercorrect" :key="i+2"><span v-html="c"> </span></th>
          </tr>
        </thead>
      </table>
    </div>
    <div class="col-md-12 mt-5">
      <table class="table table-bordered">
        <thead>
          <tr style="text-align: center">
            <!-- <th scope="col" style="background-color: #92d050">ข้อที่</th>
            <th scope="col" style="background-color: #92d050">คำถาม</th> -->
            <th scope="col" style="background-color: #92d050">ชุดทดสอบ</th>
            <th scope="col" style="background-color: #92d050">
              จำนวนที่ตอบถูก
            </th>
            <th scope="col" style="background-color: #92d050">
              จำนวนผู้ทำข้อสอบ
            </th>
            <th scope="col" style="background-color: #92d050">เปอร์เซ็นต์</th>
            <!-- <th scope="col" style="background-color: #92d050">ระดับเดิม</th>
            <th scope="col" style="background-color: #92d050">ระดับใหม่</th> -->
            <!-- <th scope="col" style="background-color: #92d050"></th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="(l,q) in list" :key="q+2">
            <!-- <td scope="row">{{ l.id }}</td>
            <td scope="row">{{ l.question }}</td> -->
            <td scope="row">{{ l.question_set_name }}</td>
            <td scope="row" style="text-align:right">{{ l.weight }}</td>
            <td scope="row" style="text-align:right">{{ l.notraing }}</td>
            <td scope="row" style="text-align:right">{{ l.percent.toFixed(2) }}%</td>
            <!-- <td scope="row">{{ l.level }}</td>
            <td scope="row">{{ l.levelnews }}</td> -->
            <!-- <td scope="row">
              <button
                type="button"
                @click="getid(l)"
                class="btn btn-success btn-sm"
                data-bs-toggle="modal"
                data-bs-target="#deletedata"
              >
                <i class="fa fa-check"></i>
              </button>
            </td> -->
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-md-12 mt-5" v-if="list.length > 0 && list[0].answer.length > 0">
      <table class="table table-bordered">
        <thead>
          <tr style="text-align: center">
            <!-- <th scope="col" style="background-color: #92d050">ข้อที่</th>
            <th scope="col" style="background-color: #92d050">คำถาม</th> -->
            <th scope="col" style="background-color: #92d050">คำตอบที่ตอบถูก</th>
            <th scope="col" style="background-color: #92d050">จำนวนคนที่ตอบถูก</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(l,i) in list[0].answer" :key="i+2">
            <!-- <td scope="row">{{ l.id }}</td>
            <td scope="row">{{ l.question }}</td> -->
            <td scope="row"><span v-html="l.answer"> </span></td>
            <td scope="row" >{{l.point}}</td>
          </tr>
        </tbody>
      </table>
    </div>
     <div class="col-md-12 mt-5" v-if="list.length > 0 && list[0].wronganswer.length > 0">
      <table class="table table-bordered">
        <thead>
          <tr style="text-align: center">
            <!-- <th scope="col" style="background-color: #92d050">ข้อที่</th>
            <th scope="col" style="background-color: #92d050">คำถาม</th> -->
            <th scope="col" style="background-color: #D05069">คำตอบที่ตอบผิด</th>
            <th scope="col" style="background-color: #D05069">จำนวนคนที่ตอบผิด</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(l,w) in list[0].wronganswer" :key="w+5">
            <!-- <td scope="row">{{ l.id }}</td>
            <td scope="row">{{ l.question }}</td> -->
            <td scope="row"><span v-html="l.answer"> </span></td>
            <td scope="row">{{l.point}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="deletedata"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              ยืนยันการรีเซ็ตค่า
            </h5>
            <button
              id="closedeleteweight"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                value="1"
              />
              <label class="form-check-label" for="flexRadioDefault1">
                เปลี่ยน
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                value="0"
              />
              <label class="form-check-label" for="flexRadioDefault2">
                ไม่เปลี่ยน
              </label>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-success btn-sm"
              @click="deletedata()"
            >
              ยืนยัน
            </button>
            <button
              type="button"
              class="btn btn btn-secondary btn-sm"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardService from "../services/DashboardService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  components: {},
  data() {
    return {
      random_id: "",
      list: [],
      question: {},
    };
  },
  mounted() {
    this.random_id = this.$route.params.id;
    this.getTest();
  },
  methods: {
    getTest() {
      // console.log(this.random_id);
      DashboardService.getDetailTypeDashboard(this.random_id).then((res) => {
        this.list = res.data;
        console.log(res.data);
      });
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
