<template>
  <div class="container">
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <div class="card mt-5">
          <div class="card-body login-card-body mt-3">
            <h3 class="login-box-msg" style="text-align: center">
              เข้าสู่ระบบ
            </h3>
            <form>
              <div class="card-body mt-3">
                <div class="form-group">
                  <label for="username">อีเมล/ชื่อผู้ใช้งาน</label>
                  <input v-model="user.email" v-on:keyup.enter="signIn()" type="text" min="1"
                    class="form-control form-control-sm" id="username" />
                </div>
                <div class="form-group mt-3">
                  <label for="password">รหัสผ่าน</label>
                  <input v-model="user.password" v-on:keyup.enter="signIn()" type="password"
                    class="form-control form-control-sm" id="password" />
                </div>
                <div class="row mt-3">
                  <div class="col-md-12"><span style="color:red">* </span>UP account ไม่ต้องสมัครสมาชิก <br/>&nbsp;&nbsp;&nbsp;กรณีที่ไม่มี UP account ให้สมัครสมาชิกด้วย Gmail</div>
                  
                  <div class="col-md-3"></div>
                  <div class="col-md-6 text-center"> <a href="/register"><button type="button"
                        class="btn btn-warning btn-sm">
                        สมัครสมาชิก
                      </button></a>&nbsp;&nbsp;
                    <button @click="signIn()" type="button" class="btn btn-success btn-sm">
                      เข้าสู่ระบบ
                    </button>
                  </div>
                  <div class="col-md-3"></div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-3"></div>
    </div>
  </div>
</template>

<script>
import UserService from "../services/UserService.js";
import axios from "axios";
import TeacherService from "../services/TeacherService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      concert_id: 0,
      menu: [],
      user: {},
    };
  },
  mounted() {

    UserService.getMenuByRole(this.currentUser.role_id).then((res) => {
      // console.log(res.data);
      this.$router.push(res.data[0].url);
    });
  },
  methods: {
    texttobase(string) {
      // Encode the String
      var encodedString = btoa(string);
      return encodedString;
    },
    login() {
      var user = {
        email: this.user.email,
        password: this.user.password,
      };
      this.$store.dispatch("auth/login", user).then(
        () => {
          this.loading = true;
          UserService.getMenuByRole(this.currentUser.role_id).then((res) => {
            console.log(res.data[0]);
            this.$router.push(res.data[0].url);
            setTimeout(function () {
              location.reload();
            }, 500);
          });
        },
        (error) => {
          alert("ชื่อหรือรหัสผ่านไม่ถูกต้อง");
          console.log(error);
          // this.loading = false;
          // this.message =
          //   (error.response &&
          //     error.response.data &&
          //     error.response.data.message) ||
          //   error.message ||
          //   error.toString();
        }
      );
    },
    getdatastu() {
      var username = this.texttobase(this.user.email);
      var password = this.texttobase(this.user.password);
      // var username = this.texttobase("64024815");
      // var password = this.texttobase("Oum@240662");
      axios
        .get(
          "https://ws.up.ac.th/mobile/AuthenService.asmx/Login?username=" +
          username +
          "&password=" +
          password +
          "&platformName=&deviceType=&ProductName=&ServiceUser=&ServicePass="
        )
        .then((res) => {
          console.log(res.data);
          var parser, xmlDoc;
          parser = new DOMParser();
          xmlDoc = parser.parseFromString(res.data, "text/xml");


          console.log(id);
          if (xmlDoc.getElementsByTagName("string")[0].childNodes.length == 0) {
            alert("อีเมล/ชื่อผู้ใช้งาน หรือรหัสผ่านไม่ถูกต้อง");
          } else {
            var id =
              xmlDoc.getElementsByTagName("string")[0].childNodes[0].nodeValue;
            axios
              .get(
                "https://ws.up.ac.th/mobile/StudentService.asmx/GetStudentInfo?sessionID=" + id).then((res) => {
                  var parser, xmlDoc
                  parser = new DOMParser();
                  xmlDoc = parser.parseFromString(res.data, "text/xml");
                  console.log(xmlDoc);
                  var StudentCode =
                    xmlDoc.getElementsByTagName("StudentCode")[0].childNodes[0]
                      .nodeValue;
                  var FirstName_TH =
                    xmlDoc.getElementsByTagName("FirstName_TH")[0].childNodes[0]
                      .nodeValue;
                  var LastName_TH =
                    xmlDoc.getElementsByTagName("LastName_TH")[0].childNodes[0]
                      .nodeValue;
                  // var ProgramName_TH =
                  //   xmlDoc.getElementsByTagName("ProgramName_TH")[0].childNodes[0]
                  //     .nodeValue;
                  console.log(StudentCode, FirstName_TH, LastName_TH);
                  UserService.getUsers(StudentCode).then((res) => {
                    console.log(res.data);
                    var users = {
                      firstname: FirstName_TH,
                      lastname: LastName_TH,
                      email: StudentCode,
                      password: this.user.password,
                      role: 3,
                      active: 0,
                      token: "",
                    };
                    console.log(res.data.length);
                    if (res.data.length == 0) {
                      UserService.createUser(users).then((res) => {
                        console.log(res.data);
                        this.login();
                      });
                    } else {
                      console.log(1);
                      UserService.updateUser(res.data[0].id, users).then(() => {
                        // console.log(res.data);
                        this.login();
                      });
                    }
                  });
                })
          }
        });


      // .catch(function (error) {
      //   console.log(error);
      //   alert("อีเมล/ชื่อผู้ใช้งาน หรือรหัสผ่านไม่ถูกต้อง");
      // });
    },
    getdatatea() {

      var username = this.texttobase(this.user.email);
      var password = this.texttobase(this.user.password);
      // var username = this.texttobase("sakkayaphop.pr");
      // var password = this.texttobase("l;ylfu8iy[,rp");
      console.log(username, password);
      // "https://ws.up.ac.th/mobile/AuthenService.asmx/Login?username=c2Fra2F5YXBob3AucHI="+
      //       "&password=bDt5bGZ1OGl5WyxycA==" +
      //       "&platformName=&deviceType=&ProductName=&ServiceUser=&ServicePass="
      axios
        .get(
          "https://ws.up.ac.th/mobile/AuthenService.asmx/Login?username=" +
          username +
          "&password=" +
          password +
          "&platformName=&deviceType=&ProductName=&ServiceUser=&ServicePass="
        )
        .then((res) => {
          console.log(res.data);
          var parser, xmlDoc;
          parser = new DOMParser();
          xmlDoc = parser.parseFromString(res.data, "text/xml");

          // var ch = xmlDoc.find('')
          console.log(xmlDoc.getElementsByTagName("string")[0].childNodes.length);

          if (xmlDoc.getElementsByTagName("string")[0].childNodes.length == 0) {
            alert("ชื่อผู้ใช้งาน หรือรหัสผ่านไม่ถูกต้อง / ไม่สามารถล็อกอินได้");
          } else {
            var id =
              xmlDoc.getElementsByTagName("string")[0].childNodes[0].nodeValue;
            console.log(id);
            axios
              .get(
                "https://ws.up.ac.th/mobile/StaffService.asmx/GetStaffInfo?sessionID=" + id).then((res) => {
                  var parser, xmlDoc
                  parser = new DOMParser();
                  xmlDoc = parser.parseFromString(res.data, "text/xml");
                  console.log(xmlDoc);
                  var FirstName_TH =
                    xmlDoc.getElementsByTagName("FirstName_TH")[0].childNodes[0]
                      .nodeValue;
                  var LastName_TH =
                    xmlDoc.getElementsByTagName("LastName_TH")[0].childNodes[0]
                      .nodeValue;
                  // var FirstName_TH = 'sakkayaphop'
                  // var LastName_TH = 'pravesjit'
                  console.log(FirstName_TH, LastName_TH);
                  TeacherService.getteacherss(FirstName_TH, LastName_TH).then((res) => {
                    console.log(res.data[0]);
                    // var tea = res.data[0]
                    var users = {
                      firstname: FirstName_TH,
                      lastname: LastName_TH,
                      email: this.user.email,
                      password: this.user.password,
                      role: 3,
                      active: 0,
                      token: "",
                    };
                    console.log(res.data.length);
                    if (res.data.length == 0) {
                      UserService.createUser(users).then((res) => {
                        console.log(res.data);
                        this.login();
                      });
                    } else if (res.data[0].status == true) {
                      UserService.getUsers(this.user.email).then((teachs) => {
                        if (teachs.data.length == 0) {
                          var teach = {
                            firstname: FirstName_TH,
                            lastname: LastName_TH,
                            email: this.user.email,
                            password: this.user.password,
                            role: res.data[0].role_id,
                            active: 0,
                            token: "",
                          };
                          UserService.createUser(teach).then((res) => {
                            console.log(res.data);
                            this.login();
                          });
                        } else {
                          UserService.getUsers(this.user.email).then((teachs) => {
                            console.log(teachs);
                            teach = {
                              firstname: FirstName_TH,
                              lastname: LastName_TH,
                              email: this.user.email,
                              password: this.user.password,
                              role: res.data[0].role_id,
                              active: 0,
                              token: "",
                            };
                            UserService.updateUser(teachs.data[0].id, teach).then((res) => {
                              console.log(res.data);
                              this.login();
                            });
                          });
                        }
                      });
                    } else {
                      UserService.getUsers(this.user.email).then((teachs) => {
                        console.log(teachs);
                        UserService.updateUser(teachs.data[0].id, users).then((res) => {
                          console.log(res.data);
                          this.login();
                        });
                      });
                    }
                  });
                })
          }
        });
    },
    signIn() {
      if (this.user.email == "" || this.user.email == null) {
        alert("กรุณากรอกอีเมล");
      } else if (this.user.password == "" || this.user.password == null) {
        alert("กรุณากรอกรหัสผ่าน");
      } else {
        let position = this.user.email.split(".");
        console.log(String(this.user.email).length);
        console.log(position);
        if (String(this.user.email).length == 8) {
          this.getdatastu();
        } else if (position) {
          console.log(position[1].length);
          if (position[1].length == 2) {
            this.getdatatea()
          } else {
            this.login();
          }
        } else {
          this.login();
        }
      }
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}

.card {
  margin: 0 auto;
  /* Added */
  float: none;
  /* Added */
  margin-bottom: 10px;
  /* Added */
}

body {
  background-color: gray;
}
</style>
