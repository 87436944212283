import http from "../http-common";

class QuestionSubject {
  createQuestionSubject(data) {
    return http.post('/question_subjecttest', data);
  }
  getQuestionSubjects(type) {
    return http.get('/question_subjecttest?name='+type);
  }  
  getQuestionSubject(id,set_id) {
    return http.get('/question_subjecttest/'+id+'/'+set_id);
  }
  updateQuestionSubject(id, data) {
    return http.put(`/question_subjecttest/${id}`, data);
  }
  updateQuestionSubjectStatus(id,type, data) {
    return http.put(`/question_subjecttest/updatestatus/${id}/${type}`, data);
  }
  updateQuestionSubjectCount(id, data) {
    return http.put(`/question_subjecttest/updatecount/${id}`, data);
  }
  updateQuestionSubjectLevel(id,type, data) {
    return http.put(`/question_subjecttest/updatelevel/${id}/${type}`, data);
  }
  deleteQuestionSubject(id, data) {
    return http.delete(`/question_subjecttest/deletebysetid/${id}`, data);
  }
}

export default new QuestionSubject();