<template>
  <div class="container-fluid row" style="padding: 30px">
      <div class="social-auth-links text-right mb-3 mt-3" style="text-align:right">
        <button type="button"
                @click="getid(0)"
                class="btn btn-success btn-sm"
                data-bs-toggle="modal"
                data-bs-target="#edittest">
                <i class="fa fa-plus"></i> เพิ่มหัวข้อชุดทดสอบ
        </button>
      </div>
     <div class="col-md-12 mt-3" style="margin: 15px">
      <table class="table table-bordered">
        <thead>
          <tr style="text-align: center">
            <th scope="col" style="background-color: #92d050">ลำดับที่</th>
            <th scope="col" style="background-color: #92d050">ชุดทดสอบ</th>
            <th scope="col" style="background-color: #92d050"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(l,k) in list" :key="k">
            <td scope="row">{{ k+1 }}</td>
            <td scope="row">{{ l.question_name }}</td>
            <td scope="row">
              <button
                type="button"
                @click="getid(l)"
                class="btn btn-warning btn-sm"
                data-bs-toggle="modal"
                data-bs-target="#edittest"
              >
                <i class="fa fa-pencil"></i>
              </button>&nbsp; <button
                type="button"
                @click="getid(l)"
                class="btn btn-danger btn-sm"
                data-bs-toggle="modal"
                data-bs-target="#deletedata"
              >
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
     <!-- Modal -->
    <div
      class="modal fade"
      id="edittest"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{name}}
            </h5>
            <button
              id="closedtest"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            
            <div class="row">
          <div class="col-md-12">
            <label for="exampleInputEmail1" class="form-label">ชุดทดสอบ</label>
          </div>
          <div class="col-md-12 mb-3">
            <input
              v-model="set.question_name"
              class="form-control"
              type="text"
              id="flexRadioDefault1"
            />
          </div>
        </div>
            <!-- <div class="form-check">
              <input
              v-model="set.format"
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                value="1"
              />
              <label class="form-check-label" for="flexRadioDefault1">
                ปรนัย
              </label>
            </div>
            <div class="form-check">
              <input
              v-model="set.format"
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                value="2"
              />
              <label class="form-check-label" for="flexRadioDefault2">
                อัตนัย
              </label>
            </div>
            <div class="form-check">
              <input
              v-model="set.format"
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                value="3"
              />
              <label class="form-check-label" for="flexRadioDefault2">
                ปรนัย-ไม่มีรูปทั้งคำถามและคำตอบ
              </label>
            </div>
            <div class="form-check">
              <input
              v-model="set.format"
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                value="4"
              />
              <label class="form-check-label" for="flexRadioDefault2">
                ปรนัย-มีรูปในคำตอบ
              </label>
            </div>
            <div class="form-check">
              <input
              v-model="set.format"
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                value="5"
              />
              <label class="form-check-label" for="flexRadioDefault2">
                ปรนัย-มีรูปในคำถาม
              </label>
            </div> -->
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success btn-sm" @click="save()">
              ยืนยัน
            </button>
            <button
              type="button"
              class="btn btn btn-secondary btn-sm"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="deletedata"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">ยืนยันการลบ</h5>
            <button
              id="closedeletedoc"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
           <div class="modal-body">
            <h6>ชุดทดสอบนี้มีข้อสอบในหัวข้อที่จะทำการลบ ท่านต้องการลบข้อสอบด้วยใช่หรือไม่</h6>
           </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger btn-sm" @click="deletedata()">
              ยืนยัน
            </button>
            <button
            id="closeupdate"
              type="button"
              class="btn btn btn-secondary btn-sm"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import QuestionSetService from "../services/QuestionSetService";
import RandomService from "../services/RandomService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      list: [],
      set:{},
      name:''
    };
  },
  mounted() {
    this.getmanagetest()
  },
  methods: {
    getid(data){
      if (data == 0) {
        this.set = {}
        this.set.id = 0
        this.name = 'เพิ่มหัวข้อชุดทดสอบ'
      }else{
this.set = data
this.name = 'แก้ไขหัวข้อชุดทดสอบ'
      }
    },
    deletedata(){
      console.log(this.set);
QuestionSetService.deleteQuestionSet(this.set.id).then(()=>{
  var d = {status:false}
RandomService.deleteRandomquestion_set_id(this.set.id,d).then(()=>{
  document.getElementById("closeupdate").click();
      this.getmanagetest()
});
})
    },
    getmanagetest(){
QuestionSetService.getQuestionSets().then((res)=>{
  // console.log(res.data);
  this.list = res.data
})
    },
    save() {
      console.log(this.set);
      var data = {
        question_name:this.set.question_name,
        status:1
      }
      if (this.set.id == 0) {
        QuestionSetService.createQuestionSet(data).then(()=>{
          document.getElementById("closedtest").click();
          this.getmanagetest()
        })
      } else{
        QuestionSetService.updateQuestionSet(this.set.id,data).then(()=>{
          document.getElementById("closedtest").click();
          this.getmanagetest()
        })
      }
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
.form-control-input {
    display: block;
    width: 10%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
}
</style>
